// // TextFormatter.js

// import React from 'react';
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// import styled from 'styled-components';

// const StyledMarkdown = styled.div`
//   font-family: system-ui, -apple-system, sans-serif;
//   line-height: 1.6;
//   color: #333;
//   text-align: left; /* Ensure text is left-aligned */

//   h1, h2, h3, h4, h5, h6 {
//     font-weight: bold;
//     margin-top: 1.5em;
//     margin-bottom: 0.5em;
//   }

//   h1 {
//     font-size: 1.5em;
//   }
//   h2 {
//     font-size: 1.4em;
//   }
//   h3 {
//     font-size: 1.3em;
//   }
//   h4 {
//     font-size: 1.2em;
//   }
//   h5 {
//     font-size: 1.1em;
//   }
//   h6 {
//     font-size: 1em;
//   }

//   p {
//     margin-bottom: 1em;
//   }
//   /* Add other styles as needed */
// `;


// const TextFormatter = ({ text }) => {
//   const cleanText = (inputText) => {
//     let cleanedText = inputText;

//     // Remove duplicated phrases with asterisks
//     cleanedText = cleanedText.replace(/(\*\*)(.*?)\1/g, '$2');

//     // Remove stray '#' characters that are not part of headers
//     cleanedText = cleanedText.replace(/^\s*#\s*$/gm, '');

//     // Replace '#' headers with '##' for proper markdown (since '#' is used for main title)
//     cleanedText = cleanedText.replace(/^#\s+(.*)/gm, '## $1');

//     // Ensure bullet points are properly formatted
//     cleanedText = cleanedText.replace(/^- /gm, '* ');

//     // Ensure paragraphs are separated by two newlines
//     cleanedText = cleanedText.replace(/([^\n])\n([^\n])/g, '$1\n\n$2');

//     // Trim leading and trailing whitespace
//     cleanedText = cleanedText.trim();

//     return cleanedText;
//   };

//   const processedText = cleanText(text);

//   return (
//     <StyledMarkdown>
//       <ReactMarkdown remarkPlugins={[remarkGfm]}>
//         {processedText}
//       </ReactMarkdown>
//     </StyledMarkdown>
//   );
// };

// export default TextFormatter;


// TextFormatter.js

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';

// Styled component for the Markdown text
const StyledMarkdown = styled.div`
  font-family: system-ui, -apple-system, sans-serif;
  line-height: 1.6;
  color: #333;
  text-align: left;

  h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.4em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.1em;
  }
  h6 {
    font-size: 1em;
  }

  p {
    margin-bottom: 1em;
  }

  strong {
    font-weight: bold; /* Ensures bold text */
  }

  ul, ol {
    margin-left: 1.5em;
    margin-bottom: 1em;
  }

  pre {
    // background-color: #f4f4f4;
    padding: 1em;
    overflow-x: auto;
    border-radius: 5px;
  }
`;

const TextFormatter = ({ text }) => {
  const cleanText = (inputText) => {
    let cleanedText = inputText;

    // Remove duplicated phrases with asterisks
    cleanedText = cleanedText.replace(/(\*\*)(.*?)\1/g, '$2');

    // Remove stray '#' characters that are not part of headers
    cleanedText = cleanedText.replace(/^\s*#\s*$/gm, '');

    // Replace '#' headers with '##' for proper markdown (since '#' is used for main title)
    cleanedText = cleanedText.replace(/^#\s+(.*)/gm, '## $1');

    // Ensure bullet points are properly formatted
    cleanedText = cleanedText.replace(/^- /gm, '* ');

    // Ensure paragraphs are separated by two newlines
    cleanedText = cleanedText.replace(/([^\n])\n([^\n])/g, '$1\n\n$2');

    // Trim leading and trailing whitespace
    cleanedText = cleanedText.trim();

    return cleanedText;
  };

  const processedText = cleanText(text);

  return (
    <StyledMarkdown>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>
        {processedText}
      </ReactMarkdown>
    </StyledMarkdown>
  );
};

export default TextFormatter;
