import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { formatNumberDollars, format2Decimals } from '../../../../UniversalFunctions/Numeric/Format2Decimal';

// Animation for loading and hover effects
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AvailableCreditsDiv = styled.div`
  display: flex;
  flex-direction: column;
  // width: 80%;
  @media (max-width: 700px) {
    width: 100%;
    height: 100%;
  }
`;

// Enhanced container styles for visual appeal and user engagement
const EnhancedScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  max-width: 60vw;
  align-items: center;
  justify-content: start;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  animation: ${fadeIn} 0.5s ease-out;

  &::-webkit-scrollbar {
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background: #325d79ff;
    border-radius: 4px;
  }

  @media (max-width: 1024px) {
    max-width: 75vw;
  }
  
  `;

// Dynamic item styling with hover and focus effects for better interaction
const MarketItem = styled.div`
  flex: 0 0 auto;
  padding: 3% 5%;
  margin-right: 3%;
  background-color: #ffffff;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  border: 2px solid #325d7980;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  transition: transform 0.3s ease-in-out, box-shadow .5s ease-in-out;
  animation: ${fadeIn} 0.6s ease-out;


  &:hover, &:focus {
    cursor: pointer;
    transform: translateY(-.5%);
    box-shadow: 0 10px 20px rgba(0,0,0,0.12), 0 4px 8px rgba(0,0,0,0.06);
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

// Enhanced title and detail styling for clarity and visual hierarchy
const MarketTitle = styled.h2`
  color: #325d79ff;
  font-size: 1.4em;
  margin-bottom: 10px;
`;

const MarketDetail = styled.p`
  color: #5f7d95ff;
  margin: 2% 0;
  font-size: 1em;
  font-weight: bold;
  text-align: left;
`;

// Button styling for engagement and coherence with the design
const ActionButton = styled.button`
  display: block;
  background-color: #325d79ff;
  color: #FFFFFF;
  padding: 1.2% 2.2%;
  border: none;
  border-radius: 10px;
  font-weight: 600;
  margin: 2.5% auto;
  transition: background-color 0.3s, transform 0.5s;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);

  &:hover {
    background-color: #5f7d95ff;
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }
`;

function AvailableCreditsFooter() {

    const navigate = useNavigate();
    const accessToken = localStorage.getItem('access_token');
    const [markets, setMarkets] = useState({});

    useEffect(() => {
        const fetchMarkets = async() => {

              try {
                const res = await axios.get(
                    'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/all_contracts',
                    {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem("firebase_id_token")}`
                        }
                    })
                const allContracts = res.data.data;
                console.log('all contracts ', allContracts);
                setMarkets(allContracts);
              } catch (error) {
                console.log('An error has occurred: ', error);
              }

        }
        fetchMarkets();
    }, [])
    return (
      <AvailableCreditsDiv>
      <MarketTitle>Explore Carbon Removal Credits</MarketTitle>
      <EnhancedScrollContainer>
        {Object.values(markets).map((market) => (
          <MarketItem key={market.contract_name} tabIndex="0" onClick={() => navigate(`/contract/${market.company}/${market.contract_name}`, { state: { market } })}>
            <MarketTitle>{market.company}</MarketTitle>
            <MarketDetail style={{ textAlign: 'center', color: '#325d79ff', marginBottom: '10%'}}>{market.contract_name}</MarketDetail>
            <MarketDetail>Tons: {market.contract_details.Tons || market.contract_details.tonsToCapture}</MarketDetail>
            <MarketDetail>Price: ${formatNumberDollars(market.contract_details.PriceTonUSD)}/ton</MarketDetail>
            <MarketDetail>Storage: {market.contract_details.Storage}</MarketDetail>
            <MarketDetail>Year: {market.contract_details.Year}</MarketDetail>
            <MarketDetail>Location: {market.contract_details.Location}</MarketDetail>
          </MarketItem>
        ))}
      </EnhancedScrollContainer>
      <ActionButton onClick={() => navigate('/')}>
        Browse All Contracts
      </ActionButton>
      </AvailableCreditsDiv>
    )
}

export default AvailableCreditsFooter;

