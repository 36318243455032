// Debug.js
import React from 'react';

class Debug extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state to indicate an error
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log error details
        console.log('Error:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>Something went wrong.</h1>; // Display fallback UI on error
        }

        // Render the child component passed to Debug via route
        return this.props.children;
    }
}

export default Debug;
