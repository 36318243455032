const Theme = {
    colors: {
      primary: {
        50: "#EDF4F5",
        100: "#DFEEF0",
        200: "#BBE0E1",
        300: "#93D1D2",
        400: "#65C0C3",
        500: "#34ACB3",
        600: "#1F93A2",
        700: "#1C778D",
        800: "#165B75",
        900: "#093C57",
      },
      secondary: {
        50: "#FFEBD4",
        100: "#FFE2C0",
        200: "#FFD7A9",
        300: "#FFCC92",
        400: "#FFC17A",
        500: "#FFA843",
        600: "#FF9821",
        700: "#FB801F",
        800: "#F76A1D",
        900: "#F14B1B",
      },
      grey: {
        50: "#E4E8E8",
        100: "#C5CECF",
        200: "#ACB7B8",
        300: "#8D9B9C",
        400: "#778386",
        500: "#636D72",
        600: "#4E555C",
        700: "#373D46",
        800: "#2A2F38",
        900: "#191E27",
      },
    },
  };

export default Theme;
