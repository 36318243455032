import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import LandingBodyTabs from "../Pages/PageComponents/CoreProductFrontEndSeller/LandingBodyTabs";
import LandingBodyTabsBuyer from "../Pages/PageComponents/CoreProductFrontEndBuyer/LandingBodyTabsBuyer";
import axios from "axios";
import styled from "styled-components";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  // background-color: #E4E8E8;
  @media (max-width: 768px) {
    max-width: 100vw;
    margin-top: 10vh;
    flex-direction: column;
  }
`;

const AuthCheck = () => {
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const firebaseIdToken = localStorage.getItem("firebase_id_token");
      if (!firebaseIdToken) {
        try {
          localStorage.removeItem("user");
        } catch (error) {
          console.log("an error occurred removing token");
        }
        await sleep(1000); //wait for one second
        navigate("/");
        return;
      }

      try {
        const decodedToken = jwtDecode(firebaseIdToken);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp < currentTime) {
          // Token is expired
          localStorage.removeItem("firebase_id_token");
          try {
            localStorage.removeItem("user");
          } catch (error) {
            console.log("an error occurred removing token");
          }
          await sleep(1000);
          navigate("/");
        }

        const res = await axios.get(
            `https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/account_type`,
            {
              params: {
                username: decodedToken.sub
              },
              headers: {
                Authorization: `Bearer ${firebaseIdToken}`
              }
            });

        const userData = res.data;
        setAccountType(userData["account_type"]);
      } catch (error) {
        console.error("Error decoding token:", error);
        await sleep(1000);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    checkToken();
  }, [navigate]);

  //Should be changed to a more dynamic throbber in the future
  if (isLoading) {
    return <div></div>;
  }

  //console.log("account type: ", accountType);

  return (
    <PageContainer>
      {accountType === "Seller" ? <LandingBodyTabs /> : <LandingBodyTabsBuyer />}
      <Outlet />
    </PageContainer>
  );
};

export default AuthCheck;
