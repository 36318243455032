import React from 'react';
import styled from 'styled-components';

const ProgressBarContainer = styled.div`
  background-color: #DFEFF0;
  border-radius: 6px;
  height: 18px;
  margin: 0.75rem 0;
  position: relative;
`;

const ProgressBarFill = styled.div`
  background-color: #1EB1B5;
  height: 100%;
  width: ${props => props.percent}%;
  border-radius: 6px;
`;

const ProgressText = styled.div`
  font-size: 0.75rem;
  font-weight: bold;
  color: #1EB1B5;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`;

const ProgressBar = ({ percent }) => {
  //console.log('percent:' ,percent);
  return (
    <ProgressBarContainer>
      <ProgressBarFill percent={percent} />
      <ProgressText>{percent.toFixed(2)}%</ProgressText>
    </ProgressBarContainer>
  );
};

export default ProgressBar;