import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

//Styled components for the banner and buttons
const Banner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #165b75;
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: ${fadeIn} 3s ease-in-out;
`;

const Text = styled.p`
  margin: 0;
  font-size: 16px;
  color: white;
  @media (max-width: 750px) {
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const AcceptButton = styled.button`
  padding: 8px 16px;
  font-size: 14px;
  color: #fff;
  background-color: #1d374a;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
  }
  @media (max-width: 750px) {
    font-size: 10px;
  }
`;

const DeclineButton = styled(AcceptButton)`
  background-color: #2a2f38;
`;

const CookieBanner = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [ipChecked, setipChecked] = useState(false);
  const [ip, setIpAddress] = useState("");

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      // console.log(response.data.ip);
      setIpAddress(response.data.ip);
      setipChecked(true);
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
      return null;
    }
  };

  const checkExistingCookie = async (ip) => {
    try {
      //console.log("ip", ip);
      //console.log("type ip", typeof ip);
      const response = await axios.get(
        `https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/cookie_preference`,
          {
            params: {
              ip: ip
            }
          }
      );
      return response.data.data;
    } catch (error) {
      console.log("Error checking cookie: ", error);
      return false;
    }
  };

  const saveCookiePreference = async (ip, preference) => {
    try {
      console.log({ ip: ip, preference: preference });

      // Make POST request, pass data in the body, not in params
      const response = await axios.post(
          `https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/cookie_preference`,
          null,  // No request body, so pass `null` here
          {
            params: {
              ip: ip,           // IP address passed as query parameter
              preference: preference // Preference passed as query parameter
            }
          }
      );

      console.log("Cookie preference saved successfully:", response.data);

    } catch (error) {
      console.error("Error setting cookie preference:", error);
    }
  };


  const checkThreeMonthsPassed = (timestamp) => {
    const threeMonthsInMs = 3 * 30 * 24 * 60 * 60 * 1000;
    return Date.now() - new Date(timestamp).getTime() > threeMonthsInMs;
  };

  useEffect(() => {
    getIpAddress();
  }, []);

  useEffect(() => {
    const checkingCookies = async () => {
      if (ipChecked) {
        //console.log('checking ip: ', ip);
        const cookieData = await checkExistingCookie(ip);
        //console.log("cookieData", cookieData);
        if (cookieData) {
          // console.log("found data!");
          const { preference, modifiedAt } = cookieData;

          if (preference !== null && modifiedAt !== null) {
            // Check if the preference is false and more than three months have passed since modifiedAt
            if (preference === false && checkThreeMonthsPassed(modifiedAt)) {
              setShowPopup(true);
            } else {
              setShowPopup(false);
            }
          } else {
            // If no valid cookie data, show the popup
            setShowPopup(true);
          }
        } else {
          setShowPopup(true); // Show popup if no data exists for this IP
        }
      }
    };
    checkingCookies();
  }, [ip, ipChecked]);

  const handleAccept = async () => {
    await saveCookiePreference(ip, true);
    setShowPopup(false);
  };

  const handleDecline = async () => {
    await saveCookiePreference(ip, false);
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <Banner>
      <Text>
        We use cookies to enhance your browsing experience. By clicking
        "Accept", you consent to our use of cookies.
      </Text>
      <ButtonContainer>
        <AcceptButton onClick={handleAccept}>Accept</AcceptButton>
        <DeclineButton onClick={handleDecline}>Decline</DeclineButton>
      </ButtonContainer>
    </Banner>
  );
};

export default CookieBanner;
