import React, { useState } from "react";
import styled from "styled-components";
import { TabsContainer, TabsList, TabsContent, TabsTrigger } from '../Library/SellerComponents';
import { format, isBefore, isToday } from 'date-fns';

const TimelineSection = styled.div`
  border-bottom: 1px solid #E6E6E6;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
`;

const TimelineNode = styled.div`
  position: absolute;
  left: 0;
  top: 0.25rem;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.isActive ? '#1EB1B5' : '#E0E0E0'};
  transition: all 0.3s ease;
  z-index: 20;

  &::before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 0.3s ease;
  }

  &:hover::before {
    border-color: #1EB1B5;
  }

`;

const TimelineLine = styled.div`
  position: absolute;
  top: 0.1rem;
  bottom: -1rem;
  // width: 2px;
  background-color: #E0E0E0;
`;

const TimelineContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const TimelineEvent = styled.p`
  margin: 0;
  font-weight: 500;
  color: #333;
  font-size: 0.9375rem;
`;

const TimelineDate = styled.span`
  font-size: 0.875rem;
  color: #6c757d;
  text-align: right;
`;

const TimelineItem = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  position: relative;
  padding-left: 1.5rem;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0.25rem;
    width: 12px;
    height: 12px;
    background-color: #1EB1B5;
    border-radius: 50%;
  }

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 0.25rem;
    bottom: -1rem;
    width: 2px;
    background-color: #E6E6E6;
  }

  @media (min-width: 800px) {
    margin-left: 12.5%;
    margin-right: 12.5%;
  }
`;

const TabsOverviewSeller = () => {
    const [activeTab, setActiveTab] = useState('Overview');

    return (
        <TabsContainer></TabsContainer>
    )
}

const Timeline = ({ events }) => {
    const today = new Date();
    console.log('events: ', events);
  
    return (
      <TimelineSection>
        {events.map((event, index) => {
          const eventDate = event.date ? new Date(event.date) : null;
          const isActive = eventDate && (isBefore(eventDate, today) || isToday(eventDate));
  
          return (
            <TimelineItem key={index}>
              <TimelineNode isActive={isActive} />
              {index !== events.length - 1 && <TimelineLine />}
              <TimelineContent>
                <TimelineEvent>{event.name}</TimelineEvent>
                <TimelineDate>
                {eventDate ? format(eventDate, 'MMM dd, yyyy') : 'N/A'}
                </TimelineDate>
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </TimelineSection>
    );
  };

export { Timeline };