import React from "react";
import styled from "styled-components";

const ContractDescTitle = styled.p`
  color: #325d79ff;
  font-size: .9em;
  margin: 1% 0.5%;
  text-align: left;
  font-weight: bold;
`;

const StatusIndicator = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: green;
  padding: 2%;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const StatusIndicatorCompleted = styled.div`
  display: inline-block;
  background-color: #f0f0f0;
  color: blue;
  padding: 2%;
  border-radius: 5px;
  font-size: 1em;
  font-weight: bold;
  margin-left: 2%; // Adjust spacing as needed
`;

const StatusIndicatorUninitialized = styled.div`
display: inline-block;
background-color: #f0f0f0;
color: gray;
padding: 2%;
border-radius: 5px;
font-size: 1em;
font-weight: bold;
margin-left: 2%; // Adjust spacing as needed
`;

function StatusFormatted(contract){
    return (
    <ContractDescTitle>Status: 
        {contract.Status === 'In Progress' && <StatusIndicator>Active</StatusIndicator>}
        {contract.Status === 'Completed' && <StatusIndicatorCompleted>Completed</StatusIndicatorCompleted>}
        {contract.Status === 'Uninitialized' && <StatusIndicatorUninitialized>Uninitialized</StatusIndicatorUninitialized>}
      </ContractDescTitle>
    )
}

export { StatusFormatted };