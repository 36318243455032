// import React, { useState, useEffect, useRef } from 'react';
// import styled from 'styled-components';
// import LandingBodyTabsBuyer from './LandingBodyTabsBuyer';
// import { useNavigate, useParams, useLocation } from 'react-router-dom';
// import axios from 'axios';
// import ProgressBarChart from '../../../UniversalFunctions/ProgressBarChart';
// import { generateIntervalDates, prepareChartData } from '../../../UniversalFunctions/ProgressBarChart';
// import { isValidDate, stringToDate, capitalizeFirstLetter } from '../../../UniversalFunctions/IsValidDate';

// const PageContainer = styled.div`
//     display: flex;
//     flex-direction: column;
//     height: 100%;
//     width: 100%;
// `;

// const MainContainer = styled.div`
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     padding: 20px;
//     // background: #f5f5f5;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const BodyDiv = styled.div`
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     width: 100%;
// `;

// const UpperDivMain = styled.div`
//   display: flex;
//   justify-content: space-between;
//   padding: 20px;
// `;

// const LeftHalfUpperDivMain = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   margin-right: 20px; // Adds some space between the left and right sections
//   padding: 20px;
//   background-color: #f8f8f8; // Light background for contrast
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   // height: 100%;
// `;

// const RightHalfUpperDivMain = styled.div`
//   flex: 1;
//   padding: 20px;
//   background-color: #f8f8f8;
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const LowerDivMain = styled.div`
//   margin-top: 20px;
//   padding: 20px;
//   background-color: #fff;
//   overflow-y: auto; // Allows for scrolling
//   border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//   max-height: 400px; // Adjust as needed
// `;

// const DetailTitle = styled.h2`
//   color: #333;
//   margin-bottom: 16px;
//   // background-color: #f5f5f5;
// `;

// const Detail = styled.p`
//   color: #666;
//   font-size: 16px;
//   margin: 5px 0;
//   border-bottom: 1px solid #eee;
//   padding-bottom: 5px;
//   text-align: left;
// `;

// const RechartDivBottom = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     margin: 2% 10%;
//     // margin-bottom: 8%;
// `;

// const ContractsScrollContainer = styled.div`
//   flex-grow: 1;
//   //overflow-y: auto; // Enable vertical scrolling
//   padding: 20px;
//   margin-top: 20px;
//   max-height: 300px; // Adjust based on available space
//   box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
//   border-radius: 8px;
//   background-color: #fff;
//   flex-direction: row;
//   display: flex;
//   overflow-x: auto; //enable horizontal scrolling
//   white-space: nowrap; //prevent items from wrapping to the next line
// `;

// const ContractScrollItem = styled.div`
//   display: inline-block; //make each item inline-block
//   padding: 15px;
//   margin-bottom: 10px;
//   border-bottom: 1px solid #eee;
//   margin-left: 5%;
//   margin-right: 5%;
//   border-radius: 4%;
//   &:last-child {
//     border-bottom: none;
//   }
//   &:hover {
//     background-color: #f0f0f0;
//     cursor: pointer;
//     border-radius: 4%;
//     transform: scale(1.05);
//   }
// `;

// const ContractScrollTitle = styled.h2`
//   text-align: center;
//   font-size: 1.5em;
//   color: #333;
//   margin-bottom: 3%;
// `;

// const BrowseButton = styled.button`
//   background-color: #4CAF50;
//   color: white;
//   padding: 10px 20px;
//   border: none;
//   border-radius: 5px;
//   font-size: 18px;
//   font-weight: bold;
//   margin-top: 20px;
//   cursor: pointer;
//   display: block;
//   width: 50%;
//   margin-left: auto;
//   margin-right: auto;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
//   transition: background-color 0.3s, box-shadow 0.3s;

//   &:hover {
//     background-color: #45a049;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
//   }
// `;

// // Styled components for the modal and delete button
// const ModalContainer = styled.div`
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 1000; // Ensure it's above other content
// `;

// const ModalContent = styled.div`
//   background-color: white;
//   padding: 20px;
//   border-radius: 8px;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
//   width: 400px;
//   min-width: 300px; // Adjust as needed
//   text-align: center;
//   position: relative;
// `;

// const ModalButton = styled.button`
//   padding: 10px 20px;
//   margin: 10px;
//   border-radius: 5px;
//   border: none;
//   cursor: pointer;
//   font-weight: bold;

//   &:first-child {
//     background-color: #4CAF50; // Green for confirmation
//     color: white;
//   }

//   &:last-child {
//     background-color: #f44336; // Red for cancellation
//     color: white;
//   }

//   &:hover {
//     opacity: 0.9;
//     transform: scale(1.03);
//   }
// `;

// const DeleteButton = styled.button`
//   background-color: #ff4d4f; // Red color for delete button
//   color: white;
//   padding: 10px 15px;
//   border: none;
//   border-radius: 5px;
//   font-size: 16px;
//   cursor: pointer;
//   margin-top: 10px; // Space from other content
//   &:hover {
//     background-color: #ff7875;
//   }
// `;

// const DeleteButtonContainer = styled.div`
//   display: flex;
//   justify-content: center;
// `;

// // Your component function here...


// function GoalIndividualBuyerPage() {

//     const navigate = useNavigate();
//     const { goalName } = useParams();
//     const username = localStorage.getItem('user');
//     const accessToken = localStorage.getItem('access_token');
//     const [goal, setGoal] = useState({});
//     const location = useLocation();
//     const timeFrameGoal = location.state;
//     const [intervalDates, setIntervalDates] = useState([]);
//     const [finalChartData, setFinalChartData] = useState([]);
//     const requestParams = {};
//     const [removalProgressTons, setRemovalProgressTons] = useState(0);
//     const [markets, setMarkets] = useState({});
//     const [showModal, setShowModal] = useState(false);

//     const Modal = ({ isOpen, onClose, onConfirm }) => {

//         const modalContentRef = useRef();

//         const handleClickOutside = (event) => {
//             if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
//                 onClose();
//             }
//         };

//         if (!isOpen) return null;
    
//         return (
//             <ModalContainer onClick={handleClickOutside}>
//                 <ModalContent ref={modalContentRef}>
//                     <p>Are you sure you want to delete this goal?</p>
//                     <ModalButton onClick={onConfirm}>Yes</ModalButton>
//                     <ModalButton onClick={onClose}>No</ModalButton>
//                 </ModalContent>
//             </ModalContainer>
//         );
//     };
    
//     const handleDeleteConfirm = async() => {
//         const username = localStorage.getItem('user');
//         const accessToken = localStorage.getItem('access_token');

//         const config = {
//             headers: {
//                 'Authorization': `Bearer ${accessToken}`
//               }
//         }

//         const deleteParams = {
//             'user': username,
//             'goalName': goalName,
//             'timeframe': timeFrameGoal
//         }
//         try {
//             // console.log(deleteParams);
//             const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/delete_indiv_goal', deleteParams, config)
//             // console.log(res.data.message);
        
//             if (String(res.data.message).includes("success")) {
//                 setShowModal(false);
//                 navigate('/Removal-Goals')
//                 }
//         } catch (error) {
//             console.log('Error deleting goal');
//         }

//     };


//     useEffect(() => {

//         // console.log('timeframe: ', timeFrameGoal);
//         const fetchGoal = async() => {
//             const config = {
//                 headers: {
//                     'Authorization': `Bearer ${accessToken}`
//                   }
//             }
//             const username = localStorage.getItem('user');
            
//             requestParams['username'] = username;
//             requestParams['goalName'] = goalName;
//             requestParams['timeframe'] = timeFrameGoal;

//             try {
//                 const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/get_buyer_goal_indiv', requestParams, config)
//                 const fetchedGoal = res.data.data;
//                 // console.log('goal: ', fetchedGoal);
//                 setGoal(fetchedGoal);
//             } catch (error) {
//                 console.log('An error occurred: ', error);
//             }
//         }
//         fetchGoal();

//     },[]);

//     // Effect to update endDate when location.state is "permanent"
//     // Separate effect for generating interval dates and chart data
//     useEffect(() => {
      
//         if (String(location.state) === "permanent") {
//           const newDateObj = stringToDate('2050-01-01');
//           if (newDateObj) {
//             if (goal.goalType){
//               const formattedDate = newDateObj.toISOString().split('T')[0]; // '2050-01-01'
//               goal['endDate'] = formattedDate;
//               // console.log('goal initial', goal);
//               // setGoal(prevGoal => ({ ...prevGoal, endDate: formattedDate }));
//             }
//           } else {
//               console.error('Invalid Date format');
//           }
//         }

//         if (goal.goalType) {
//           const newGoalType = {
//             name: Object.keys(goal.goalType)[0],
//             value: Object.values(goal.goalType)[0]
//           }
//           delete goal['goalType'];
//           goal["goalType"] = newGoalType;
//         }

//         if (goal && goal.endDate && goal.goalType) {
//             const intervalDatesTemp = generateIntervalDates(goal.endDate);
//             setIntervalDates(intervalDatesTemp);
            
//             // console.log('input vals', goal.goalType.value);
//             // console.log('goaltypegneral', goal.goalType);
//             setFinalChartData(prepareChartData(intervalDatesTemp, goal.goalType.value, goal.goalType.name));
//         }
//     }, [goal]);


//     useEffect(() => {
//         const fetchMarkets = async() => {
//             const config = {
//                 headers: {
//                   'Authorization': `Bearer ${accessToken}`
//                 }
//               }

//               try {
//                 const res = await axios.get('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/browse_all_contracts', config)
//                 const allContracts = res.data.data;
//                 // console.log('all contracts ', allContracts);
//                 setMarkets(allContracts);
//               } catch (error) {
//                 console.log('An error has occurred: ', error);
//               }

//         }
//         fetchMarkets();
//     }, []);


//     return (
//             <MainContainer>
//                 <BodyDiv>
//                     <UpperDivMain>
//                         <LeftHalfUpperDivMain>
//                             {/* NEED TO KEEP GOALTYPE STATE AND CHANGE VIEW BASED ON GOALTYPE */}
//                             <DetailTitle>{goalName}</DetailTitle>
//                             <Detail>Start Date: {goal.startDate}</Detail>
//                             <Detail>End Date: {goal.endDate}</Detail>
//                             {goal.goalType && Object.entries(goal.goalType).map(([key, value]) => (
//                                 <Detail key={key}>{capitalizeFirstLetter(key)}: {value}</Detail>
//                             ))}
//                             <Detail>Removal Progress: {removalProgressTons} Tons</Detail>

//                             {/* {Object.entries(goal.goalType).map(([key, value]) => (
//                                 <p key={key}>{key}: {value}</p>
//                             ))} */}
//                             <DeleteButtonContainer>
//                               <DeleteButton onClick={() => setShowModal(true)}>Delete Goal</DeleteButton>
//                             </DeleteButtonContainer>
//                             {showModal && 
//                                 <Modal 
//                                     isOpen={showModal} 
//                                     onClose={() => setShowModal(false)} 
//                                     onConfirm={handleDeleteConfirm} 
//                                 />
//                             }
//                         </LeftHalfUpperDivMain>
//                         <RightHalfUpperDivMain>
//                             <RechartDivBottom>
//                                 <ProgressBarChart data={finalChartData} goalType={goal.goalType} />
//                             </RechartDivBottom>
                            

//                         </RightHalfUpperDivMain>
//                     </UpperDivMain>
//                     <LowerDivMain>
//                         <ContractScrollTitle>Available Credits</ContractScrollTitle>
//                         <ContractsScrollContainer>
//                         {Object.values(markets).map((market) => (
//                             <ContractScrollItem key={market.contract_name} onClick={() => navigate('/')}>
//                                 <DetailTitle>{market.company}</DetailTitle>
//                                 <Detail>{market.contract_name}</Detail>
//                                 <Detail>Tons: {market.contract_details.Tons}</Detail>
//                                 <Detail>Price: {market.contract_details.PriceTonUSD} USD</Detail>
//                                 <Detail>Storage: {market.contract_details.Storage}</Detail>
//                                 <Detail>Year: {market.contract_details.Year}</Detail>
//                                 <Detail>Location: {market.contract_details.Location}</Detail>
//                             </ContractScrollItem>
//                         ))}

//                     </ContractsScrollContainer>
//                     <BrowseButton onClick={() => navigate('/')}>
//                         Browse Available Contracts
//                     </BrowseButton>

//                     </LowerDivMain>

//                 </BodyDiv>
//             </MainContainer>
//     );
// }

// export default GoalIndividualBuyerPage;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { PageContainer, BodyDiv, MainContainer } from '../../../UI/Library/PageStructure';
import { Card, CardContent, CardHeader, CardTitle } from "../../../UI/Library/SellerComponents";
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import { FaCheckCircle, FaHourglassHalf, FaExclamationTriangle, FaRocket, FaChartLine, FaBullseye, FaTrash, FaCalendarAlt, FaExclamationCircle, FaEdit, FaSave, FaTimes} from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, ArcElement, Title, Tooltip, Legend);

const SubBodyDiv = styled.div`
  display: grid;
  grid-template-columns: 30% 70%;
  gap: 20px;
  margin: 20px;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const GoalCard = styled(motion.div)`
  background: linear-gradient(135deg, #f6f9fc 0%, #ffffff 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
  }
`;

const GoalHeader = styled(CardHeader)`
  background: linear-gradient(135deg, #325d79 0%, #5f7d95 100%);
  padding: 20px;
`;

const GoalTitle = styled(CardTitle)`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  color: white;
`;

const GoalContent = styled(CardContent)`
  padding: 20px;
`;

const GoalDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
`;

const GoalLabel = styled.span`
  font-weight: bold;
  color: #325d79;
`;

const GoalValue = styled.span`
  color: #5f7d95;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
`;

const ProgressFill = styled.div`
  width: ${props => props.progress}%;
  height: 100%;
  background: linear-gradient(90deg, #325d79 0%, #5f7d95 100%);
  transition: width 0.5s ease-out;
`;

const ProgressLabel = styled.div`
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
  color: #325d79;
`;

const StatusIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
`;

const ChartContainer = styled(motion.div)`
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
`;

const EditButton = styled.button`
  background-color: #52c41a;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #73d13d;
    transform: translateY(-2px);
  }
`;

const SaveButton = styled.button`
  background-color: #1890ff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #40a9ff;
    transform: translateY(-2px);
  }
`;

const DeleteButton = styled.button`
  background-color: #ff4d4f;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;

  &:hover {
    background-color: #ff7875;
    transform: translateY(-2px);
  }
`;

const Modal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled(motion.div)`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  width: 90%;
`;

const ModalButton = styled.button`
  margin: 0 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
  }
`;

const ConfirmButton = styled(ModalButton)`
  background-color: #ff4d4f;
  color: white;

  &:hover {
    background-color: #ff7875;
  }
`;

const CancelButton = styled(ModalButton)`
  background-color: #d9d9d9;
  color: #000;

  &:hover {
    background-color: #bfbfbf;
  }
`;

const InsightsSection = styled.div`
  margin-top: 20px;
`;

const InsightCard = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
`;

const InsightTitle = styled.h3`
  color: #325d79;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InsightContent = styled.p`
  color: #5f7d95;
`;

const ExpiredGoalWarning = styled.div`
  background-color: #fff3cd;
  color: #856404;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

function GoalIndividualBuyerPage() {
  const { goalName } = useParams();
  const navigate = useNavigate();
  const [goal, setGoal] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [insights, setInsights] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});

  const username = localStorage.getItem('user');
  const firebaseIdToken = localStorage.getItem('firebase_id_token');
  const authHeader = {
    Authorization: `Bearer ${firebaseIdToken}`
  };



  useEffect(() => {
    const fetchGoalAndData = async () => {
      try {
        const [goalRes, contractsRes] = await Promise.all([
          axios.get('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/buyer_goal',
              {
                params: {
                  goal_name: goalName,
                  username: username
                },
                headers: authHeader
              }),

          axios.get('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/user_all_contracts',
              {
                params: {
                  username: username
                },
                headers: authHeader
                })
        ]);

        const fetchedGoal = goalRes.data.data;
        if (fetchedGoal) {
          setGoal(fetchedGoal);
          setEditedData({
            endDate: fetchedGoal.endDate,
            goalType: fetchedGoal.goalType
          });
        } else {
          alert('No goal found');
          navigate('/Removal-Goals');
        }

        const contractsData = contractsRes.data.data;
        //console.log("GoalIndividualBuyer user_all_contracts", contractsRes.data.data)
        if (Array.isArray(contractsData)) {
          setContracts(contractsData);
        } else if (typeof contractsData === 'object' && contractsData !== null) {
          setContracts(Object.values(contractsData));
        } else {
          console.error('Unexpected contracts data format:', contractsData);
          setContracts([]);
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        setContracts([]);
      }
    };

    fetchGoalAndData();
  }, [goalName, navigate]);

  useEffect(() => {
    if (goal && contracts.length > 0) {
      generateInsights();
    }
  }, [goal, contracts]);

  const calculateProgress = () => {
    if (!goal || !goal.goalType) return 0;
  
    const [goalType, goalValue] = Object.entries(goal.goalType)[0];
    let currentValue = 0;
  
    if (!Array.isArray(contracts) || contracts.length === 0) {
      console.error('Contracts is not an array or is empty:', contracts);
      return 0;
    }
  
    switch (goalType) {
      case 'CO2 Removed':
        currentValue = contracts.reduce((sum, contract) => {
          const removed = Number(contract.carbonRemoved) || 0;
          return sum + removed;
        }, 0);
        break;
      case 'Contracts Completed':
        currentValue = contracts.filter(contract => contract.Status === 'Completed').length;
        break;
      case 'Partnerships Created':
        currentValue = new Set(contracts.map(contract => contract.company)).size;
        break;
      default:
        return 0;
    }
  
    return Math.min((currentValue / goalValue) * 100, 100);
  };

  const progress = calculateProgress();

  const getStatusIndicator = () => {
    if (progress === 0) return { icon: <FaExclamationTriangle color="#ffa940" />, text: "Not Started", color: "#ffa940" };
    if (progress === 100) return { icon: <FaCheckCircle color="#52c41a" />, text: "Completed", color: "#52c41a" };
    return { icon: <FaHourglassHalf color="#1890ff" />, text: "In Progress", color: "#1890ff" };
  };

  const status = getStatusIndicator();

  const chartData = {
    labels: contracts.map((_, index) => `Contract ${index + 1}`),
    datasets: [
      {
        label: 'Contract Value ($)',
        data: contracts.map(contract => {
          if (contract.Revenue && typeof contract.Revenue === 'string') {
            return parseFloat(contract.Revenue.replace(/[^0-9.-]+/g,"")) || 0;
          }
          return contract.Revenue || 0;
        }),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
        yAxisID: 'y',
      },
      {
        label: 'Carbon Removed (tons)',
        data: contracts.map(contract => contract.carbonRemoved || 0),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
        yAxisID: 'y1',
      }
    ]
  };

  const chartOptions = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    plugins: {
      title: {
        display: true,
        text: 'Contract Values vs Carbon Removed'
      }
    },
    scales: {
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        title: {
          display: true,
          text: 'Contract Value ($)'
        }
      },
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        title: {
          display: true,
          text: 'Carbon Removed (tons)'
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const handleDelete = async () => {

    try {
      await axios.delete(
          'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/buyer_goal',
          {
            params: {
              username: username,
              goal_name: goalName
            },
            headers: authHeader
          }

      );
      navigate('/Removal-Goals');

    } catch (error) {
      console.error('Error deleting goal:', error);
      alert('Failed to delete goal. Please try again.');
    }
  };

  const handleSave = async () => {

    try {
      const newEndDate = moment.tz(editedData.endDate, moment.tz.guess()).utc().format();
      const newGoalType = editedData.goalType;

      const submitObj = {
        endDate: newEndDate,
        goalType: newGoalType,
        modifiedAt: new Date().toISOString()
      };

      await axios.put(
          'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/buyer_goal',
          submitObj,
          {
            params: {
              username: username,
              goal_name: goalName
            },
            headers: authHeader
          });

      setGoal(prevGoal => ({
        ...prevGoal,
        endDate: newEndDate,
        goalType: newGoalType,
        modifiedAt: new Date().toISOString()
    }));
    setIsEditing(false);
    alert('Changes saved successfully!');
  } catch (error) {
    console.error('Error saving changes:', error);
    alert('Failed to save changes. Please try again.');
  }
};

const generateInsights = () => {
  const newInsights = [];
  const [goalType, goalValue] = Object.entries(goal.goalType)[0];
  const currentValue = calculateProgress() * goalValue / 100;

  const localStartDate = moment(goal.startDate).tz(moment.tz.guess()).toDate();
  const localEndDate = moment(goal.endDate).tz(moment.tz.guess()).toDate();

  const daysSinceStart = Math.ceil((new Date() - localStartDate) / (1000 * 60 * 60 * 24));
  const progressRate = currentValue / daysSinceStart;
  newInsights.push({
    title: "Daily Progress Rate",
    content: `You're making progress at a rate of ${progressRate.toFixed(2)} ${goalType === 'CO2 Removed' ? 'tons' : 'units'} per day.`,
    icon: <FaChartLine />
  });

  const daysUntilEnd = Math.ceil((localEndDate - new Date()) / (1000 * 60 * 60 * 24));
  const projectedValue = currentValue + (progressRate * daysUntilEnd);
  const projectedPercentage = (projectedValue / goalValue) * 100;
  newInsights.push({
    title: "Goal Projection",
    content: `At your current rate, you're projected to reach ${projectedPercentage.toFixed(2)}% of your goal by the end date.`,
    icon: <FaRocket />
  });

  if (projectedPercentage < 100) {
    const accelerationNeeded = ((goalValue - currentValue) / daysUntilEnd) - progressRate;
    newInsights.push({
      title: "Acceleration Needed",
      content: `To reach your goal, you need to increase your daily progress by ${accelerationNeeded.toFixed(2)} ${goalType === 'CO2 Removed' ? 'tons' : 'units'}.`,
      icon: <FaBullseye />
    });
  }

  setInsights(newInsights);
};

const isGoalExpired = new Date() > moment(goal?.endDate).tz(moment.tz.guess()).toDate();

if (!goal) return <div>Loading...</div>;

return (
  <PageContainer>
    <MainContainer>
      <BodyDiv>
        <SubBodyDiv>
          <GoalCard
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <GoalHeader>
              <GoalTitle>{goalName}</GoalTitle>
            </GoalHeader>
            <GoalContent>
              {Object.entries(goal).map(([key, value]) => {
                if (key !== 'goalName' && key !== 'timeAdded') {
                  if (key === 'goalType') {
                    const [type, amount] = Object.entries(value)[0];
                    return (
                      <GoalDetail key={type}>
                        <GoalLabel>{type === 'CO2 Removed' ? 'CO₂ Removed' : type}:</GoalLabel>
                        {isEditing ? (
                          <GoalValue>
                            <input
                              type="number"
                              value={editedData.goalType[type]}
                              onChange={(e) => setEditedData({
                                ...editedData,
                                goalType: { [type]: e.target.value }
                              })}
                            />
                          </GoalValue>
                        ) : (
                          <GoalValue>{amount}</GoalValue>
                        )}
                      </GoalDetail>
                    );
                  }
                  if (key === 'endDate' || key === 'startDate') {
                    return (
                      <GoalDetail key={key}>
                        <GoalLabel>{key === 'startDate' ? 'Start Date' : 'End Date'}:</GoalLabel>
                        {isEditing && key === 'endDate' ? (
                          <GoalValue>
                            <input
                              type="date"
                              value={moment(editedData.endDate).format('YYYY-MM-DD')}
                              min={moment(goal.startDate).tz(moment.tz.guess()).format('YYYY-MM-DD')}
                              onChange={(e) => setEditedData({
                                ...editedData,
                                endDate: e.target.value
                              })}
                            />
                          </GoalValue>
                        ) : (
                          <GoalValue>{moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY')}</GoalValue>
                        )}
                      </GoalDetail>
                    );
                  }
                  if (key === 'addedAt' || key === 'modifiedAt') {
                    return (
                      <GoalDetail key={key}>
                        <GoalLabel>{key}:</GoalLabel>
                        <GoalValue>{moment(value).tz(moment.tz.guess()).format('MM/DD/YYYY, HH:mm:ss')}</GoalValue>
                      </GoalDetail>
                    );
                  }
                  return (
                    <GoalDetail key={key}>
                      <GoalLabel>{key}:</GoalLabel>
                      <GoalValue>{value}</GoalValue>
                    </GoalDetail>
                  );
                }
                return null;
              })}
              <ProgressBar>
                <ProgressFill progress={progress} />
              </ProgressBar>
              <ProgressLabel>{progress.toFixed(2)}% Complete</ProgressLabel>
              <StatusIndicator style={{ color: status.color }}>
                {status.icon} {status.text}
              </StatusIndicator>
              {isGoalExpired && (
                <ExpiredGoalWarning>
                  <FaExclamationCircle />
                  This goal has expired. Consider updating or archiving it.
                </ExpiredGoalWarning>
              )}
              {!isEditing ? (
                <EditButton onClick={() => setIsEditing(true)}><FaEdit /> Edit</EditButton>
              ) : (
                <>
                  <SaveButton onClick={handleSave}><FaSave /> Save Changes</SaveButton>
                  <CancelButton onClick={() => setIsEditing(false)}><FaTimes /> Cancel</CancelButton>
                </>
              )}
              <DeleteButton onClick={() => setShowModal(true)}>
                <FaTrash /> Delete Goal
              </DeleteButton>
            </GoalContent>
          </GoalCard>

          <ChartContainer
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <Line options={chartOptions} data={chartData} />
            <InsightsSection>
              <AnimatePresence>
                {insights.map((insight, index) => (
                  <InsightCard
                    key={index}
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 50 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <InsightTitle>{insight.icon} {insight.title}</InsightTitle>
                    <InsightContent>{insight.content}</InsightContent>
                  </InsightCard>
                ))}
              </AnimatePresence>
            </InsightsSection>
          </ChartContainer>
        </SubBodyDiv>
      </BodyDiv>
    </MainContainer>

    <AnimatePresence>
      {showModal && (
        <Modal
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ModalContent
            initial={{ scale: 0.8, y: -50 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.8, y: 50 }}
          >
            <h2>Are you sure you want to delete this goal?</h2>
            <ConfirmButton onClick={handleDelete}>Yes, Delete</ConfirmButton>
            <CancelButton onClick={() => setShowModal(false)}>Cancel</CancelButton>
          </ModalContent>
        </Modal>
      )}
    </AnimatePresence>
  </PageContainer>
);
}

export default GoalIndividualBuyerPage;

