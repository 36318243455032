import React, { useEffect, useState } from "react";
import LandingBodyTabs from "./LandingBodyTabs";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../../UI/Library/PageStructure";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const slideIn = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   position: absolute;
//   width: 100%;
//   background: linear-gradient(145deg, #fff, #dbe2e9);
//   @media (max-width: 768px) {
//     padding-top: 10vh;
//   }
// `;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
    gap: 5vh;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const BodyDiv = styled.div`
  margin-top: 5%;
  flex: 1;
`;

const DocumentationSection = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.5s ease-out forwards;
`;

const FAQSection = styled.div`
  animation: ${fadeIn} 0.5s ease-out;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const HelpSection = styled.div`
  padding: 20px;
  background-color: #f0f4f8;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: ${appear} 0.5s ease-out forwards;
`;

const SectionTitle = styled.h2`
  color: #4a90e2;
  text-align: center;
  margin-bottom: 30px;
`;

const Paragraph = styled.p`
  color: #666;
  //  color: #6c757d;
  line-height: 1.6;
  text-align: left;
  margin-bottom: 20px;
`;

const ParagraphFAQ = styled(Paragraph)`
  width: 75%;
  text-align: left;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  background: linear-gradient(145deg, #ffffff, #ece9e6);
  border-radius: 10px;
  margin: 10px 0;
  padding: 15px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.01);
  }
`;

const Answer = styled.div`
  margin-top: 10px;
  color: #555;
  display: none;
  ${ListItem}:hover & {
    display: block;
  }
`;

const Icon = styled.span`
  background: #4a90e2;
  color: white;
  padding: 8px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

const Question = styled.div`
  color: #333;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
`;

const Input = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  margin-top: 10px;
  border: none;
  background-color: #28a745;
  color: white;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const DocumentationList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
`;

const DocumentationListItem = styled.div`
  background: #e9ecf2;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    background: #dde1e7;
    transform: translateY(-5px);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
  }
`;

const DocumentationItemTitle = styled.h3`
  color: #4a90e2;
  font-size: 18px;
  margin-bottom: 10px;
`;

const DocumentationItemParagraph = styled.p`
  color: #6c757d;
  line-height: 1.6;
`;

const HelpSectionTitle = styled.h2`
  color: #5d647b;
  text-align: center;
  margin-bottom: 20px;
`;

const HelpParagraph = styled.p`
  color: #6c757d;
  line-height: 1.6;
  text-align: center;
  margin-bottom: 20px;
`;

const HelpContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  animation: ${appear} 0.5s ease-out forwards;
`;

const HelpInput = styled.input`
  width: 80%;
  padding: 10px;
  margin: 5px 0;
  border: 2px solid #dde1e7;
  border-radius: 20px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #a5b4cb;
    outline: none;
  }

  &::placeholder {
    color: #a5b4cb;
  }
`;

const HelpTextArea = styled.textarea`
  width: 80%;
  padding: 10px;
  margin: 5px 0;
  border: 2px solid #dde1e7;
  border-radius: 20px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #a5b4cb;
    outline: none;
  }

  &::placeholder {
    color: #a5b4cb;
  }
`;

const HelpSubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #3a78c3;
  }
`;

function Documentation() {
  return (
    <DocumentationSection>
      <SectionTitle>Documentation</SectionTitle>
      <DocumentationItemParagraph>
        Welcome to our documentation page! Here you will find all the
        information you need to make the most out of our product.
      </DocumentationItemParagraph>
      <DocumentationList>
        <DocumentationListItem>
          <DocumentationItemTitle>Getting Started</DocumentationItemTitle>
          <DocumentationItemParagraph>
            Start here to learn the basics of how to get up and running quickly.
          </DocumentationItemParagraph>
        </DocumentationListItem>
        <DocumentationListItem>
          <DocumentationItemTitle>Feature Guides</DocumentationItemTitle>
          <DocumentationItemParagraph>
            Dive into detailed guides on using each feature effectively.
          </DocumentationItemParagraph>
        </DocumentationListItem>
        <DocumentationListItem>
          <DocumentationItemTitle>
            Advanced Configuration
          </DocumentationItemTitle>
          <DocumentationItemParagraph>
            Learn how to customize and configure your setup for the best
            experience.
          </DocumentationItemParagraph>
        </DocumentationListItem>
        <DocumentationListItem>
          <DocumentationItemTitle>Troubleshooting</DocumentationItemTitle>
          <DocumentationItemParagraph>
            Find solutions to common problems and learn how to solve them
            quickly.
          </DocumentationItemParagraph>
        </DocumentationListItem>
      </DocumentationList>
    </DocumentationSection>
  );
}

function FAQ() {
  return (
    <FAQSection>
      <SectionTitle>Frequently Asked Questions</SectionTitle>
      <Paragraph>
        Here are some of the most frequently asked questions by our users:
      </Paragraph>
      <List>
        <ListItem>
          <Question>
            <span>How do I reset my password?</span>
            <Icon>?</Icon>
          </Question>
          <Answer>
            <Paragraph>
              Go to the login page and click on "Forgot Password?" to reset your
              password.
            </Paragraph>
          </Answer>
        </ListItem>
        <ListItem>
          <Question>
            <span>Can I change my subscription plan at any time?</span>
            <Icon>?</Icon>
          </Question>
          <Answer>
            <Paragraph>
              Yes, you can change your subscription plan from your account
              settings at any time.
            </Paragraph>
          </Answer>
        </ListItem>
        <ListItem>
          <Question>
            <span>Can I upload any amount of carbon credits that I wish?</span>
            <Icon>?</Icon>
          </Question>
          <Answer>
            <Paragraph>
              With the documentation that you submit for your projects, you will have as many credits associated with your projects as issued by the relevant VVB that you work with.
            </Paragraph>
          </Answer>
        </ListItem>
        <ListItem>
          <Question>
            <span>
              I am having trouble adding team members to my account. How can I
              do this?
            </span>
            <Icon>?</Icon>
          </Question>
          <Answer>
            <Paragraph>
              Please contact us through the "Get in Touch" button. We will
              assist you in setting this up. In the future, we will have
              functionality for performing team setup.
            </Paragraph>
          </Answer>
        </ListItem>
      </List>
    </FAQSection>
  );
}

function Help({ user }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [feedback, setFeedback] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();


    const formData = {
      name,
      email,
      message: `I am a seller on your platform: ${message}`,
    };

    try {
      //console.log("formData", formData);
      const response = await axios.post(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/email",
        formData
      );

      console.log("Email sent: ", response.data);
      setFeedback("Message sent!");
      setTimeout(() => {
        navigate("/Overview");
      }, 2000);
    } catch (error) {
      console.log("Error sending email: ", error.response?.data);
      setFeedback("Error sending email. Please try again.");
    }
  };

  useEffect(() => {
    //set email field if in username
    if (user.username && user.username.includes("@")) {
      setEmail(user.username);
    }
  }, [user.username]);

  return (
    <HelpSection>
      <HelpSectionTitle>Get in Touch</HelpSectionTitle>
      <HelpParagraph>
        Need help? Fill out the form below and our support team will get back to
        you as soon as possible.
      </HelpParagraph>
      <HelpContactForm onSubmit={handleSubmit}>
        <HelpInput
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {!user.username && (
          <HelpInput
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        )}
        <HelpTextArea
          placeholder="Your Message"
          rows="5"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></HelpTextArea>
        <HelpSubmitButton type="submit">Submit</HelpSubmitButton>
      </HelpContactForm>
      {feedback && <p>{feedback}</p>}{" "}
      {/* Display feedback messages to the user */}
    </HelpSection>
  );
}

function HelpPage() {
  const [view, setView] = useState("FAQ");
  const [user, setUser] = useState({});

  const username = localStorage.getItem('user');
  const firebaseIdToken = localStorage.getItem('firebase_id_token');
  const authHeader = {
    Authorization: `Bearer ${firebaseIdToken}`
  };

  useEffect(() => {
    const fetchUserData = async () => {

      try {
        const userData = await axios.get(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/all_user_data",
          {
            params: {
              username: username
            },
            headers: authHeader
          },
        );
        setUser(userData.data.data);
        console.log(userData.data.data);
      } catch (error) {
        console.log("Error: ", error);
      }
    };
    fetchUserData();
  }, []);

  const renderView = () => {
    switch (view) {
      case "FAQ":
        return <FAQ />;
      case "Documentation":
        return <Documentation />;
      case "Help":
        return <Help user={user} />;
      default:
        return <FAQ />;
    }
  };

  return (
    <PageContainer>
      <BodyContainer>
        <BodyDiv>
          <Button onClick={() => setView("FAQ")}>FAQ</Button>
          {/* <Button onClick={() => setView('Documentation')}>Documentation</Button> */}
          <Button onClick={() => setView("Help")}>Get in Touch</Button>

          {renderView()}

          {/* Insert 3 buttons here for "FAQ", "Documentation", "Help", with default to FAQ*/}
        </BodyDiv>
      </BodyContainer>
      </PageContainer>
  );
}

export default HelpPage;
