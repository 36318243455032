import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Title,
  StepWrapper,
  StepContent,
} from './MarketingComponents/StyledComponents';
import StepOne from './MarketingComponents/StepOne';
import OutputDisplay from './MarketingComponents/OutputDisplay';
import '@fontsource/raleway'; // A soft, friendly font
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: 'Raleway', sans-serif;
    background-color: #FFF9C4;
  }

  * {
    box-sizing: border-box;
  }
`;

function Home() {
  const [step, setStep] = useState(1);
  const [direction, setDirection] = useState('next');
  const [formData, setFormData] = useState({
    companyName: '',
  });
  const [outputData, setOutputData] = useState(null);
  const [lastInputData, setLastInputData] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleOutput = (data) => {
    if (data && data.result) {
      setOutputData(data.result);
      setLastInputData({
        website_url: formData.companyName,
        topic: formData.topic
      });
    } else {
      console.error('No result found in data');
      alert('No result found in response data');
    }
  };

  const handleBack = () => {
    setOutputData(null);
    setLastInputData(null);
    setFormData({
      companyName: '',
    });
  };

  const handleNewPrompt = async (payload) => {
    try {
      const response = await axios.post('https://marketing-llm-316212251926.us-west2.run.app/process', payload, {
        headers: { 'Content-Type': 'application/json' },
      });
      
      if (response.data && response.data.result) {
        setOutputData(response.data.result);
        setLastInputData(payload);
      } else {
        alert('No result found in response data');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while processing your request.');
    }
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <StepWrapper>
          {!outputData ? (
            <StepContent key="step1" direction={direction}>
              <Title>Welcome!</Title>
              <p style={{marginTop: '20px'}}>Enter your website link and a short description about content you wish to create, then step back and let us draft it for you!</p>
              <StepOne
                formData={formData}
                handleChange={handleChange}
                handleOutput={handleOutput}
              />
            </StepContent>
          ) : (
            <StepContent key="output" direction={direction}>
              <Title>Result</Title>
              <OutputDisplay 
                output={outputData}
                onBack={handleBack}
                onSubmit={handleNewPrompt}
                inputData={lastInputData}
              />
            </StepContent>
          )}
        </StepWrapper>
      </Container>
    </>
  );
}

export default Home;
