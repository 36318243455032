import React from 'react';
import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';

const SearchBarContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: 1rem 0;
`;

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f7f9;
  border-radius: 20px;
  padding: 0.5rem 1rem;
`;

const SearchInput = styled.input`
  border: none;
  background: transparent;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  width: 100%;
  
  &:focus {
    outline: none;
  }
`;

const SearchBar = ({ searchTerm, onSearch, placeholder }) => {
  return (
    <SearchBarContainer>
      <SearchBarWrapper>
        <FiSearch color="#5F7D95" />
        <SearchInput 
          type="text" 
          value={searchTerm}
          placeholder={placeholder || "Search..."} 
          onChange={(e) => onSearch(e.target.value)}
        />
      </SearchBarWrapper>
    </SearchBarContainer>
  );
};

export default SearchBar;