import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Tooltip } from 'recharts';

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
  const adjustedAngle = midAngle + 45;
  const sin = Math.sin(-RADIAN * adjustedAngle);
  const cos = Math.cos(-RADIAN * adjustedAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 20) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 5;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const fontSize = Math.min(outerRadius * 0.16, 12);

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize={fontSize}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 3}
        outerRadius={outerRadius + 5}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text 
        x={ex + (cos >= 0 ? 1 : -1) * 6} 
        y={ey} 
        textAnchor={textAnchor} 
        fill="#333"
        fontSize={fontSize}
      >{`${value.toFixed(2)} tons`}</text>
      <text 
        x={ex + (cos >= 0 ? 1 : -1) * 6} 
        y={ey} 
        dy={fontSize + 2} 
        textAnchor={textAnchor} 
        fill="#999"
        fontSize={fontSize * 0.78}
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};
export default class CarbonRemovedProgressPie extends PureComponent {

  state = {
    activeIndex: 0,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  render() {
    const { data, width = "100%", height = "100%" } = this.props;
    return (
      <ResponsiveContainer width={width} height={height}>
        <PieChart>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx={width/2}
            cy={height/2}
            innerRadius={50}
            outerRadius={70}
            dataKey="value"
            onMouseEnter={this.onPieEnter}
          />
        </PieChart>
      </ResponsiveContainer>
    );
  }
}
