import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Line, LineChart } from 'recharts';
import { Treemap, Tooltip as TreemapTooltip } from 'recharts';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PageContainer, MainContainer } from '../../../UI/Library/PageStructure';
import { HeaderContainer } from '../../../UI/Library/SellerComponents';
import { motion, AnimatePresence } from 'framer-motion';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
// import { PageContainer } from '../../../UI/Library/PageStructure';

const HeaderAdjusted = styled.div`
  padding-left: 2.5%;
  padding-right: 1%;
  text-align: left;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #1e2938;
  margin: 0;
`;

const SubTitle = styled.p`
  font-size: 14px;
  color: #6c7a89;
  margin: 5px 0 0;
`;

const HelpLink = styled.a`
  color: #1a99d4;
  text-decoration: none;
  font-weight: bold;
`;

const TabContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  background-color: #e0e5e9;
  border-radius: 20px;
  padding: 3px;
  width: fit-content;
`;

const Tab = styled.button`
  padding: 8px 16px;
  background-color: ${props => props.active ? '#fff' : 'transparent'};
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  color: ${props => props.active ? '#1e2938' : '#6c7a89'};
  transition: all 0.3s ease;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TopRow = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const BottomRow = styled.div`
  width: 100%;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
`;

const CardTitle = styled.h2`
  font-size: 18px;
  color: #345D72;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: left;
`;

const AvailableCreditsCard = styled(Card)`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const CreditsToDeliverCard = styled(Card)`
  flex: 7;
`;

const ProgressBarContainer = styled.div`
  background-color: #e0e5e9;
  border-radius: 10px;
  height: 15px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
`;

const ProgressBar = styled.div`
  background-color: #00a3b8;
  height: 100%;
  width: ${props => props.percent}%;
  transition: width 0.5s ease-in-out;
`;

const ProgressLabel = styled.span`
  color: #1e2938;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
`;

const CreditText = styled(ProgressLabel)`
  color: #1EB1B5;
`;

const ProgressArrow = styled.div`
  position: absolute;
  top: -20px;
  left: ${props => props.percent}%;
  transform: translateX(-50%);
  color: #00a3b8;
  font-weight: bold;
`;

const DeliverySection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const DeliveryColumn = styled.div`
  flex: 1;
  padding: 0 10px;
`;

const DeliveryTitle = styled.h3`
  font-size: 14px;
  color: #1e2938;
  margin-bottom: 10px;
  font-weight: bold;
`;

const DeliveryTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f5f7f9;
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #e0e5e9;
  font-weight: normal;
  color: #6c7a89;
`;

const Td = styled.td`
  padding: 8px;
  border-bottom: 1px solid #e0e5e9;
  color: #1e2938;
`;

const TreemapContainer = styled.div`
  height: 200px;
  margin-top: 20px;
`;

const PredictionBox = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #e0e5e9;
  border-radius: 4px;
  padding: 15px;
  height: 100%;
`;

const PredictionTitle = styled.h3`
  font-size: 16px;
  color: #1e2938;
  margin-bottom: 10px;
`;

const AvailableCreditsChart = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 20px;
`;

const CreditBar = styled.div`
  background-color: ${props => props.color};
  height: ${props => props.height}%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: #fff;
  font-weight: bold;
  position: relative;
`;

const CreditSubBar = styled.div`
  background-color: rgba(255, 255, 255, 0.2);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 33%;
  height: 100%;
`;

const CreditBarText = styled.div`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
`;

const CreditBarPercentage = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-weight: bold;
  font-size: 14px;
`;

const InsightsPage = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('internal');
  const [userData, setUserData] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [company, setCompany] = useState("");
  const [grabbedCompany, setGrabbedCompany] = useState(false);
  const [contracts, setContracts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [processedData, setProcessedData] = useState({
    availableCreditsData: [],
    creditsToDeliverData: [],
    pendingDeliveryData: [],
    annualCreditData: [],
    upcomingDeliveryData: [],
    totalCredits: 0
  });
  const [hasData, setHasData] = useState(false);
  const [useSampleData, setUseSampleData] = useState(false);


  const authHeader = {
    Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`
  };
  const username = localStorage.getItem("user");

  useEffect(() => {
    const getCompany = async () => {

      try {

        const res = await axios.get(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/profile_company",
            {
              params: {
                username: username
              },
              headers: authHeader
            }
        );

        const fetchedComp = res.data.data;
        console.log("fetchedComp", fetchedComp);
        setCompany(fetchedComp);
        setGrabbedCompany(true);
      } catch (error) {
        console.log(error);
      }
    };
  
    getCompany();
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      if (grabbedCompany) {
        setIsLoading(true);

        try {
          const [projectsRes, contractsRes] = await Promise.all([
            axios.get(
                'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/company_projects',
                {
                  params: {
                    username: username
                  },
                  headers: authHeader
                }),

            axios.get(
                'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/company_contracts',
                {
                  params: {
                    company_name: company
                  },
                  headers: authHeader
                })
          ]);
  
          setProjects(projectsRes.data.data.Projects || {});
          setContracts(contractsRes.data.data || {});
          console.log("Projects", projectsRes.data.data.Projects);
          console.log("Contracts", contractsRes.data.data);

        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchData();
  }, [grabbedCompany, company]);
  
  useEffect(() => {
    if (!isLoading) {
      if (Object.keys(projects || {}).length === 0 && Object.keys(contracts || {}).length === 0) {
        setHasData(false);
        setProcessedData(generateSampleData());
      } else {
        setHasData(true);
        const availableCreditsData = processAvailableCreditsData(projects, contracts);
        const creditsToDeliverData = processCreditsToDeliverData(contracts);
        const pendingDeliveryData = processPendingDeliveryData(contracts);
        const annualCreditData = processAnnualCreditData(projects, contracts);
        const upcomingDeliveryData = processUpcomingDeliveryData(contracts);
        const totalCredits = calculateTotalCredits(projects);
  
        setProcessedData({
          availableCreditsData,
          creditsToDeliverData,
          pendingDeliveryData,
          annualCreditData,
          upcomingDeliveryData,
          totalCredits
        });
      }
    }
  }, [isLoading, projects, contracts]);


  const calculateTotalCredits = (projects) => {
    return Object.values(projects).reduce((sum, project) => 
      sum + (parseInt(project?.credits_per_year) || 0), 0);
  };

  const generateSampleData = () => {
    return {
      availableCreditsData: [
        { name: 'Available', value: 3000, percentage: '60%', color: '#a3e5dd' },
        { name: 'Sold', value: 2000, percentage: '40%', color: '#48a7a1' },
      ],
      creditsToDeliverData: [
        { name: 'Delivered', value: 1500 },
        { name: 'Pending', value: 500 },
      ],
      pendingDeliveryData: [
        { name: 'Contract A', size: 300, color: '#a3e5dd' },
        { name: 'Contract B', size: 200, color: '#48a7a1' },
      ],
      annualCreditData: [
        { name: 'Jan', actual: 100, target: 80 },
        { name: 'Feb', actual: 200, target: 160 },
        { name: 'Mar', actual: 300, target: 240 },
        { name: 'Apr', actual: 400, target: 320 },
        { name: 'May', actual: 500, target: 400 },
        { name: 'Jun', actual: 600, target: 480 },
        { name: 'Jul', actual: 700, target: 560 },
        { name: 'Aug', actual: 800, target: 640 },
        { name: 'Sep', actual: null, target: 720 },
        { name: 'Oct', actual: null, target: 800 },
        { name: 'Nov', actual: null, target: 880 },
        { name: 'Dec', actual: null, target: 960 },
      ],
      upcomingDeliveryData: [
        { project: 'Sample Client', credits: 100, date: '2024-08-01' },
        { project: 'Sample Client 2', credits: 150, date: '2024-09-01' },
      ],
      totalCredits: 5000
    };
  };

  const processAvailableCreditsData = (projects, contracts) => {
    let totalCredits = 0;
    let availableCredits = 0;
    let soldCredits = 0;

    Object.values(projects).forEach(project => {
      if (project.credits_per_year) {
        totalCredits += parseInt(project.credits_per_year);
        availableCredits += parseInt(project.remaining_credits || 0);
        soldCredits += parseInt(project.credits_used || 0);
      }
    });

    const availablePercentage = totalCredits > 0 ? ((availableCredits / totalCredits) * 100).toFixed(1) : '0.0';
    const soldPercentage = totalCredits > 0 ? ((soldCredits / totalCredits) * 100).toFixed(1) : '0.0';

    return [
      { name: 'Available', value: availableCredits, percentage: `${availablePercentage}%`, color: '#a3e5dd' },
      { name: 'Sold', value: soldCredits, percentage: `${soldPercentage}%`, color: '#48a7a1' },
    ];
  };

  const processUpcomingDeliveryData = (contracts) => {
    return Object.values(contracts)
      .filter(contract => contract.purchaser && contract.contractSold)
      .map(contract => ({
        project: contract.purchaser,
        credits: contract.tonsToCapture,
        date: new Date(contract.timeSold).toLocaleDateString()
      }))
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 4);
  };

  const processCreditsToDeliverData = (contracts) => {
    let delivered = 0;
    let pending = 0;

    Object.values(contracts).forEach(contract => {
      if (typeof contract === 'object') {
        delivered += parseInt(contract.carbonRemoved || 0);
        pending += parseInt(contract.tonsToCapture || 0) - parseInt(contract.carbonRemoved || 0);
      }
    });

    return [
      { name: 'Delivered', value: delivered },
      { name: 'Pending', value: pending },
    ];
  };

  const processPendingDeliveryData = (contracts) => {
    const purchasedContracts = Object.values(contracts).filter(contract => 
      typeof contract === 'object' && contract.purchaser
    );

    const sizes = purchasedContracts.map(contract => parseInt(contract.tonsToCapture || 0));
    const minSize = Math.min(...sizes);
    const maxSize = Math.max(...sizes);

    return purchasedContracts.map(contract => ({
      name: contract.contractName,
      size: parseInt(contract.tonsToCapture || 0),
      color: getTealGradientColor(parseInt(contract.tonsToCapture || 0), minSize, maxSize)
    }));
  };

  const processAnnualCreditData = (projects, contracts) => {
    if (!projects || typeof projects !== 'object' || !contracts || typeof contracts !== 'object') {
      console.error('Invalid projects or contracts data');
      return [];
    }
  
    const totalCreditsPerYear = Object.values(projects).reduce((sum, project) => 
      sum + (parseInt(project?.credits_per_year) || 0), 0);
  
    const soldContracts = Object.values(contracts).filter(contract => 
      contract && contract.contractSold === true
    );
  
    const monthlyData = Array(12).fill().map((_, i) => ({
      name: new Date(2024, i).toLocaleString('default', { month: 'short' }),
      actual: 0,
      target: Math.round(totalCreditsPerYear * (i + 1) / 12)
    }));
  
    soldContracts.forEach(contract => {
      if (contract && contract.modifiedAt) {
        const modifiedDate = new Date(contract.modifiedAt);
        const monthIndex = modifiedDate.getMonth();
        if (monthIndex >= 0 && monthIndex < 12) {
          monthlyData[monthIndex].actual += parseInt(contract.tonsToCapture || '0');
        }
      }
    });
  
    let cumulativeActual = 0;
    monthlyData.forEach(data => {
      if (data && typeof data.actual === 'number') {
        cumulativeActual += data.actual;
        data.actual = cumulativeActual;
      } else {
        console.error('Invalid data object in monthlyData:', data);
      }
    });
  
    return monthlyData;
  };

  const generateRecommendations = (annualCreditData, availableCreditsData) => {
    const currentMonth = new Date().getMonth();
    const currentMonthData = annualCreditData[currentMonth];
    const recommendations = [];
  
    if (currentMonthData && currentMonthData.actual < currentMonthData.target) {
      const difference = currentMonthData.target - currentMonthData.actual;
      recommendations.push(`You are currently behind pace by ${difference} credits. Bolster your sales process this month to begin catching back up.`);
    }
  
    const availableCredits = availableCreditsData.find(item => item.name === 'Available');
    const soldCredits = availableCreditsData.find(item => item.name === 'Sold');
    if (availableCredits && soldCredits && availableCredits.value > soldCredits.value) {
      recommendations.push(
        <span>
          You have a lot more contracts that you can generate with available credits.{' '}
          <a href="#" onClick={(e) => { e.preventDefault(); navigate('/ContractBuilder'); }}>
            Click here to generate a new contract.
          </a>
        </span>
      );
    }
  
    if (currentMonthData && currentMonthData.actual > currentMonthData.target) {
      recommendations.push("Nice job selling! Chat with your technical team to make sure you are meeting pace with deliveries.");
    }
  
    return recommendations;
  };

  const getTealGradientColor = (value, min, max) => {
    const ratio = (value - min) / (max - min);
    const r = Math.round(72 + (163 - 72) * ratio);
    const g = Math.round(167 + (229 - 167) * ratio);
    const b = Math.round(161 + (221 - 161) * ratio);
    return `rgb(${r},${g},${b})`;
  };
  return (
    <PageContainer style={{backgroundColor: '#f0f2f5', paddingTop: '1%'}}>
      <HeaderAdjusted>
        <Header>
          <div>
            <Title>Insights</Title>
            <SubTitle>A deep dive into your data</SubTitle>
          </div>
          <HelpLink href="/support">Help?</HelpLink>
        </Header>
  
        <TabContainer>
          <Tab active={activeTab === 'internal'} onClick={() => setActiveTab('internal')}>Internal</Tab>
          <Tab active={activeTab === 'market'} onClick={() => setActiveTab('market')}>Market</Tab>
        </TabContainer>
      </HeaderAdjusted>
  
      <MainContainer>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh', width: '100%'}}>
            <Spinner />
          </div>
        ) : (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              {!hasData && (
                <div style={{backgroundColor: '#ffeeba', padding: '10px', marginBottom: '20px', borderRadius: '5px'}}>
                  No data available. This is sample data. Start uploading your projects and creating contracts to view your actual operations.
                </div>
              )}
  
              <ContentContainer>
                <TopRow>
                  <AvailableCreditsCard>
                    <CardTitle>Available Credits for Sale</CardTitle>
                    <AvailableCreditsChart>
                      {processedData.availableCreditsData.map((item, index) => (
                        <CreditBar key={index} color={item.color} height={(item.value / (processedData.totalCredits || 1)) * 100}>
                          <CreditBarText>{item.name}: {item.value} credits</CreditBarText>
                          <CreditBarPercentage>{item.percentage}</CreditBarPercentage>
                          <CreditSubBar />
                        </CreditBar>
                      ))}
                    </AvailableCreditsChart>
                  </AvailableCreditsCard>
  
                  <CreditsToDeliverCard>
                    <CardTitle style={{textAlign: 'left'}}>Credits to Deliver</CardTitle>
                    {processedData.creditsToDeliverData.length > 0 ? (
                      <>
                        <ProgressLabel style={{textAlign: 'left', color: '#345D72'}}>Total Credits Delivered: <CreditText style={{display: 'inline'}}>{processedData.creditsToDeliverData[0].value} credits</CreditText></ProgressLabel>
                        <ProgressBarContainer>
                          <ProgressBar percent={(processedData.creditsToDeliverData[0].value / (processedData.creditsToDeliverData[0].value + processedData.creditsToDeliverData[1].value || 1)) * 100} />
                          <ProgressArrow percent={(processedData.creditsToDeliverData[0].value / (processedData.creditsToDeliverData[0].value + processedData.creditsToDeliverData[1].value || 1)) * 100}>
                            ▼ {((processedData.creditsToDeliverData[0].value / (processedData.creditsToDeliverData[0].value + processedData.creditsToDeliverData[1].value || 1)) * 100).toFixed(0)}%
                          </ProgressArrow>
                        </ProgressBarContainer>
                        <ProgressLabel style={{textAlign: 'left', color: '#345D72'}}>Pending Delivery: <CreditText style={{display: 'inline'}}>{processedData.creditsToDeliverData[1].value} credits</CreditText></ProgressLabel>
                      </>
                    ) : (
                      <p>No credits to deliver data available.</p>
                    )}
                    <DeliverySection>
                      <DeliveryColumn style={{flex: 2}}>
                        <DeliveryTitle style={{textAlign: 'left'}}>Purchased Contracts</DeliveryTitle>
                        {processedData.pendingDeliveryData.length > 0 ? (
                          <TreemapContainer>
                            <ResponsiveContainer width="100%" height="100%">
                              <Treemap
                                data={processedData.pendingDeliveryData}
                                dataKey="size"
                                ratio={4/3}
                                stroke="#fff"
                                fill="#8884d8"
                                content={<CustomizedContent colors={processedData.pendingDeliveryData.map(item => item.color)} />}
                              >
                                <TreemapTooltip />
                              </Treemap>
                            </ResponsiveContainer>
                          </TreemapContainer>
                        ) : (
                          <p>No contracts have been purchased yet.</p>
                        )}
                      </DeliveryColumn>
                      <DeliveryColumn style={{flex: 1}}>
                        <DeliveryTitle style={{textAlign: 'left'}}>Upcoming Delivery</DeliveryTitle>
                        {processedData.upcomingDeliveryData && processedData.upcomingDeliveryData.length > 0 ? (
                          <DeliveryTable>
                            <thead>
                              <tr>
                                <Th>Purchaser</Th>
                                <Th>Credits</Th>
                                <Th>Date</Th>
                              </tr>
                            </thead>
                            <tbody>
                              {processedData.upcomingDeliveryData.map((item, index) => (
                                <tr key={index}>
                                  <Td style={{backgroundColor: '#DFEFF0', borderRadius: '10px'}}>{item.project}</Td>
                                  <Td>{item.credits}</Td>
                                  <Td>{item.date}</Td>
                                </tr>
                              ))}
                            </tbody>
                          </DeliveryTable>
                        ) : (
                          <p>No upcoming deliveries.</p>
                        )}
                      </DeliveryColumn>
                    </DeliverySection>
                  </CreditsToDeliverCard>
                </TopRow>
  
                <BottomRow>
                  <Card>
                    <CardTitle style={{textAlign: 'left'}}>Annual Credit Target</CardTitle>
                    <div style={{display: 'flex'}}>
                      <div style={{flex: 3}}>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={processedData.annualCreditData}>
                            <CartesianGrid strokeDasharray="3 3" stroke="#e0e5e9" />
                            <XAxis dataKey="name" stroke="#6c7a89" />
                            <YAxis stroke="#6c7a89" />
                            <Tooltip />
                            <Line type="monotone" dataKey="actual" stroke="#00a3b8" strokeWidth={2} dot={{ r: 4 }} />
                            <Line type="monotone" dataKey="target" stroke="#ff7f50" strokeWidth={2} strokeDasharray="5 5" />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                      <div style={{flex: 1, marginLeft: '20px'}}>
                        <PredictionBox>
                          <PredictionTitle>Recommendations</PredictionTitle>
                          <div style={{textAlign: 'left', margin: '10%'}}>
                            {generateRecommendations(processedData.annualCreditData, processedData.availableCreditsData).map((recommendation, index) => (
                              <p key={index} style={{marginBottom: '10px'}}>{recommendation}</p>
                            ))}
                          </div>
                        </PredictionBox>
                      </div>
                    </div>
                  </Card>
                </BottomRow>
              </ContentContainer>
            </motion.div>
          </AnimatePresence>
        )}
      </MainContainer>
    </PageContainer>
  );
};

const CustomizedContent = (props) => {
  const { depth, x, y, width, height, index, colors, name, value } = props;

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 ? colors[index] : 'none',
          stroke: '#fff',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
          rx: 5,
          ry: 5,
        }}
      />
      {depth === 1 && (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={14}
        >
          {name}
        </text>
      )}
      {depth === 1 && (
        <text
          x={x + 4}
          y={y + 18}
          fill="#fff"
          fontSize={16}
          fillOpacity={0.9}
        >
          {value}
        </text>
      )}
    </g>
  );
};

export default InsightsPage;