import React, { useState, useEffect, useRef } from 'react';
// import Header from '../LandingHeader';
import { NewHeader } from '../ExternalSite/NewHeader';
import Footer from "../LandingFooter";
import styled from 'styled-components';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import DisplayContent from './BlogHomeContent';
import NewFooter from '../ExternalSite/NewFooter';


const BodySection = styled.section`
  background-color: white; /* Light gray background */
  border-top: 1px solid #325d7980;
  padding-top: 0%;
  // padding: 6% 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: ${({ calculatedHeight }) => `calc(100vh - ${calculatedHeight}px)`};
`;

const HeaderWrapper = styled.div`
  background: linear-gradient(180deg, #093C57 0%, #24839A 100%);
  padding: 100px 20px;
  padding-bottom: 50px;
  text-align: center;
  color: white;
  min-height: 35vh;
  align-content: center;
`;

const Title = styled.h1`
  font-size: 3em;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  margin-top: 10px;
`;

const CategoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
//   border-bottom: 1px solid #ddd;
`;

const CategoryLink = styled.a`
  margin: 0 3%;
  font-size: 1em;
  color: #007db8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;


function BlogHome() {
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const [calculatedHeight, setCalculatedHeight] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const updateHeight = () => {
            if (headerRef.current && footerRef.current) {
                const headerHeight = headerRef.current.offsetHeight;
                const footerHeight = footerRef.current.offsetHeight;
                setCalculatedHeight(headerHeight + footerHeight);
                setLoading(false);
            }
        };
        updateHeight();

    }, []);

    return (
        <>
          <div ref={headerRef}>
            <NewHeader absolute={true}/>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <BodySection calculatedHeight={calculatedHeight}>
                <HeaderWrapper>
                    <Title>Carbonsmith Blog</Title>
                    <Subtitle>Sharing updates and insights on the latest in engineered carbon removal</Subtitle>
                </HeaderWrapper>
                <DisplayContent />
            </BodySection>
          )}
          <div ref={footerRef}>
            <NewFooter />
          </div>
        </>
      );
}


export default BlogHome;