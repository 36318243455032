import { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';

export const useSearch = (items, searchKeys, debounceTime = 300) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [initialState, setInitialState] = useState(true);
  const [statusFilter, setStatusFilter] = useState('');

  useEffect(() => {
    // Set initial state to false and update search results with items
    setInitialState(false);
    setSearchResults(items);
  }, [items]);

  const filteredItems = useMemo(() => {
    if (!searchTerm && !statusFilter && initialState) return items;

    // Filter items based on status and search term
    return items.filter(item => {
      const matchesStatus = statusFilter ? item.Status === statusFilter : true;
      const matchesSearchTerm = searchKeys.some(key =>
        item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
      return matchesStatus && matchesSearchTerm;
    });
  }, [items, searchTerm, searchKeys, statusFilter, initialState]);

  const debouncedSetSearchResults = useCallback(
    debounce((results) => {
      setSearchResults(results);
    }, debounceTime),
    [debounceTime]
  );

  useEffect(() => {
    if (searchTerm || statusFilter) {
      debouncedSetSearchResults(filteredItems);
    } else {
      setSearchResults(items || []);
    }
  }, [filteredItems, searchTerm, statusFilter, debouncedSetSearchResults, items]);

  return { 
    searchTerm, 
    setSearchTerm, 
    searchResults,
    isSearching: searchTerm !== '' || statusFilter !== '',
    setStatusFilter
  };
};

