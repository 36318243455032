import { Client, PrivateKey, AccountCreateTransaction, AccountId } from "@hashgraph/sdk";

//DEFINITELY DONT WANT TO BE SENDING PRIVATE KEY OVER FRONT-END CONNECTION SO UPDATE LATER
async function createAccount() {
    const client = Client.forTestnet();

    ///NEED TO CONCEAL THIS SOMEHOW 
    const MY_ACCOUNT_ID = AccountId.fromString('0.0.2695140');
    const MY_PRIVATE_KEY = PrivateKey.fromString('3030020100300706052b8104000a042204203346b155458fabdbc4cfcdc2ccfc905826618ec37b380b7450b2b69be76daf12')
    client.setOperator(MY_ACCOUNT_ID, MY_PRIVATE_KEY);

    const newAccountPrivateKey = await PrivateKey.generate();
    const newAccountPublicKey = newAccountPrivateKey.publicKey;

    //Create new account with initial balance
    const transaction = new AccountCreateTransaction()
        .setKey(newAccountPublicKey)
        .setInitialBalance(10);

    const response = await transaction.execute(client);
    const receipt = await response.getReceipt(client);

    const userInfo = {};

    userInfo['accountId'] = `${receipt.accountId.shard}.${receipt.accountId.realm}.${receipt.accountId.num}`;
    userInfo['privateKey'] = newAccountPrivateKey;

    return userInfo;

}

export default createAccount;