import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import AppRouter from "./components/UniversalFunctions/AppRouter";
import useAuthCheck from "./components/UniversalFunctions/JwtDecoding";
import { Router } from "react-router-dom";
import CookieBanner from "./components/UniversalFunctions/CookieBanner";

const GlobalStyle = createGlobalStyle`
body {
  font-family: 'Roboto', sans-serif;
}
  *, *::before, *::after {
    box-sizing: border-box;
  }
  // You can add more global styles here
`;

const AppStyle = styled.div`
  text-align: center;
`;

function App() {
  // useAuthCheck();

  /*Initialize analytics tracking
  useEffect(() => {
    
  }, []);*/

  return (
    <AppStyle>
      <GlobalStyle />

      <AppRouter />

      <CookieBanner />
    </AppStyle>
  );
}

export default App;
