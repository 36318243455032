import React, { useState, useEffect } from  'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import LandingBodyTabs from './LandingBodyTabs';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import { faDollarSign, faWeightHanging, faTag, faBuilding, faClipboardList, faEnvelope, faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { addedAt, modifiedAt } from '../../../UniversalFunctions/timestamps';
// import StripeConnectHome from '../../../UniversalFunctions/Stripe/StripeConnectHomeSeller';
import StripeConnectModal from '../../../UniversalFunctions/Stripe/StripeConnectHomeSeller';
import StripeService from '../../../UniversalFunctions/Stripe/StripeService';
import { CONFIG } from '../../../UniversalFunctions/Stripe/stripeConfig';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  margin-top: 10vh;
  // background: linear-gradient(145deg, #fff, #DBE2E9);
`;

const MainContainer = styled.div`
  // flex: 1;
  // background-color: #f5f5f5;
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5
  justify-content: space-between;
  padding: 2%;
`;

const borderFadeIn = keyframes`
  from {
    border-color: transparent;
  }
  to {
    border-color: #325D79;
  }
`;

const InstructionalSection = styled(motion.div)`
  flex: 1;
  padding: 2%;
  background: linear-gradient(145deg, #F0F9FF, #CBE0E3);
  color: #325D79;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  margin: 2%;
  border: 1px solid transparent;
  transition: border 2s ease-in-out;

  &:hover {
    transform: scale(1.01);
    border: 1px solid #325D79; /* Visible border on hover */
  }
`;

const SectionalMotion = {
  hidden: {
    boxShadow: "0 0 0px rgba(0,0,0,0)",
    opacity: 0,
    scale: 0.95
  },
  visible: {
    boxShadow: "0 0 8px 2px rgba(50,93,121,0.5)", //simulated border appearance
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.8,
      ease: "easeOut",
      boxShadow: { duration: 1.5 } // slower transition for boxshadow
    }
  }
}

const FormSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StyledSelect = styled.select`
  padding: 2% 2%;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: 16px;
  color: #333;
  margin-bottom: 5%;
  cursor: pointer;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  // width: 10%;

  &:hover, &:focus {
    border-color: #26a69a;
    box-shadow: 0 0 0 3px rgba(38, 166, 154, 0.5);
  }

`;

const ButtonChangeDiv = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
`;

const PageTitle = styled.h1`
  color: #325d79ff; /* Matching your form label color */
  font-size: 1.5rem; /* Adjust size as needed */
  text-align: center; /* Centering the title */
  margin: 2% 0; /* Adding some space above and below the title */
  width: 100%; /* Ensure it spans the full width */
  margin-bottom: 5%;
  border-bottom: 1px solid #325d79ff;
  padding-bottom: 6px;
  // border-radius: 30px;
  // border: 1px solid #325d7980;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  // align-items: center;
  width: calc(70% - 20px);
`;

const ProjectsInfoDiv = styled.div`
  width: 90%;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  background-color: white;
  padding: 3%;
  border-radius: 20px;
  min-height: auto;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  // height: 55%;
  border: 1px solid #325d7980;
  transition: all 0.3s ease-in-out;
  @media (max-width: 768px) {
    max-width: 80%;
  }

  &:hover {
    box-shadow: 0 5px 15px rgba(0,0,0,0.15);
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AllInputsDiv = styled.div`
  margin-right: 15%;
  text-align: right;
`;

const CreditsRemDiv = styled.div`
  align-content: left;
  text-align: left;
  padding: 0% 8%;
`;

const FormLabel = styled.label`
  margin-bottom: .75%;
  font-size: 1.1rem;
  font-weight: bold;
  color: #325d79ff;
  margin-right: 4%;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 5%;

  @media (max-width: 768px) {
    margin-bottom: 10%;
  }
`;

const Icon = styled.span`
  position: absolute;
  left: 5%;
  top: 40%;
  transform: translateY(-50%);
  color: #7a8e97;

  @media (max-width: 1000px) {
    top: 20%;
    left: 1%;
  }
`;

const FormInput = styled.input`
  padding: 1%;
  margin-bottom: 1.5%;
  font-size: 1rem;
  align-content: center;
  border-radius: 7px;
  border: 1px solid #325d7980;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:focus {
    border-color: #325d79;
    box-shadow: 0 0 0 2px #bbd1ea;
    outline: none;
  }

  @media (max-width: 768px) {
    margin-top: 3%;
    margin-bottom: 3%;
  }
`;

const SubmitButton = styled.button`
  background-color: #325d79ff;
  color: #fff;
  padding: 2% 3%;
  border: none;
  border-radius: 10px;
  font-size: 1.1em;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  width: 40%;
  margin-top: 2%;
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    background-color: #5f7d95ff;
    transform: translateY(-2px);
  }

  @media (max-width: 800px) {
    
  }
`;

const FormSelect = styled.select`
  padding: 1%;
  margin-bottom: 1.5%;
  font-size: 1em;
  border-radius: 7px;
  border: 1px solid #325d7980;
  transition: border 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:focus {
    border-color: #325d79;
    box-shadow: 0 0 0 2px #bbd1ea;
    outline: none;
  }
`;

const ButtonContainer = styled.div`
  justify-content: center;
`;

const GuideStep = styled(motion.div)`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const instructionalVariants = {
  hidden: { 
    opacity: 0, 
    scale: 0.95, 
    y: 30 
  },
  visible: {
    opacity: 1, 
    scale: 1, 
    y: 0,
    transition: { 
      duration: 0.8, 
      ease: "easeOut" 
    }
  }
};

const StepTitle = styled.h2`
  color: #325d79;
  margin-bottom: 10px;
  font-size: 1.5rem;
`;

const StepDescription = styled.p`
  color: #325d79;
`;

const LoadingAlert = styled.div`
  background-color: #f3f9e8;
  color: #739e9f;
  padding: 10px;
  margin: 10px 0;
  border-radius: 10px;
  text-align: center;
  font-weight: bold;
`;

const ApprovalPendingModal = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ApprovalPendingContent = styled(motion.div)`
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  border-radius: 20px;
  padding: 40px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const ApprovalPendingTitle = styled.h2`
  font-size: 2rem;
  color: #325d79;
  margin-bottom: 20px;
`;

const ApprovalPendingMessage = styled.p`
  font-size: 1.1rem;
  color: #5f7d95;
  margin-bottom: 30px;
  line-height: 1.6;
`;

const ApprovalPendingButton = styled(motion.button)`
  background: linear-gradient(45deg, #00c9ff, #92fe9d);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ApprovalPendingModalFull = ({ isOpen, onClose }) => {

  const navigate = useNavigate();
  return (
    <AnimatePresence>
      {isOpen && (
        <ApprovalPendingModal
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ApprovalPendingContent
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
          >
            <ApprovalPendingTitle>Approval Pending</ApprovalPendingTitle>
            <ApprovalPendingMessage>
              Your Stripe account is connected, but it hasn't been fully approved for creating markets yet. Please visit Stripe.com or our help page to resolve this issue.
            </ApprovalPendingMessage>
            <ApprovalPendingButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.open('https://stripe.com', '_blank')}
            >
              Go to Stripe
            </ApprovalPendingButton>
            <ApprovalPendingButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate('/support')}
            >
              Help Page
            </ApprovalPendingButton>
            <ApprovalPendingButton
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onClose}
            >
              Close
            </ApprovalPendingButton>
          </ApprovalPendingContent>
        </ApprovalPendingModal>
      )}
    </AnimatePresence>
  );
};
// background: linear-gradient(to right, #325d79ff, #5f7d95ff);

const guideSteps = [
  { title: "Creating Your Contract", description: "Creating a contract enables users to purchase your carbon removals. Begin by defining the scope of your environmental impact." },
  { title: "Specify Contract Details", description: "We make it straightforward and easy to define the terms of your contract. Just enter the amount of tons and price per ton." },
  { title: "Import Existing Contracts", description: 'If you are importing an existing contract, simply mark it as "In Progress".' },
  { title: "Integration Support", description: "Create your contract and we will assist in integrating your removal feed with our API." },
];


function ContractBuilder() {
    const navigate = useNavigate();
    const [showCompanyField, setShowCompanyField] = useState(true);
    const [projects, setProjects] = useState({});
    const [selectedProject, setSelectedProject] = useState(null);
    // const { contractName, tonsSold, priceTonUSD } = ['',0,0];
    const [contractData, setContractData] = useState({
        contractName: '',
        tonsToCapture: 0,
        pricePerTon: 0,
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [stripeAccountComplete,setStripeAccountComplete] = useState(false);
    const [showStripeModal,setShowStripeModal] = useState(false);
    const [showApprovalPendingModal, setShowApprovalPendingModal] = useState(false);
    const [company, setCompany] = useState('');

    const stripeService = new StripeService();

    const authHeader = {
        Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`
    };
    const username = localStorage.getItem("user");


    const handleProjectChange = (e) => {
      const selectedProjectId = e.target.value;
      const projectArray = Object.values(projects);
      const foundProject = projectArray.find(project => project.project_name === selectedProjectId);
      setSelectedProject(foundProject);
      setContractData(prevData => ({
        ...prevData,
        tonsToCapture: 0, //Reset tonsToCapture when a new project is selected
      }));
      setErrorMessage('');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        //ensure tonstocapture cant exceed remaining credits
        if (name === 'tonsToCapture') {
          if (selectedProject?.remaining_credits && value > selectedProject.remaining_credits) {
            setErrorMessage('Tons to Capture cannot exceed remaining credits');
            return;
          } else {
            setErrorMessage('');
          }
        }

        setContractData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!stripeAccountComplete) {
          setShowStripeModal(true);
          return;
        }

        setButtonDisabled(true)

        if (selectedProject?.remaining_credits === 0 || !selectedProject?.remaining_credits) {
          setErrorMessage('No credits available for this project');
          setButtonDisabled(false);
          return;
        }

        //handle submission logic
        contractData["name"] = contractData.contractName;
        contractData["tonsSold"] = contractData.tonsToCapture;
        contractData["priceTonUSD"] = contractData.pricePerTon;
        contractData["Revenue"] = `${contractData.tonsToCapture * contractData.pricePerTon} USD`;
        contractData['carbonRemoved'] = 0;
        contractData['company'] = contractData.company;
        contractData['addedAt'] = addedAt();
        contractData['modifiedAt'] = modifiedAt();
        contractData['contractProject'] = selectedProject;
        contractData['contractSold'] = false;
        contractData['revenueGenerated'] = 0;

        try {

            console.log(contractData);

            const res = await axios.post(
                'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/new_contract',
                contractData,
                {
                    params: {
                        username: username
                    },
                    headers: authHeader
                });

            if (res.status === 200) {
                console.log(`Successfully added ${contractData.contractName}!`)

                //console.log(res);

                //Making post request for subtracting credits in DB
                const subtractCreditsData = {
                  contractCredits: parseInt(contractData.tonsToCapture),
                  modifiedAt: modifiedAt()
                };

                //console.log(subtractCreditsData);
                //console.log(contractData.company);
                //console.log(selectedProject.project_name);

                const subtractResponse = await axios.put(
                    'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/subtract_credits',
                    subtractCreditsData,
                    {
                        params: {
                            company: contractData.company,
                            project_name: selectedProject.project_name
                        },
                        headers: authHeader
                    });


                if (subtractResponse.status === 200) {
                  console.log('Credits subtracted successfully')
                  setButtonDisabled(false);
                  navigate('/');
                } else {
                  console.log('Failed to subtract credits');
                  setButtonDisabled(false);
                }

            } else {
              console.log('Something went wrong'); // This line is usually not reached because axios throws on bad statuses
          }
      } catch (error) {
        setButtonDisabled(false)
          if (error.response && error.response.status === 400) {
              // Assuming your backend sends specific error messages under a key, e.g., `message` or `error`
              if (error.response.data && error.response.data.error && error.response.data.error.includes('This contract name already exists')) {
                  alert('This contract name already exists. Please try another name.');
              } else {
                  alert('Error: ' + error.response.data.message); // Adjust based on your backend error message structure
              }
          } else {
              console.error('Error:', error.message || 'Unknown error');
          }
      }
        //redirect to other desired page
    };

    const checkStripeAccountStatus = async () => {
      if (!CONFIG.ENABLE_STRIPE) {
        setStripeAccountComplete(true);
        return;
      }
      try {
        const { stripe_connected, charges_enabled, payouts_enabled } = await stripeService.checkAccountStatus(company);
  
        if (stripe_connected && charges_enabled && payouts_enabled) {
          setStripeAccountComplete(true);
        } else if (stripe_connected && !charges_enabled) {
          setShowApprovalPendingModal(true);
        } else {
          setShowStripeModal(true);
        }
      } catch (error) {
        console.error('Error checking Stripe account status:', error);
        setStripeAccountComplete(false);
      }
    };

    useEffect(() => {
      if (company !== '') {
        checkStripeAccountStatus();
      }
    }, [company]);

    useEffect(() => {
      const fetchCompany = async () => {

        try {
            //console.log("username", username);
            const res = await axios.get(
                'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/profile_company',
                {
                    params: {
                        username: username
                    },
                    headers: authHeader
                });

            //console.log("company name", res.data.data);

          const gettingProjects = await axios.get(
              'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/company_projects',
              {
                  params: {
                      username: username
                  },
                  headers: authHeader
              });
          //console.log("company projects", gettingProjects.data.data.Projects);

          const fetchedProjects = gettingProjects.data.data.Projects;
          if (res.data.data !== 'none') {
            setContractData(prevData => ({
              ...prevData,
              "company": res.data.data
            }));
            setShowCompanyField(false);
            setCompany(res.data.data);
          }
          if (Object.keys(gettingProjects.data.data.Projects).length > 0) {
            setProjects(gettingProjects.data.data.Projects);
            const projectArray = Object.values(fetchedProjects);
            setSelectedProject(projectArray[0]);
          }
        } catch (error) {
          console.log('Error fetching company ', error);
        }
      
      }
      fetchCompany();
    }, []);

    return (
            <MainContainer>
                <ContentContainer>
                  <InstructionalSection
                    initial="hidden"
                    animate="visible"
                    variants={SectionalMotion}
                  >
                    {guideSteps.map((step, index) => (
                      <GuideStep key={index}
                      initial="hidden"
                      animate="visible"
                      variants={instructionalVariants}
                      >
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                      </GuideStep>
                    ))}
                  </InstructionalSection>
                    {projects && Object.keys(projects).length > 0 ? (
                      <ProjectsInfoDiv>
                      <ButtonChangeDiv>
                        <StyledSelect onChange={handleProjectChange}>
                        {Object.values(projects).map((project) => (
                          <option key={project.project_name} value={project.project_name}>
                            {project.project_name}
                          </option>
                        ))}
                        </StyledSelect>
                      </ButtonChangeDiv>
                      <div key={selectedProject?.project_name || 'no_project'}>
                      {/* Check if selectedProject is defined and then check for remaining_credits */}
                      {selectedProject?.remaining_credits ? (
                          <CreditsRemDiv key={selectedProject.project_name + "_credits"}>
                              <FormLabel>{selectedProject.remaining_credits - contractData.tonsToCapture} credits remaining</FormLabel>
                          </CreditsRemDiv>
                      ) : (
                          <div>
                              {/* Provide a fallback message when remaining_credits are not available or project is undefined */}
                              No credits have been issued for this project or project details are unavailable
                          </div>
                      )}
                  </div>


                      </ProjectsInfoDiv>
                    ): (<></>)}
                    {stripeAccountComplete && (

                    <FormContainer>
                    <PageTitle>New Contract</PageTitle>
                        <Form onSubmit={handleSubmit}>
                          <AllInputsDiv>
                          { showCompanyField && (
                            <React.Fragment>
                              <InputGroup>
                              <Icon><FontAwesomeIcon icon={faBuilding} /></Icon>
                                <FormLabel>Company</FormLabel>
                                <FormInput
                                  type="text"
                                  id="company"
                                  name="company"
                                  value={contractData.company}
                                  onChange={handleChange}
                                  required
                                />
                              </InputGroup>
                            </React.Fragment>
                          )}
                            <InputGroup>
                              <Icon><FontAwesomeIcon icon={faTag} /></Icon>
                              <FormLabel>Contract Name</FormLabel>
                              <FormInput
                                  type="text"
                                  id="contractName"
                                  name="contractName"
                                  value={contractData.contractName}
                                  onChange={handleChange}
                                  required
                              />
                            </InputGroup>

                            <InputGroup>
                              <Icon><FontAwesomeIcon icon={faWeightHanging} /></Icon>
                              <FormLabel>Tons to Capture</FormLabel>
                              <FormInput
                                  type="number"
                                  id="tonsToCapture"
                                  name="tonsToCapture"
                                  value={contractData.tonsToCapture}
                                  onChange={handleChange}
                                  required
                                  disabled={!selectedProject?.remaining_credits}
                              />
                              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                            </InputGroup>

                            <InputGroup>
                              <Icon><FontAwesomeIcon icon={faDollarSign} /></Icon>
                              <FormLabel>Price per Ton (USD)</FormLabel>
                              <FormInput
                                  type="number"
                                  id="pricePerTon"
                                  name="pricePerTon"
                                  value={contractData.pricePerTon}
                                  onChange={handleChange}
                                  required
                              />
                            </InputGroup>

                            <InputGroup>
                              <Icon><FontAwesomeIcon icon={faClipboardList} /></Icon>
                              <FormLabel>Status</FormLabel>
                              <FormSelect
                                type="text"
                                id="Status"
                                name="Status"
                                value={contractData.Status}
                                onChange={handleChange}
                                required
                              >
                                <option value="">Select Status</option>
                                <option value="Uninitialized">Uninitialized</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Completed">Completed</option>
                                </FormSelect>
                              </InputGroup>
                              <ButtonContainer>
                                <SubmitButton disabled={buttonDisabled || !stripeAccountComplete} type="submit">Create Contract</SubmitButton>
                            </ButtonContainer>
                            {/* STRIPE ACCOUNT CHECK - Comment out this block when testing other components */}
                            {!stripeAccountComplete && (
                              <LoadingAlert>
                                Please set up your Stripe account to create contracts.
                                <button onClick={() => {setShowStripeModal(true)}}>show</button>
                              </LoadingAlert>
                            )}
                            {/* END STRIPE ACCOUNT CHECK */}
                            {buttonDisabled && (
                              <LoadingAlert>
                                Please wait while we deploy your contract on the blockchain. This may take up to 10 seconds.
                              </LoadingAlert>
                            )}
                            </AllInputsDiv>
                        </Form>
                    </FormContainer>
                    )}

                    {CONFIG.ENABLE_STRIPE && (
                    <StripeConnectModal 
                      isOpen={showStripeModal} 
                      onClose={() => setShowStripeModal(false)}
                      company={contractData.company}
                      onAccountCreated={checkStripeAccountStatus}
                    />
                    )}

                    {!showStripeModal && (
                      <ApprovalPendingModalFull
                        isOpen={showApprovalPendingModal}
                        onClose={() => setShowApprovalPendingModal(false)}

                      />
                    )}

                    {/* END STRIPE ACCOUNT CHECK */}

                </ContentContainer>


            </MainContainer>
    )
}

export default ContractBuilder;