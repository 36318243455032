import React from 'react';
import styled from 'styled-components';

// Styled components for the Footer
const FooterContainer = styled.footer`
  // background-color: #00796B;
  background-color: #325d79ff;
  color: #fff;
  padding: 3% 0;
`;

const FooterContent = styled.div`
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 5%;
`;

const CopyrightText = styled.p`
  font-size: .9em;
`;



function Footer() {
  return (
    <FooterContainer>
      <FooterContent>
        <CopyrightText>&copy; 2023 Carbonsmith. All rights reserved.</CopyrightText>
        {/* Add additional footer content here */}
      </FooterContent>
    </FooterContainer>
  );
}

export default Footer;
