import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import cocoonHeadshot from "../../../images/aboutme-cocoon.png";
import drewHeadshot from "../../../images/aboutme-drew.png";
import FlexContainer from "../../UI/Library/Container/FlexContainer";
import { NewHeader } from "./ExternalSite/NewHeader";
import mountainPng from "../../../images/aboutpage-Mountains.png";
import NewFooter from "./ExternalSite/NewFooter";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const animatedStyle = css`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(10px)')};
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.6s ease-out;
  transition: opacity 0.75s ease-out, transform 0.75s ease-out;
`;

const MainContainer = styled(FlexContainer)`
  background: linear-gradient(180deg, #093c57 0%, #568893 30%, #ffffff 80%);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0 10%;
  gap: 40vh;
  @media (max-width: 1024px) {
    gap: 20vh;
  }
`;

const VisionDiv = styled(FlexContainer)`
  flex-direction: column;
  text-align: left;
  padding-top: 15%;
  gap: 20px;
  ${animatedStyle}
  @media (max-width: 1024px) {
    flex-direction: column;
    padding-top: 30%;
    align-items: center;
  }
`;

const VisionTitle = styled.h4`
  font-size: 30px;
  font-weight: 700;
  line-height: 35.16px;
  padding-bottom: 4%;
  ${animatedStyle}
`;

const VisionText = styled.h6`
  font-size: 70px;
  font-weight: 700;
  line-height: 82.03px;
  ${animatedStyle}

  @media(max-width: 768px) {
    font-size: 40px;
    line-height: 50px;
  }
`;

const VisionExtra = styled.p`
  font-size: 40px;
  font-weight: 300;
  line-height: 46.88px;
  text-align: left;
  color: rgba(223, 239, 240, 1);
  ${animatedStyle}
`;

const MissionDiv = styled(FlexContainer)`
  color: white;
  gap: 5vh;
  width: 100%;
  align-items: flex-end;
  justify-content: center;
  flex-direction: row;
  ${animatedStyle}
  img {
    width: 50%;
    height: auto;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MissionText = styled.p`
  font-size: 30px;
  font-weight: 300;
  line-height: 39px;
  text-align: left;
  ${animatedStyle}
`;

const MissionSpan = styled.span`
  font-size: 30px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
`;

const ValuesDiv = styled(FlexContainer)`
  flex-direction: row;
  gap: 5vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  ${animatedStyle}
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  ${animatedStyle}
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
  }
`;

const ValueCardDiv = styled.div`
  width: 285px;
  height: 285px;
  filter: drop-shadow(3px 3px 6px rgba(49, 93, 121, 0.2));
  background: #dfeff0;
  border-radius: 28px;
  color: #1d374a;
  display: flex;
  padding: 10%;
  gap: 20px;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: center;
  ${animatedStyle}
  @media (max-width: 1024px) {
    width: auto;
    height: auto;
    align-items: center;
  }
`;

const CardLine = styled.hr`
  width: 15%;
  border: 1px solid #0a3c57;
  margin-right: 100%;
  @media (max-width: 1024px) {
    margin-right: 50%;
  }
`;

const CardTitle = styled.h6`
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  width: 50%;
  text-align: left;
  color: rgba(29, 55, 74, 1);
`;

const CardContent = styled.p`
  font-size: 15px;
  font-weight: 400;
  line-height: 17.58px;
  text-align: left;
  color: rgba(29, 55, 74, 1);
`;

const TeamDiv = styled(FlexContainer)`
  flex-direction: row;
  gap: 5vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #1d374a;
  padding-bottom: 20%;
  ${animatedStyle}
  h6 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35.16px;
    text-align: left;
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ValueCard = ({ title, content, styles, isVisible }) => {
  return (
    <ValueCardDiv style={styles} isVisible={isVisible}>
      <CardTitle>{title}</CardTitle>
      <CardLine />
      <CardContent>{content}</CardContent>
    </ValueCardDiv>
  );
};

function AboutTeam() {
  const [isVisible, setIsVisible] = useState({
    vision: false,
    mission: false,
    values: false,
    team: false
  });

  const visionRef = useRef(null);
  const missionRef = useRef(null);
  const valuesRef = useRef(null);
  const teamRef = useRef(null);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.2,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(prev => ({ ...prev, [entry.target.id]: true }));
        } else {
          setIsVisible(prev => ({ ...prev, [entry.target.id]: false }));
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    if (visionRef.current) observer.observe(visionRef.current);
    if (missionRef.current) observer.observe(missionRef.current);
    if (valuesRef.current) observer.observe(valuesRef.current);
    if (teamRef.current) observer.observe(teamRef.current);

    return () => {
      if (visionRef.current) observer.unobserve(visionRef.current);
      if (missionRef.current) observer.unobserve(missionRef.current);
      if (valuesRef.current) observer.unobserve(valuesRef.current);
      if (teamRef.current) observer.unobserve(teamRef.current);
    };
  }, []);

  return (
    <>
      <MainContainer>
        <NewHeader absolute={true} />
        <VisionDiv ref={visionRef} id="vision" isVisible={isVisible.vision}>
          {/* <VisionTitle isVisible={isVisible.vision}>Our Vision</VisionTitle> */}
          <VisionText isVisible={isVisible.vision}>We help companies bridge the reduction gap.</VisionText>
          <VisionExtra isVisible={isVisible.vision}>
            Carbonsmith instills trust, transparency, and standardization in carbon markets.
          </VisionExtra>
        </VisionDiv>
        <MissionDiv ref={missionRef} id="mission" isVisible={isVisible.mission}>
          <img src={mountainPng} alt="mountain path decorative" />
          <FlexContainer
            direction="column"
            gap="15px"
            align="flex-start"
            style={{ textAlign: "left" }}
          >
            <VisionTitle isVisible={isVisible.mission}>Our Mission</VisionTitle>
            <MissionText isVisible={isVisible.mission}>
              We provide a trusted end-to-end solution that accelerates the scope at which <MissionSpan>engineered carbon removal</MissionSpan> can scale.
            </MissionText>
            <MissionText isVisible={isVisible.mission}>
              We empower buyers and sellers through transparent infrastructure and robust
              Monitoring, Reporting, and Verification (MRV) standards, enabling
              broad <MissionSpan>decarbonization</MissionSpan>.
            </MissionText>
          </FlexContainer>
        </MissionDiv>
        <ValuesDiv ref={valuesRef} id="values" isVisible={isVisible.values}>
          <FlexContainer direction="column" gap="10px" width={"50vw"} style={{textAlign: 'left'}}>
            <h6
              style={{
                fontSize: "60px",
                lineHeight: "70.31px",
                fontWeight: "700",
                color: "rgba(29, 55, 74, 1)",
              }}
            >
              Our Core Values
            </h6>
            <p
              style={{
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "300",
                color: "rgba(29, 55, 74, 1)",
              }}
            >
              Our core values serve as the guiding principles that drive our
              success and inspire us to create meaningful impact in all we do.
            </p>
          </FlexContainer>
          <CardGrid isVisible={isVisible.values}>
            <ValueCard
              title="EVOLVING EXCELLENCE"
              content="We strive for continuous advancement in quality and openness to change."
              isVisible={isVisible.values}
            />
            <ValueCard
              title="TRUST & INTEGRITY"
              content="Your trusted partner for reaching transition goals and science-based targets."
              isVisible={isVisible.values}
            />
            <ValueCard
              title="ENDURING COMMITMENT"
              content="We are dedicated to creating positive and lasting impacts for you and the climate."
              isVisible={isVisible.values}
            />
            <ValueCard
              title="CUSTOMER CENTRICITY"
              content="Your success is our success. Our planet needs the help of everyone to ensure its preservation."
              isVisible={isVisible.values}
            />
          </CardGrid>
        </ValuesDiv>
        <TeamDiv ref={teamRef} id="team" isVisible={isVisible.team}>
          <FlexContainer
            direction="column"
            gap="10px"
            width={"60vw"}
            style={{ textAlign: "left" }}
          >
            <h6
              style={{
                fontSize: "60px",
                lineHeight: "70.31px",
                fontWeight: "700",
                color: "rgba(29, 55, 74, 1)",
              }}
            >
              MEET THE TEAM
            </h6>
            <p
              style={{
                fontSize: "20px",
                lineHeight: "24px",
                fontWeight: "300",
                color: "rgba(29, 55, 74, 1)",
              }}
            >
              Our team is passionate, skilled and forward-thinking. We share the
              mission of accelerating innovation and making a positive impact.
            </p>
          </FlexContainer>
          <FlexContainer
            direction="column"
            gap="20px"
            align="center"
            justify="space-between"
            style={{ textAlign: "left", width: "50%" }}
          >
            <img
              src={drewHeadshot}
              alt="Andrew Hahn headshot"
              style={{
                height: "250px",
              }}
            />
            <span
              style={{
                color: "#1EB1B5",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.09px",
                textAlign: "left",
              }}
            >
              Co-Founder & CEO
            </span>
            <h6>Drew Hahn</h6>
            <p>
              Drew has a background in Economics, Data Science, and algorithmic
              market-making. He aims to enhance carbon trust through data
              transparency. With five years of experience scaling three startups
              from pre-seed to Series C, he is committed to using his diverse
              skillset to establish the infrastructure for scaling innovation in
              carbon removal and clean energy.
            </p>
          </FlexContainer>
          <FlexContainer
            direction="column"
            gap="20px"
            align="center"
            justify="center"
            style={{ textAlign: "left", width: "50%" }}
          >
            <img src={cocoonHeadshot} alt="Cocoon Cao headshot" style={{height: '215px'}} />
            <span
              style={{
                color: "#1EB1B5",
                fontSize: "18px",
                fontWeight: "700",
                lineHeight: "21.09px",
                textAlign: "left",
              }}
            >
              Co-Founder & COO
            </span>
            <h6>Cocoon Cao</h6>
            <p>
              Cocoon has four years of experience in data management,
              consulting, and entrepreneurial work in the sustainability sector.
              She is dedicated to driving positive transformation in the ESG
              ecosystem by leveraging her AI and Data Science expertise.
            </p>
          </FlexContainer>
        </TeamDiv>
      </MainContainer>
      <NewFooter />
    </>
  );
}

export default AboutTeam;