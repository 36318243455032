import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import solidGauge from 'highcharts/modules/solid-gauge';
import more from 'highcharts/highcharts-more';
import styled from 'styled-components';

// Initialize the module with Highcharts

more(Highcharts);
solidGauge(Highcharts);
Highcharts.setOptions({
    credits: {
      enabled: false
    }
  });

const InsightsContainer = styled.div`
  background-color: white;
//   padding: 1%;
//   margin-top: 20px;
  border-radius: 8px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 92%;
  margin-bottom: 3%;
  margin-left: 2%;
  border: 1px solid #325d7980;
`;

const InsightsContainerStyling = styled.div`
  padding: 2%;
  background-color: white;
  border-radius: 20px;
`;

const InsightHeader = styled.h2`
  color: #325d79ff;
  margin-bottom: 3%;
`;

const InsightsContainerSpread = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    margin: 5%

    /* Make each child (chart) take equal space */
    & > div {
        flex: 1;
    }

`;

const TextContainer = styled.p`
  margin-bottom: 3%;
`;


//getting data ready for the second graph
function calculateDataPoints(contract) {
    const startDate = new Date(contract.startDate);
    const today = new Date();
    
    // Calculate days since start
    const daysSinceStart = (today - startDate) / (1000 * 60 * 60 * 24);
  
    // Calculate daily removal rate based on actual days passed, avoiding division by zero
    const dailyRemovalRate = daysSinceStart > 0 ? parseFloat((parseFloat(contract.carbonRemoved) / daysSinceStart).toFixed(3)) : 0;
    
    // Total tons to capture
    const totalToRemove = parseFloat(contract.tonsToCapture);
    
    // Remaining tons to remove
    const remainingTons = totalToRemove - parseFloat(contract.carbonRemoved);
  
    // Calculate estimated completion days for the remaining tons at the current rate
    const estimatedCompletionDays = dailyRemovalRate > 0 ? parseFloat((remainingTons / dailyRemovalRate).toFixed(3)) : 0;
  
    // Calculate the estimated end date by adding the estimated completion days to today's date
    const endDate = estimatedCompletionDays > 0 ? new Date(today.getTime() + estimatedCompletionDays * 24 * 60 * 60 * 1000) : new Date(today);
  
    const actualData = [];
    const projectedData = [];
  
    // Generate actual data points up to today
    for (let d = new Date(startDate); d < today; d.setDate(d.getDate() + 1)) {
      const elapsedDays = (d - startDate) / (1000 * 60 * 60 * 24);
      actualData.push([d.getTime(), parseFloat((dailyRemovalRate * elapsedDays).toFixed(3))]);
    }
  
    // Assuming some removals have already been made and we have a daily rate
    if (dailyRemovalRate > 0) {
      // Generate projected data points from today to the estimated end date
      for (let d = new Date(today); d <= endDate; d.setDate(d.getDate() + 1)) {
        const elapsedDays = (d - startDate) / (1000 * 60 * 60 * 24);
        projectedData.push([d.getTime(), parseFloat((dailyRemovalRate * elapsedDays).toFixed(3))]);
      }
    }
  
    console.log('End Date:', endDate);
  
    return { actualData, projectedData, endDate };
  }
  


function SolidGaugeChart({ contract }) {
    contract['startDate'] = '2024-01-01';

    const progressPercentage = parseFloat((parseFloat(contract.carbonRemoved) / parseFloat(contract.tonsToCapture) * 100).toFixed(2));
    const revenueUSD = parseFloat(contract.Revenue.replace(' USD', ''));
    const costPerTon = revenueUSD / parseFloat(contract.carbonRemoved);
    const daysSinceStart = (new Date() - new Date(contract.startDate)) / (1000 * 60 * 60 * 24); // Example start date needed
    const estimatedCompletionDays = (parseFloat(contract.tonsToCapture) / (parseFloat(contract.carbonRemoved) / daysSinceStart));
    const { actualData, projectedData, endDate } = calculateDataPoints(contract);
  
    const efficiencyOptions = {
      chart: { type: 'pie' },
      title: { text: 'Revenue and Cost Efficiency', style: { color: '#325d79ff' } },
      series: [{
        name: 'USD',
        colorByPoint: true,
        data: [
          { name: 'Revenue', y: revenueUSD },
          { name: 'Cost Per Ton', y: costPerTon }
        ]
      }]
    };

    // console.log('percent', progressPercentage);
    const chartOptions = {
        chart: {
          type: 'spline'
        },
        title: {
          text: 'Carbon Removal Progress and Projection',
          style: {
            color: '#325d79ff'
          }
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%e. %b'
          },
          title: {
            text: 'Date'
          }
        },
        yAxis: {
          title: {
            text: 'Tons of CO2 Removed'
          }
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          valueSuffix: ' tons',
          dateTimeLabelFormats: {
            day: '%e. %b %Y'
          }
        },
        series: [{
          name: 'Actual Removals',
          data: actualData,
          zIndex: 1,
          color: 'rgba(0, 120, 200, 0.75)',
          marker: {
            enabled: true,
            fillColor: 'white',
            lineWidth: 2,
            lineColor: null // inherit from series
          }
        }, {
          name: 'Projected Removals',
          data: projectedData.slice(1), // Skip the first point as it overlaps with the last actual point
          zIndex: 0,
          dashStyle: 'ShortDash',
          color: 'rgba(0, 120, 200, 0.5)',
          marker: {
            enabled: false
          }
        }]
      };
  
    return (
      <InsightsContainer>
        <InsightsContainerStyling>
        <InsightHeader>Contract Insights & Analytics</InsightHeader>
        <InsightsContainerSpread>
        <HighchartsReact highcharts={Highcharts} options={efficiencyOptions} />
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </InsightsContainerSpread>
        <TextContainer>Estimated days to completion: {estimatedCompletionDays.toFixed(0)} days</TextContainer>
        </InsightsContainerStyling>
      </InsightsContainer>
    );
}

export default SolidGaugeChart;
