import React from 'react';
import styled from 'styled-components';
import calltoaction from '../../../../images/Homepage_diagram_2.png'; // Adjust the path if necessary
import { NewHeaderLight } from './NewHeader';
import { FaCheckCircle } from 'react-icons/fa';
import { StandardButton } from './ExternalUILibrary/ExternalComponents';

const LandingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 15%;
  right: 13%;
  width: 33%; /* Adjusted to make the image slightly smaller */
  height: 75%; /* Slight adjustment to the image height */
  background-image: url(${calltoaction});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  z-index: 0;

  @media (max-width: 768px) {
    top: 5%;
    right: 5%;
    width: 55%;
  }
`;

const MainContent = styled.main`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  padding-left: 7%;
  padding-top: 5%;
  max-width: 60%; /* Limit text width */
  color: black;
  @media (max-width: 768px) {
    max-width: 80%;

  }
`;

const Title = styled.h1`
  font-size: 48px;
  margin: 0 0 20px;
  color: #0A3C57;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Highlight = styled.span`
  color: #1EB1B5; /* Adjust the color for "high-quality" and "durable" */
`;

const Subtitle = styled.p`
  font-size: 30px;
  margin: 10px 0;
  margin-bottom: 0;
  width: 80%; 
  color: #0A3C57;
  font-family: 'Roboto', sans-serif;
  font-weight: 300; /* Roboto Light */
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 20px;
    width: 48%;
    margin-top: 40px;
    margin-bottom: 0;
  }
`;

const BulletList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
  @media (max-width: 768px) {
    margin-top: 35%;
    padding-top: 0%;
  }

`;

const BulletItem = styled.li`
  font-size: 24px;
  margin: 10px 0;
  padding: 10px;
  display: flex;
  align-items: center;
  color: #0A3C57;
  font-family: 'Roboto', sans-serif;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: left;
  }
  
`;

const CTAButton = styled(StandardButton)`
  cursor: pointer;
  background-color: #1EB1B5;
  color: white;
  margin-top: 2%;
  margin-bottom: 4%;
  
  &:hover {
    transform: scale(1.01);
    background-color: #179fa3;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin: 0 auto;
  }
`;

const CheckIcon = styled(FaCheckCircle)`
  color: #1EB1B5;
  margin-right: 10px;
  // font-size: 24px;

  @media (max-width: 750px) {
    font-size: 1rem;
  }

`;

const ButtonHolder = styled.div`
  width: 60%;
  margin-top: 10px;
  align-content: center;
  text-align: center;
`;


const NewLandingTop = () => {
  return (
    <LandingContainer>
      <BackgroundImage />
      <NewHeaderLight absolute={false}/>
      <MainContent>
        <Title>
          Marketplace for <Highlight>high-quality,</Highlight> <Highlight>durable</Highlight> carbon credits
        </Title>
        <Subtitle>
          We simplify the world of engineered carbon removal
        </Subtitle>
        <BulletList>
          <BulletItem><CheckIcon />&nbsp;&nbsp;Built-in transparency</BulletItem>
          <BulletItem><CheckIcon />&nbsp;&nbsp;Standardized off-takes</BulletItem>
          <BulletItem><CheckIcon />&nbsp;&nbsp;Data-driven credits</BulletItem>
        </BulletList>
        <ButtonHolder>
          <CTAButton><a href="#contact-form" style={{ textDecoration: 'none', color: 'white'}}>
            Get Started
          </a></CTAButton>
        </ButtonHolder>
      </MainContent>
    </LandingContainer>
  );
};

export default NewLandingTop;
