import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LandingBodyTabsBuyer from './LandingBodyTabsBuyer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ProgressBarChart from '../../../UniversalFunctions/ProgressBarChart';
import { generateIntervalDates, prepareChartData } from '../../../UniversalFunctions/ProgressBarChart';
import AvailableCreditsFooter from './UniversalFcnsBuyerFE/AvailableCreditsScroll';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import moment from 'moment-timezone';
import { FaCalendar } from 'react-icons/fa';
import { modifiedAt } from '../../../UniversalFunctions/timestamps';
import { PageContainer, BodyDiv, MainContainer } from '../../../UI/Library/PageStructure';
import { Card, CardContent, CardHeader, CardTitle, ContractDetails, ContractStatus, ContractId, ContractItem, HeaderContainer, HeaderTitle, HeaderDescription } from '../../../UI/Library/SellerComponents';

const TargetsHeaderStatus = styled.div`
  color: #325d79ff;
  font-weight: bold;
  margin-top: 40%;
`;

const AddGoalsDiv = styled.div`
    margin: 5% auto;

    &:hover {
        transform: scale(1.02);
        transition: all 0.3s ease-in-out;        
    }
`;

const AddGoalsButton = styled.div`
  background-color: #325d79ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 4%;
  width: 100%;
  font-weight: bold;

  &:hover {
    background-color: #5f7d95ff;
    transform: scale(1.02);
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
`;

const FormTitle = styled.h2`
  text-align: center;
  color: #325d79;
  margin-bottom: 24px;
  font-size: 1.5em;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FormLabel = styled.label`
  font-weight: 600;
  color: #325d79;
  margin-bottom: 8px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #325d79;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  background-color: white;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #325d79;
  }
`;

const DateInputGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const DateInput = styled.div`
  flex: 1;
  position: relative;
`;

const StyledDateInput = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  transition: border-color 0.3s;

  &:focus {
    outline: none;
    border-color: #325d79;
  }
`;

const DateLabel = styled.span`
  position: absolute;
  top: -20px;
  left: 0;
  font-size: 0.85em;
  color: #666;
`;

const DateSeparator = styled.span`
  color: #325d79;
  font-weight: 600;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #325d79;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #1a4971;
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const TargetDesc = styled.p`
    text-align: left;
    font-size: .9em;
    font-weight: bold;
    margin: 2% 0;
`;

const ProgressContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  margin: 2% 0;
`;

const ProgressBar = styled.div`
  height: 10px;
  width: ${({ progress }) => progress}%;
  background-color: #4CAF50;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
`;

const HeaderDiv = styled.div`
    padding-bottom: 1%;
`;

const HeaderDivContainer = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    margin-left: 3%;
    margin-right: 3%;
    border: 1px solid #325d7980;
`;

const RemovalGoalsHeader = styled.div`
  text-align: left;
  padding: 2% 0;
  color: #325d79ff;
  border-radius: 8px;
  font-size: 2em;
  font-weight: bold;
  margin-left: 5%;
`;

const GoalCountSec = styled.p`
    text-align: right;
    margin-left: 40%;
    font-weight: bold;
    margin-top: 5%;
    font-size: 1em;
    color: #325d79ff;
    margin-bottom: 1%;
`;

const GoalsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: top;
    justify-content: flex-start;
    width: 100%;
    padding: 2%;
`;

const RechartDivBottom = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% 10%;
    background-color: white;
    border-radius: 20px;
    padding: 2%;
    border: 1px solid #325d7980;
`;

const WelcomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10%;
  background: linear-gradient(to right, #00c9ff, #92fe9d);
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  color: white;
  text-align: center;
  margin: 2% 1%;
  transition: all 0.3s ease-in-out;
  height: 80%;
  margin-bottom: 10%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
  }
`;

const WelcomeTitle = styled.h1`
  font-size: 2.5em;
  margin-bottom: 2.5%;
`;

const WelcomeMessage = styled.p`
  font-size: 1.2em;
  margin-bottom: 3%;
  max-width: 600px;
`;

const GetStartedButton = styled.button`
  background-color: white;
  color: #00796b;
  font-size: 1em;
  padding: 2% 4%;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const getCurrentDate = () => {
  return moment().tz(moment.tz.guess()).format('YYYY-MM-DD');
};

function Modal({ isOpen, onClose, children }) {
  if (!isOpen) return null;

  return (
    <ModalBackdrop onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        {children}
      </ModalContent>
    </ModalBackdrop>
  );
}

function GoalItemComponent({ goal, navigate }) {
  return (
    <Card style={{ margin: '1% 1%', marginLeft: '3%', width: '30%' }} onClick={() => navigate(`/Removal-Goals/${goal.goalName}`)}>
      <CardHeader>
        <CardTitle>{goal.goalName}</CardTitle>
      </CardHeader>
      <CardContent>
        <ContractItem>
          <ContractDetails>
            {goal.goalType && typeof goal.goalType === 'object' && Object.entries(goal.goalType).map(([key, value]) => (
                key === 'CO2 Removed' ? (
                <ContractId>{key}: {value} Tons</ContractId>
                ) : (
                <ContractId>{key}: {value}</ContractId>
                )
            ))}
            {goal.progress >= 0 && goal.progress !== null && (
              <div style={{ marginTop: '10%'}}>
                <TargetDesc>{(goal.progress).toFixed(2)}% Complete</TargetDesc>
                <ProgressContainer>
                  <ProgressBar progress={goal.progress} />
                </ProgressContainer>
              </div>
            )}
          </ContractDetails>
          <ContractStatus style={{ backgroundColor: goal.Status === 'In Progress' ? '#b2e0b2' : '#f0f0f0', color: goal.Status === 'In Progress' ? 'white' : 'black' }}>
            {goal.Status}
          </ContractStatus>
        </ContractItem>
      </CardContent>
    </Card>
  );
}

function RemovalGoals() {
  // ... [Rest of the component logic remains the same]

    const [noGoals, setNoGoals] = useState(true);
    const navigate = useNavigate();
    const [goals, setGoals] = useState({});
    const [isModalOpen, setModalOpen] = useState(false);
    const [goalName, setGoalName] = useState('');
    const [goalType, setGoalType] = useState('CO2 Removed');
    const [goalValue, setGoalValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [closestGoal, setClosestGoal] = useState({});
    const [finalChartData, setFinalChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const username = localStorage.getItem('user');
    const firebaseIdToken = localStorage.getItem('firebase_id_token');
    const authHeader = {
        Authorization: `Bearer ${firebaseIdToken}`
    };

    function handleGoalNameChange(event) {
        setGoalName(event.target.value);
    }
    
    // function handleTargetLengthChange(event) {
    //     setTargetLength(event.target.value);
    // }
    
    function handleGoalTypeChange(event) {
        setGoalType(event.target.value);
    }
    
    function handleGoalValueChange(event) {
        setGoalValue(event.target.value);
    }
    
    function handleStartDateChange(event) {
        setStartDate(event.target.value);
    }
    
    function handleEndDateChange(event) {
        setEndDate(event.target.value);
    }
  
    useEffect(() => {
      fetchGoals();
    }, []);
  
    useEffect(() => {
      if (closestGoal && Object.keys(closestGoal).length > 0) {
        const intervalDates = generateIntervalDates(closestGoal.endDate);
        setFinalChartData(prepareChartData(intervalDates, closestGoal.goalType.value, closestGoal.goalType.name));
      }
    }, [closestGoal]);
  
    const fetchGoals = async () => {
  
      try {
        const res = await axios.get(
            'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/buyer_goals',
            {
                params: {
                    username: username
                },
                headers: authHeader
            });
        const fetchedGoals = res.data.data;
        if (fetchedGoals && Object.keys(fetchedGoals).length > 0) {
          setNoGoals(false);
          setGoals(fetchedGoals);
          updateGoalsProgress(fetchedGoals);
        }
      } catch (error) {
        console.log('Error fetching goals: ', error);
      }
      setIsLoading(false);
    };
  
    const updateGoalsProgress = async (fetchedGoals) => {
    
      try {
        const res = await axios.get(
            'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/user_all_contracts',
            {
                params: {
                    username: username
                },
                headers: authHeader
            });

        const contracts = res.data.data;
        //console.log("buyerRemovalGoals user_all_contracts", res.data.data)
        const updatedGoals = {};
    
        Object.entries(fetchedGoals).forEach(([key, goal]) => {
          const goalType = Object.keys(goal.goalType)[0];
          const goalValue = goal.goalType[goalType];
          let totalValue = 0;
  
          switch (goalType) {
            case 'CO2 Removed':
              totalValue = Object.values(contracts).reduce((sum, contract) => sum + (contract.carbonRemoved || 0), 0);
              break;
            case 'Contracts Completed':
              totalValue = Object.values(contracts).filter(contract => contract.Status === 'Completed').length;
              break;
            case 'Partnerships Created':
              totalValue = new Set(Object.values(contracts).map(contract => contract.company)).size;
              break;
            // Add other cases as needed
          }
  
          const progress = Math.min(100, (totalValue / goalValue) * 100);
          updatedGoals[key] = { ...goal, progress, Status: progress >= 100 ? 'Completed' : 'In Progress' };
        });
    
        setGoals(updatedGoals);
        findAndSetClosestGoal(updatedGoals);
      } catch (error) {
        console.log('Error updating goals progress: ', error);
      }
    };
  
    const findAndSetClosestGoal = (updatedGoals) => {
      const allGoals = Object.values(updatedGoals);
      const currentDate = new Date();
      let closestGoal = null;
      let smallestDiff = Infinity;
  
      allGoals.forEach(goal => {
        const goalEndDate = new Date(goal.endDate);
        const timeDiff = goalEndDate - currentDate;
        if (timeDiff >= 0 && timeDiff < smallestDiff) {
          smallestDiff = timeDiff;
          closestGoal = goal;
        }
      });
  
      setClosestGoal(closestGoal);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();

      const formData = {
        goalName,
        goalType,
        goalValue,
        startDate: moment.tz(startDate, moment.tz.guess()).utc().format(),
        endDate: moment.tz(endDate, moment.tz.guess()).utc().format(),
        addedAt: new Date().toISOString(),
        modifiedAt: new Date().toISOString()
      };
  
      try {
          //console.log(formData);
          //console.log(username);
          //console.log(authHeader);
          //console.log(typeof formData);

        const res = await axios.post(
            'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/buyer_goal',
            formData,
            {
                params: {
                    username: username
                },
                headers: authHeader
            });

        if (res.status === 200) {
          alert('Goal successfully added!');
          setModalOpen(false);
          fetchGoals();
        }
      } catch (error) {
        console.log('Error adding goal: ', error);
        alert('An error occurred while adding the goal. Please try again.');
      }
    };

  return (
    <PageContainer>
      <MainContainer style={{display: 'block'}}>
        <LandingBodyTabsBuyer />
        <BodyDiv>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {noGoals ? (
                <WelcomeContainer>
                  <WelcomeTitle>No goals have been set</WelcomeTitle>
                  <WelcomeMessage>Begin adding goals and catalyze your removal journey!</WelcomeMessage>
                  <GetStartedButton onClick={() => setModalOpen(true)}>
                    Get Started
                  </GetStartedButton>
                </WelcomeContainer>
              ) : (
                <>
                  <HeaderContainer>
                    <HeaderTitle>Removal Goals</HeaderTitle>
                    <HeaderDescription>
                      {Object.values(goals).some(goal => goal.Status === 'In Progress') && 
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                          <span style={{ height: '12px', width: '12px', backgroundColor: 'green', borderRadius: '50%', display: 'inline-block', marginRight: '7px', marginTop: '45%' }}></span>
                          <TargetsHeaderStatus>Active</TargetsHeaderStatus>
                        </div>
                      }
                    </HeaderDescription>
                  </HeaderContainer>
                  <GoalsContainer>
                    {Object.values(goals).map((goal, index) => (
                      <GoalItemComponent key={index} goal={goal} navigate={navigate} />
                    ))}
                  </GoalsContainer>
                </>
              )}

              {noGoals && <AvailableCreditsFooter />}

              {!noGoals && (
                <>
                  <AddGoalsDiv>
                    <AddGoalsButton onClick={() => setModalOpen(true)}>Add a Goal</AddGoalsButton>
                  </AddGoalsDiv>
                  {/* <RechartDivBottom>
                    {closestGoal && (
                      <>
                        <h2 style={{ color: '#325d79ff' }}>{closestGoal.goalName} Plan</h2>
                        <ProgressBarChart data={finalChartData} goalType={closestGoal.goalType} />
                      </>
                    )}
                  </RechartDivBottom> */}
                </>
              )}

              <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
                <ModalContent>
                  <FormTitle>Set a New Goal</FormTitle>
                  <StyledForm onSubmit={handleSubmit}>
                  <FormSection>
                      <FormLabel>Target Details</FormLabel>
                      <StyledInput å
                        type="text" 
                        placeholder="Goal Name" 
                        value={goalName} 
                        onChange={handleGoalNameChange} 
                      />
                      <StyledSelect value={goalType} onChange={handleGoalTypeChange}>
                        <option value="CO2 Removed">CO₂ Removed</option>
                        <option value="RECS Sold">Renewable Energy Credits Sold</option>
                        <option value="Total Emissions Reduced">Total Emissions Reduced</option>
                        <option value="Total Emissions Reduced (%)">% Emission Reduction</option>
                        <option value="Contracts Completed">Contracts Completed</option>
                        <option value="Partnerships Created">New Partnerships</option>
                      </StyledSelect>
                      <StyledInput 
                        type="number" 
                        placeholder="Goal Value" 
                        value={goalValue} 
                        onChange={handleGoalValueChange} 
                      />
                    </FormSection>
                    
                    <FormSection>
                      <FormLabel>Target Duration</FormLabel>
                      <DateInputGroup>
                        <DateInput>
                          <StyledDateInput 
                            type="date" 
                            value={startDate} 
                            onChange={handleStartDateChange}
                            min={getCurrentDate()}
                          />
                          <DateLabel>Start Date</DateLabel>
                        </DateInput>
                        <DateSeparator>to</DateSeparator>
                        <DateInput>
                          <StyledDateInput 
                            type="date" 
                            value={endDate} 
                            onChange={handleEndDateChange}
                            min={startDate || getCurrentDate()}
                          />
                          <DateLabel>End Date</DateLabel>
                        </DateInput>
                      </DateInputGroup>
                    </FormSection>

                    <SubmitButton type="submit">Create Target</SubmitButton>
                    {/* ... [Form fields remain the same] */}
                  </StyledForm>
                </ModalContent>
              </Modal>
            </>
          )}
        </BodyDiv>
      </MainContainer>
    </PageContainer>
  );
}

export default RemovalGoals;

