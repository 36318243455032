import React from 'react';
import { Treemap, Tooltip, ResponsiveContainer } from 'recharts';
import styled from 'styled-components';

const TooltipContainer = styled.div`
    background-color: white;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const ToolTipHeader = styled.p`
    margin: 0 0 2% 0;
    color: #333;
    font-size: 1.1em;
    font-weight: bold;
    text-align: left;
    color: #00796b;
`;

const TooltipText = styled.p`
    margin: 0;
    color: #333;
    font-size: .8em;
    text-align: left;
    margin: 1.5% 0;
`;

const transformDataForTreemap = (contracts) => {
    return contracts.map(contract => {
      // Check if revenue exists and is not undefined
      const revenueString = contract.Revenue;
      let revenueNumber = 0;
      if (revenueString && typeof revenueString === 'string') {
        revenueNumber = parseFloat(revenueString.split(' ')[0]);
      }
  
      return {
        name: contract.contractName,
        revenue: revenueNumber, // Use parsed number or 0 if revenue is undefined
        //fill: "#8884d8" // Example fill color, modify as needed
      };
    });
  };
  

const DEFAULT_COLORS = [
  "#8889DD",
  "#9597E4",
  "#8DC77B",
  "#A5D297",
  "#E2CF45",
  "#F8C12D"
];

const CustomTooltipRevenue = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
      return (
          <TooltipContainer>
              <ToolTipHeader>{label}</ToolTipHeader>
              <TooltipText>Revenue: {payload[0].value.toFixed(2)} USD</TooltipText>
              {/* <TooltipText>Tons to Capture: {payload[1].value.toFixed(2)}</TooltipText> */}
          </TooltipContainer>
      );
  }

  return null;
};


  const CustomContentTreemap = ({ contracts }) => {
    const data = transformDataForTreemap(contracts);
    console.log('treemap data: ', data);
  
    return (
      <ResponsiveContainer width="100%" height={400}>
        <Treemap
          data={data}
          dataKey="revenue"
          ratio={4 / 3}
          stroke="#fff"
          fill="#8884d8"
          content={<CustomizedContent />} 
        >
          <Tooltip content={<CustomTooltipRevenue /> } />
        </Treemap>
      </ResponsiveContainer>
    );
  };
  
  // Customized content for each cell in the treemap
  const CustomizedContent = (props) => {
  //   console.log('customized contnet called');
  //   return <rect {...props} />
  // }
  
    const { depth, x, y, width, height, index, name } = props;

    // console.log('payload: ', payload);
    // if (!payload) {
    //   return null;
    // }

    // const fillColor = payload?.fill || '#8884d8';
  
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: 
              depth < 2 ? DEFAULT_COLORS[index % DEFAULT_COLORS.length]
              : 'none',
            stroke: '#fff',
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1/ (depth + 1e-10),
          }}
        />

        {depth === 1 ? (
          <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor='middle'
          fill='#fff'
          fontSize={14}
          >{name}</text>
        ): null}
        {depth === 1 ? (
          <text
            x={x+120}
            y={y+18}
            fill="#fff"
            fontSize={12}
            fillOpacity={0.9}
          >
            {/* {labels[index]} */}
          </text>
        ): null}
      {/* //   <text
      //     x={x + width / 2}
      //     y={y + height / 2}
      //     textAnchor="middle"
      //     fill="#fff"
      //     fontSize={14}
      //   >
      //     {payload.name}
      //   </text>
      // </g> */}
      </g>
    );
  };

  export default CustomContentTreemap;