// import React, { useEffect, useState } from 'react';
// import { csv } from 'd3-fetch';
// import { scaleLinear, scaleLog } from 'd3-scale';
// import { interpolateHcl } from 'd3';
// import { ComposableMap, Geographies, Geography, Sphere, Graticule } from 'react-simple-maps';
// import emissionsData from './territorial-emissions-co2-included.csv';
// import topoJsonData from './map_features.json';
// import { feature } from 'topojson-client';
// import styled from 'styled-components';
// import { MdPlayArrow, MdPause } from 'react-icons/md';
// import { StandardButton, Title, Titleh3 } from '../components/Pages/PageComponents/ExternalSite/ExternalUILibrary/ExternalComponents';


// const ContainerFull = styled.div`
//     flex: 1;
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     // justify-content: center;
// `;

// const MapDivFull = styled.div`
//     height: 75%;
//     width: 90%;
//     // margin-left: 5%;
//     // margin-right: 5%;
//     @media (max-width: 750px) {
//         margin: auto;
//         margin-left: 8%;
//     }
//     @media (min-width: 1000px) {
//         margin-left: 10%;
//     }
// `;

// const YearSlider = styled.input.attrs({ type: 'range' })`
//   display: block;
//   width: 70%;
//   margin: 20px auto;
//   margin-right: 10px;
//   cursor: pointer;
//   -webkit-appearance: none;
//   appearance: none;

//   &::-webkit-slider-runnable-track {
//     width: 100%;
//     height: 8px;
//     background: #d9d9d9;
//     border-radius: 5px;
//   }

//   &::-webkit-slider-thumb {
//     -webkit-appearance: none;
//     appearance: none;
//     width: 20px;
//     height: 20px;
//     background: #1EB1B5;
//     border-radius: 50%;
//     cursor: pointer;
//     margin-top: -6px; /* Adjust if needed to center the thumb */
//   }

//   &::-moz-range-track {
//     width: 100%;
//     height: 8px;
//     background: #ddd;
//     border-radius: 5px;
//   }

//   &::-moz-range-thumb {
//     width: 20px;
//     height: 20px;
//     background: #1EB1B5;
//     border-radius: 50%;
//     cursor: pointer;
//   }

//   &::-ms-track {
//     width: 100%;
//     height: 8px;
//     background: transparent;
//     border-color: transparent;
//     color: transparent;
//   }

//   &::-ms-fill-lower {
//     background: #ddd;
//     border-radius: 5px;
//   }

//   &::-ms-fill-upper {
//     background: #ddd;
//     border-radius: 5px;
//   }

//   &::-ms-thumb {
//     width: 20px;
//     height: 20px;
//     background: #1EB1B5;
//     border-radius: 50%;
//     cursor: pointer;
//   }

//   &:focus {
//     outline: none;
//   }

//   @media (max-width: 600px) {
//     width: 80%;
//   }
// `;

// const SliderButtonDiv = styled.div`
//  display: flex;
//  flex-direction: row;
// `;

// const ToolTipDiv = styled.div`
//     position: absolute;
//     background-color: white;
//     border: 1px solid #ddd;
//     padding: 10px;
//     border-radius: 5px;
//     pointer-vents: none;
//     opacity: 0.8;
//     display: ${({ show }) => (show ? 'block' : 'none')};
//     left: ${({ x }) => x}px;
//     top: ${({ y }) => y}px;
//     max-width: 30%;
// `;

// const minEmissions = 1;
// const maxEmissions = 6000;

// const colorScale = scaleLog()
//     .domain([minEmissions, maxEmissions])
//     .range(["#F0FFFF","#00008B"])
//     .interpolate(interpolateHcl);

//     //#0A3C57

// const MapChart = () => {
//     const [data, setData] = useState([]);
//     const [geoJson, setGeoJson] = useState({});
//     const [tooltipContent, setTooltipContent] = useState("");
//     const [showTooltip, setShowTooltip] = useState(false);
//     const [tooltipPosition, setTooltipPosition] = useState({ x:0, y:0 });
//     const [isPlaying, setIsPlaying] = useState(true);
//     const [currentYear, setCurrentYear] = useState(1980);

//     useEffect(() => {
//         csv(emissionsData).then((data) => {
//             // console.log(data);
//             setData(data);
//         });
        
//         //convert TopoJson to GeoJSON
//         const convertedGeoJson = feature(topoJsonData, topoJsonData.objects.world);
//         setGeoJson(convertedGeoJson);

        
//     }, []);

//     useEffect(() => {
//         let interval;
//         if (isPlaying) {
//             interval = setInterval(() => {
//                 setCurrentYear((prevYear) => (prevYear < 2017 ? prevYear + 1 : 1960));
//             }, 100); //update every .75 sec
//         }
//         return () => clearInterval(interval);
//     }, [isPlaying]);

//     return (
//         <ContainerFull>

//         <MapDivFull style={{ position: 'relative'}}>
//         <ComposableMap
//         // projection="geoMercator"
//         // width={850}
//         // height={400}
//         width={850}
//         height={400}
//         projectionConfig={{
//             rotate: [-10, 0, 0],
//             scale: 150
//         }}
//         >
//             <Sphere stroke="#1EB1B5" strokeWidth={1} />
//             <Graticule stroke="#E4E5E6" strokeWidth={.5} />
//             {data.length > 0 && (
//                 <Geographies geography={geoJson}>
//                     {({ geographies }) => 
//                     geographies.map((geo) => {
//                         // console.log('geo ', geo);
//                         const d = data.find((s) => {
//                             return s.Code === geo.id && s.Year === currentYear.toString();
//                         });
                        
                        

//                         return (
//                             <Geography
//                             key={geo.rsmKey}
//                             geography={geo}
//                             fill={d ? (d.emissions_CO2 > 0  ? colorScale(+d.emissions_CO2) : "#EEE") : "#F5F4F6"}
//                             onMouseEnter={(event) => {
//                                 const { name } = geo.properties;
//                                 const emissionsData = d ? d.emissions_CO2 : "No data";
//                                 setTooltipContent(`${name} - ${currentYear} Emissions: ${emissionsData} Mt CO2`);
    
//                                 //position tooltip
//                                 const offsetX = 50;
//                                 const offsetY = 220;
//                                 setShowTooltip(true);
//                                 setTooltipPosition({ x: event.clientX - offsetX, y: event.clientY - offsetY });
                                
//                             }}
//                             onMouseLeave={() => {
//                                 setTooltipContent("");
//                             }}
//                             />
//                         );
//                     })
//                     }
//                 </Geographies>
//             )}
//         </ComposableMap>
//         <SliderButtonDiv>
//         <YearSlider
//             type="range"
//             min="1960"
//             max="2017"
//             value={currentYear}
//             onChange={(e) =>{
//                 setCurrentYear(Number(e.target.value));
//                 setIsPlaying(false); //pause when manually changing the year
//             }}
//         />
//         {/* <StandardButton onClick={() => setIsPlaying(!isPlaying)} style={{ margin: '10px auto' }}>
//             {isPlaying ? 'Pause' : 'Play'}
//         </StandardButton> */}
//         {!isPlaying && (
//             <MdPlayArrow onClick={() => setIsPlaying(!isPlaying)} style={{ fontSize: '30px', margin: '20px auto', marginLeft: '0px', color: 'white', backgroundColor: '#315D79', padding: '4px 4px', borderRadius: '5px'}} />
//         )}
//         {isPlaying && (
//             <MdPause onClick={() => setIsPlaying(!isPlaying)} style={{ fontSize: '30px', margin: '20px auto', marginLeft: '0px', color: 'white', backgroundColor: '#315D79', padding: '4px 4px', borderRadius: '5px'}} /> 
//         )}

//         </SliderButtonDiv>
//         <Titleh3>Global CO<sub>2</sub> Emissions: {currentYear}</Titleh3>

//         {/* <ToolTipDiv show={showTooltip} x={tooltipPosition.x} y={tooltipPosition.y}>
//             {tooltipContent}
//         </ToolTipDiv> */}
//         </MapDivFull>
//         </ContainerFull>
//     );

// };

// export default MapChart;

import React, { useEffect, useState } from 'react';
import { csv } from 'd3-fetch';
import { scaleLog } from 'd3-scale';
import { interpolateHcl } from 'd3';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import styled from 'styled-components';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import emissionsData from './territorial-emissions-co2-included.csv';
import topoJsonData from './map_features.json';
import { feature } from 'topojson-client';

const ContainerFull = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 450px; // Reduced from 500px
  padding: 10px;
  box-sizing: border-box;
  background-color: white;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const MapDivFull = styled.div`
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
//   margin-bottom: 5px;
`;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const YearSlider = styled.input.attrs({ type: 'range' })`
  flex-grow: 1;
  margin-right: 8px;
  -webkit-appearance: none;
  background: ${props => `linear-gradient(to right, #1EB1B5 0%, #1EB1B5 ${props.percentage}%, #d9d9d9 ${props.percentage}%, #d9d9d9 100%)`};
  outline: none;
  height: 4px;
  border-radius: 3px;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    background: #1EB1B5;
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 14px;
    height: 14px;
    background: #1EB1B5;
    cursor: pointer;
    border-radius: 50%;
    border: none;
  }
`;

const PlayPauseButton = styled.button`
  background: #1EB1B5;
  border: none;
  color: white;
  padding: 4px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 24px;
  height: 24px;

  &:hover {
    background: #179fa3;
  }
`;

const YearDisplay = styled.div`
  font-size: 14px;
  font-style: italic;
  color: #1EB1B5;
  margin-top: 6px;
  text-align: center;
  width: 100%;
`;

const colorScale = scaleLog()
  .domain([1, 6000])
  .range(["#F0FFFF", "#00008B"])
  .interpolate(interpolateHcl);

const MapChart = () => {
  const [data, setData] = useState([]);
  const [geoJson, setGeoJson] = useState({});
  const [currentYear, setCurrentYear] = useState(1980);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    csv(emissionsData).then(setData);
    const convertedGeoJson = feature(topoJsonData, topoJsonData.objects.world);
    setGeoJson(convertedGeoJson);
  }, []);

  useEffect(() => {
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentYear((prevYear) => (prevYear < 2017 ? prevYear + 1 : 1960));
      }, 100);
    }
    return () => clearInterval(interval);
  }, [isPlaying]);

  const sliderPercentage = ((currentYear - 1960) / (2017 - 1960)) * 100;

  return (
    <ContainerFull>
      <MapDivFull>
        <ComposableMap
          projectionConfig={{
            rotate: [-10, 0, 0],
            scale: 175,
          }}
        >
          {data.length > 0 && (
            <Geographies geography={geoJson}>
              {({ geographies }) =>
                geographies.map((geo) => {
                  const d = data.find((s) => s.Code === geo.id && s.Year === currentYear.toString());
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={d ? (d.emissions_CO2 > 0 ? colorScale(+d.emissions_CO2) : "#EEE") : "#F5F4F6"}
                      stroke="#FFF"
                      strokeWidth={0.5}
                    />
                  );
                })
              }
            </Geographies>
          )}
        </ComposableMap>
      </MapDivFull>
      <ControlsContainer>
        <SliderContainer>
          <YearSlider
            min="1960"
            max="2017"
            value={currentYear}
            percentage={sliderPercentage}
            onChange={(e) => {
              setCurrentYear(Number(e.target.value));
              setIsPlaying(false);
            }}
          />
          <PlayPauseButton onClick={() => setIsPlaying(!isPlaying)}>
            {isPlaying ? <MdPause /> : <MdPlayArrow />}
          </PlayPauseButton>
        </SliderContainer>
        <YearDisplay>Global CO₂ Emissions: {currentYear}</YearDisplay>
      </ControlsContainer>
    </ContainerFull>
  );
};

export default MapChart;