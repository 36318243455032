import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import LandingBodyTabs from "./LandingBodyTabs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import mammoth from "mammoth";
import JSZip from "jszip";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { FaHourglassHalf, FaTools, FaCheckCircle } from 'react-icons/fa';
import ProjectLocationMap from "./GraphSourcing/GoogleMapsApi";
import { formatDateFromISO } from "../../../UniversalFunctions/timestamps";
import Spinner from "../../../UniversalFunctions/LoadingGraphic";
import { FaFileContract } from 'react-icons/fa';

import {
    PageContainer,
    BodyDiv,
    MainContainer,
} from "../../../UI/Library/PageStructure";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    ContractDetails,
    ContractId,
    ContractItem,
} from "../../../UI/Library/SellerComponents";

const UpperDivMain = styled.div``;

const LowerDivMain = styled.div``;

const BodyUpperDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 60vh;
  max-height: 80vh;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
  }
`;

const LeftSideBody = styled.div`
  width: 40%;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const RightSideBody = styled.div`
  width: 55%;
  display: flex;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledCardContent = styled(CardContent)`
  flex-grow: 1;
`;

const VisualContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 2%;
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.justify || 'flex-start'};
  height: 100%;
`;

const GraphSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 2%;
  border-right: 1px solid #e0e0e0;
`;

const DetailItem = styled.div`
  margin-bottom: 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
`;

const Label = styled.strong`
  display: block;
  margin-right: 1%;
  color: #325d79ff;
  &::first-letter {
    text-transform: uppercase;
  }
`;

const DocxPreviewContainer = styled.div`
  cursor: pointer;
  background-color: white;
  max-height: ${(props) => (props.showFullDocument ? "none" : "300px")};
  overflow: hidden;
  position: relative;
  text-align: left;
  padding: 2%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;

  &:hover::after {
    content: "Click to see more";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    padding: 8px 16px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    font-size: 0.9em;
    color: #333;
    white-space: nowrap;
    z-index: 1;
  }

  &:hover::before {
    content: "";
    background: white;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
  }

  &::before {
    content: ${(props) => (props.showFullDocument ? "" : '"..."')};
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 16px;
    background: linear-gradient(to bottom, transparent, white 50%);
  }
`;

const DocumentationHeader = styled.h2`
  color: #325d79ff;
  font-size: 1.6rem;
  margin: 4% auto;
  margin-bottom: 2%;
`;

const CreditUsageGraph = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const CreditUsageText = styled.p`
  font-size: 1.2rem;
  font-weight: bold;
  color: #325d79;
  margin-top: 20px;
`;

const CustomTooltip = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const TooltipLabel = styled.p`
  margin: 0;
  font-weight: bold;
  color: #325d79;
`;

const TooltipValue = styled.p`
  margin: 5px 0 0;
  color: #5f7d95;
`;

const BuildStatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 1.2rem;
  color: #325d79ff;
`;

const BuildStatusIcon = styled.div`
  margin-right: 10px;
  font-size: 1.5rem;
`;


const CreditDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4%;
`;

const AssociatedContractDiv = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const ContractTitle = styled.h3`
  color: #325d79;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const ContractDetail = styled.p`
  color: #5f7d95;
  font-size: 1rem;
  margin: 5px 0;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 2rem;
`;

const RelatedContractsSection = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const RelatedContractsTitle = styled.h2`
  color: #325d79;
  font-size: 1.2rem;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 2px solid #1A99D4;
  display: flex;
  align-items: center;
  
  svg {
    margin-right: 10px;
    color: #1A99D4;
  }
`;

const ContractGrid = styled.div`
  display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 5px;

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const ContractCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #e9ecef;
  }
`;

const ContractName = styled.h3`
  color: #1A99D4;
  font-size: 0.9rem;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContractTime = styled.p`
  color: #6c757d;
  font-size: 0.75rem;
  margin: 0;
`;

const NoContractsMessage = styled.p`
  color: #6c757d;
  font-style: italic;
`;

const StartContractLink = styled.span`
  color: #1A99D4;
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    color: #0056b3;
  }
`;

const DisplayPdfPreview = ({
                               fileData,
                               showFullDocument,
                               toggleDocumentView,
                           }) => {
    return (
        <div
            onClick={toggleDocumentView}
            style={{
                cursor: "pointer",
                background: "white",
                height: showFullDocument ? "100%" : "300px",
                overflow: "hidden",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                position: "relative",
            }}>
            <a href={fileData} download>download</a>
            {showFullDocument ? (
                <iframe
                    title="pdf-preview"
                    src={fileData}
                    style={{ width: "100%", height: "600px", border: "none" }}
                />
            ) : (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <p>Click to view document</p>
                </div>
            )}
        </div>
    );
};

const DisplayDocxPreview = ({ fileData, showFullDocument, toggleDocumentView }) => {
    const [docContent, setDocContent] = useState("");

    useEffect(() => {
        const fetchDocContent = async () => {
            const response = await fetch(fileData);
            const buffer = await response.arrayBuffer();
            console.log(buffer)
            mammoth
                .convertToHtml({ arrayBuffer: buffer })
                .then((result) => {
                    setDocContent(result.value);
                })
                .catch((err) => console.log("Error converting DOCX:", err));
        };

        fetchDocContent();
    }, [fileData]);

    return (
        <DocxPreviewContainer
            showFullDocument={showFullDocument}
            onClick={toggleDocumentView}
        >
            <div
                dangerouslySetInnerHTML={{
                    __html: showFullDocument
                        ? docContent
                        : `${docContent.slice(0, 1000)}...`,
                }}
            />
        </DocxPreviewContainer>
    );
};

// Deal with image
const DisplayImagePreview = ({ fileData, showFullDocument, toggleDocumentView }) => {
    return (
        <div
            onClick={toggleDocumentView}
            style={{
                cursor: "pointer",
                background: "white",
                height: showFullDocument ? "100%" : "300px",
                overflow: "hidden",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                position: "relative",
            }}>
            <a href={fileData} download>download</a>
            {showFullDocument ? (
                // image tag to show
                <img
                    src={fileData}
                    alt="image-preview"
                    style={{ width: "100%", height: "600px", objectFit: "contain", border: "none" }}
                />
            ) : (
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <p>Click to view image</p>
                </div>
            )}
        </div>
    );
};


const DocumentViewer = ({
                            fileType,
                            fileData,
                            showFullDocument,
                            setShowFullDocument,
                        }) => {
    const toggleDocumentView = () => setShowFullDocument(!showFullDocument);

    if (fileType === "pdf") {
        console.log("pdf")
        return (
            <DisplayPdfPreview
                fileData={fileData}
                showFullDocument={showFullDocument}
                toggleDocumentView={toggleDocumentView}
            />
        );
    } else if (fileType === "docx") {
        console.log("docx")
        return (
            <DisplayDocxPreview
                fileData={fileData}
                showFullDocument={showFullDocument}
                toggleDocumentView={toggleDocumentView}
            />
        );
    }  else if (fileType === "image") {
        // if image
        console.log("image")
        return (
            <DisplayImagePreview
                fileData={fileData}
                showFullDocument={showFullDocument}
                toggleDocumentView={toggleDocumentView}
            />
        );
    } else {
        console.log("other")
        return <p>Unsupported file type</p>;
    }
};

const transformProjectInfo = (info) => {
    const transformed = {};

    Object.entries(info).forEach(([key, value]) => {
        switch(key) {
            case 'addedAt':
                transformed['Submitted'] = formatDateFromISO(value);
                break;
            case 'modifiedAt':
                transformed['Last Updated'] = formatDateFromISO(value);
                break;
            case 'project_location':
                transformed['Location'] = value;
                break;
            case 'approved':
            case 'certified':
            case 'company':
                transformed[key] = value;
                break;
            // Exclude 'project_name' and 'time_initiated'
            default:
                break;
        }
    });

    return transformed;
};

function IndivProject() {
    const [company, setCompany] = useState("");
    const { project_name } = useParams();
    const [projectInfo, setProjectInfo] = useState({});
    const [grabbedCompany, setGrabbedCompany] = useState(false);
    const [documentData, setDocumentData] = useState([]);
    const [showFullDocument, setShowFullDocument] = useState(true);
    const [transformedProjectInfo, setTransformedProjectInfo] = useState({});
    const [companyContracts, setCompanyContracts] = useState([]);
    const navigate = useNavigate();

    const user = localStorage.getItem('user');
    const firebaseIdToken = localStorage.getItem('firebase_id_token');
    const authHeader = {
        Authorization: `Bearer ${firebaseIdToken}`
    };

    useEffect(() => {
        // Debugging navigation calls
        console.log("Current location:", window.location.pathname);
    }, [navigate]);

    const fetchProjectDocumentation = async () => {

        try {
            const documentation = await axios.get(
                "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/file",
                {
                    params: {
                        company: company,
                        project_name: project_name
                    },
                    headers: authHeader,
                    responseType: "arraybuffer"
                },
            );
            console.log(documentation)
            const contentType = documentation.headers["content-type"];

            if (contentType.includes("application/zip")) {
                const zip = new JSZip();
                const content = await zip.loadAsync(documentation.data);
                const files = [];

                await Promise.all(
                    Object.keys(content.files).map(async (fileName) => {
                        if (!content.files[fileName].dir) {
                            const fileBlob = await content.files[fileName].async("blob");
                            const url = window.URL.createObjectURL(fileBlob);
                            const fileType = fileName.toLowerCase().endsWith(".pdf")
                                ? "pdf"
                                : fileName.toLowerCase().endsWith(".docx")
                                    ? "docx"
                                    : [".jpeg", ".jpg", ".png"].some(ext => fileName.toLowerCase().endsWith(ext))
                                        ? "image"
                                        : "unsupported";
                            files.push({
                                type: fileType,
                                data: url
                            });
                        }
                    })
                );
                setDocumentData(files);
                console.log(documentData)
            } else {
                const url = window.URL.createObjectURL(new Blob([documentation.data], { type: contentType }));
                const fileType = contentType === "application/pdf"
                    ? "pdf"
                    : contentType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        ? "docx"
                        : contentType.startsWith("image")
                            ? "image"
                            : "unsupported";
                setDocumentData([
                    {
                        type: fileType,
                        data: url
                    },
                ]);
                console.log(documentData)
            }
        } catch (error) {
            console.error("Failed to fetch documentation:", error);
        }
    };

    useEffect(() => {
        const getCompany = async () => {

            try {
                const res = await axios.get(
                    "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/profile_company",
                    {
                        params: {
                            username: user
                        },
                        headers: authHeader
                    }
                );

                const fetchedComp = res.data.data;
                console.log(fetchedComp);
                setCompany(fetchedComp);
                setGrabbedCompany(true);
            } catch (error) {
                console.log(error);
            }
        };
        getCompany();

        const getContracts = async () => {
            try {
                const res = await axios.get(
                    "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/user_all_contracts",
                    {
                        params: {
                            username: user
                        },
                        headers: authHeader
                    }
                );
                const fetchedContracts = res.data.data;
                setCompanyContracts(res.data.data)
                console.log(fetchedContracts);
            } catch (error) {
                console.log(error);
            }
        }
        getContracts();
    }, []);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const res = await axios.get(
                    "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/project",
                    {
                        params: {
                            company: company,
                            project_name: project_name,
                        },
                        headers: authHeader
                    }
                );
                //console.log(res)

                setProjectInfo(res.data.data);
                setTransformedProjectInfo(transformProjectInfo(res.data.data));
            } catch (error) {
                console.log("error from post to get indiv proj");
                console.log(error);
            }
        };

        if (grabbedCompany) {
            fetchProject();
            fetchProjectDocumentation();
        }
    }, [grabbedCompany, project_name]);

    const CreditUsagePieChart = ({ credits_per_year, credits_used }) => {
        const data = [
            { name: 'Used', value: credits_used },
            { name: 'Remaining', value: credits_per_year - credits_used },
        ];
        const COLORS = ['#1A99D4', '#5F7D95']; // Updated colors to match your platform style

        const CustomPieChartTooltip = ({ active, payload }) => {
            if (active && payload && payload.length) {
                return (
                    <CustomTooltip>
                        <TooltipLabel>{payload[0].name} Credits</TooltipLabel>
                        <TooltipValue>{payload[0].value}</TooltipValue>
                    </CustomTooltip>
                );
            }
            return null;
        };

        return (
            <CreditUsageGraph>
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                        <Pie
                            data={data}
                            cx="50%"
                            cy="50%"
                            innerRadius={60}
                            outerRadius={80}
                            fill="#8884d8"
                            paddingAngle={5}
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomPieChartTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
                <CreditUsageText>
                    Credit Usage: {((credits_used / credits_per_year) * 100).toFixed(2)}%
                </CreditUsageText>
                <p>Project credits: {credits_per_year}</p>
                <p>Credits remaining: {data.find(item => item.name === 'Remaining').value}</p>

            </CreditUsageGraph>
        );
    };



    const BuildStatus = () => {
        const { build_status } = projectInfo;
        let icon;
        let color;

        switch (build_status) {
            case 'Awaiting Funding':
                icon = <FaHourglassHalf />;
                color = '#FFA500';
                break;
            case 'In Progress':
                icon = <FaTools />;
                color = '#1E90FF';
                break;
            case 'Complete':
                icon = <FaCheckCircle />;
                color = '#32CD32';
                break;
            default:
                icon = null;
                color = '#000000';
        }

        return (
            <BuildStatusContainer style={{ color }}>
                <BuildStatusIcon>{icon}</BuildStatusIcon>
                <span>{build_status}</span>
            </BuildStatusContainer>
        );
    };

    const RelatedContracts = ({ companyContracts, project_name }) => {
        // const filteredContracts = Object.values(companyContracts)
        //   .filter(contract => contract.contractProject && contract.contractProject.project_name === project_name);
        const filteredContracts = companyContracts
            ? Object.values(companyContracts)
                .filter(contract => contract.contractProject && contract.contractProject.project_name === project_name)
            : [];

        return (
            <RelatedContractsSection>
                <RelatedContractsTitle>
                    <FaFileContract />
                    Related Contracts
                </RelatedContractsTitle>
                <ContractGrid>
                    {filteredContracts.length !== 0 ? (
                        filteredContracts.map(filteredContract => (
                            <ContractCard
                                key={filteredContract.contractName}
                                onClick={() => navigate(`/contract/${filteredContract.contractName}`)}
                            >
                                <ContractName title={filteredContract.contractName}>
                                    {filteredContract.contractName}
                                </ContractName>
                                <ContractTime>{formatDateFromISO(filteredContract.addedAt)}</ContractTime>
                            </ContractCard>
                        ))
                    ) : (
                        <NoContractsMessage>No related contracts found.</NoContractsMessage>
                    )}
                </ContractGrid>
            </RelatedContractsSection>
        );
    };

    // const filteredContracts = Object.values(companyContracts)
    // .filter(contract => contract.contractProject && contract.contractProject.project_name === project_name);
    const filteredContracts = companyContracts
        ? Object.values(companyContracts)
            .filter(contract => contract.contractProject && contract.contractProject.project_name === project_name)
        : [];

    return (
        <MainContainer>
            <BodyDiv>
                <UpperDivMain>
                    <BodyUpperDiv>
                        <LeftSideBody>
                            <StyledCard>
                                <CardHeader>
                                    <CardTitle>{project_name}</CardTitle>
                                </CardHeader>
                                <StyledCardContent>
                                    <ContentWrapper style={{display: 'flex', flexDirection: 'column'}}>
                                        <ContractItem style={{flexDirection: 'column'}}>
                                            <ContractDetails style={{width: '100%'}}>
                                                {Object.entries(transformedProjectInfo)
                                                    .filter(([key, _]) => key !== "projectDocumentation" && key !== "build_status")
                                                    .map(([key, value]) => (
                                                        <DetailItem key={key}>
                                                            <Label>{key.replace(/_/g, " ")}:</Label>
                                                            <ContractId>{value}</ContractId>
                                                        </DetailItem>
                                                    ))}
                                            </ContractDetails>
                                            <ContractDetails style={{width: '100%'}}>
                                                <RelatedContracts companyContracts={companyContracts} project_name={project_name} />
                                            </ContractDetails>
                                        </ContractItem>
                                        <BuildStatus />
                                    </ContentWrapper>
                                </StyledCardContent>
                            </StyledCard>
                        </LeftSideBody>
                        <RightSideBody>
                            <VisualContainer style={{ display: 'block'}}>
                                <ContentWrapper justify="space-between">
                                    <GraphSection>
                                        <CardTitle>Project Fulfillment</CardTitle>
                                        {projectInfo.credits_per_year && projectInfo.credits_used && (
                                            <CreditUsagePieChart
                                                credits_per_year={projectInfo.credits_per_year}
                                                credits_used={projectInfo.credits_used}
                                            />
                                        )}
                                    </GraphSection>
                                    <CreditDetails>
                                        {filteredContracts.length !== 0 ? (
                                            filteredContracts.map(filteredContract => (
                                                <AssociatedContractDiv key={filteredContract.contractName}>
                                                    <ContractTitle>{filteredContract.contractName}</ContractTitle>
                                                    <ContractDetail>Total: {filteredContract.tonsToCapture} tons</ContractDetail>
                                                    <ContractDetail>Removed: {filteredContract.carbonRemoved} tons</ContractDetail>
                                                </AssociatedContractDiv>
                                            ))
                                        ) : (
                                            <NoContractsMessage>
                                                No related contracts found. Start a contract{' '}
                                                <StartContractLink onClick={() => navigate('/ContractBuilder')}>
                                                    here
                                                </StartContractLink>
                                            </NoContractsMessage>
                                        )}
                                    </CreditDetails>
                                </ContentWrapper>
                            </VisualContainer>
                        </RightSideBody>
                    </BodyUpperDiv>
                    <MapContainer>
                        <ProjectLocationMap projectLocation={projectInfo.project_location} />
                    </MapContainer>
                </UpperDivMain>
                <LowerDivMain>
                    <DocumentationHeader>Submitted Documentation</DocumentationHeader>
                    {documentData.length > 0 ? (
                        documentData.map((doc, index) => (
                            <DocumentViewer
                                key={index}
                                fileType={doc.type}
                                fileData={doc.data}
                                showFullDocument={showFullDocument}
                                setShowFullDocument={setShowFullDocument}
                            />
                        ))
                    ) : (
                        <Spinner />
                        // <h3>No documents submitted</h3>
                    )}
                </LowerDivMain>
            </BodyDiv>
        </MainContainer>
    );
}

export default IndivProject;

