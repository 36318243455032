import React, { useState, useEffect } from "react";
import { useStripeConnect } from './useStripeConnect';
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import styled, { keyframes } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import StripeService from "./StripeService";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const ModalOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: scroll;
`;

const ModalContent = styled(motion.div)`
  background: linear-gradient(135deg, #ffffff 0%, #f0f0f0 100%);
  border-radius: 20px;
  padding: 40px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
`;

const GlowingBorder = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  background: linear-gradient(45deg, #00c9ff, #92fe9d);
  z-index: -1;
  filter: blur(10px);
  opacity: 0.7;
  border-radius: 22px;
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: #325d79;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Description = styled.p`
  font-size: 1.1rem;
  color: #5f7d95;
  margin-bottom: 30px;
  line-height: 1.6;
  text-align: center;
`;

const Button = styled(motion.button)`
  background: linear-gradient(45deg, #00c9ff, #92fe9d);
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 1.1rem;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
`;

const ErrorMessage = styled.p`
  color: #ff6b6b;
  text-align: center;
  margin-top: 20px;
  font-weight: bold;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  color: #5f7d95;
  border: 2px solid #5f7d95;

  &:hover {
    background: rgba(95, 125, 149, 0.1);
  }
`;

export default function StripeConnectModal({ isOpen, onClose, company, onAccountCreated }) {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);

  const stripeService = new StripeService();

  useEffect(() => {
    if (onboardingExited) {
      onClose();
    }
  }, [onboardingExited, onClose]);

  const handleSignUp = async () => {
    const email = localStorage.getItem('user');
    setAccountCreatePending(true);
    setError(false);
    try {
      const { account, onboarding_url } = await stripeService.createAccount(company);
      if (account) {
        setConnectedAccountId(account);
        onAccountCreated();
        if (onboarding_url) {
          window.location.href = onboarding_url;
        } else {
          console.log("Stripe account created, but onboarding URL is not available. Please contact support.");
        }
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    } finally {
      setAccountCreatePending(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <ModalOverlay
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
          <ModalContent
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            transition={{ type: "spring", damping: 25, stiffness: 500 }}
            onClick={(e) => e.stopPropagation()}
          >
            <GlowingBorder />
            <Title>Welcome to Carbonsmith</Title>
            <Description>
              Carbonsmith is the only carbon marketplace in the world that operates entirely free of cost to carbon removal suppliers. We are on a mission to help you make a tangible impact on our planet. Let's set up your payment information to get started!
            </Description>
            {!accountCreatePending && !connectedAccountId && (
              <ButtonContainer>
                <Button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleSignUp}
                >
                  Set Up Payment
                </Button>
                <SecondaryButton
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={onClose}
                >
                  Not Now
                </SecondaryButton>
              </ButtonContainer>
            )}
            {accountCreatePending && <LoadingSpinner />}
            {stripeConnectInstance && (
              <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
                <ConnectAccountOnboarding
                  onExit={() => setOnboardingExited(true)}
                />
              </ConnectComponentsProvider>
            )}
            {error && <ErrorMessage>Something went wrong. Please try again.</ErrorMessage>}
          </ModalContent>
        </ModalOverlay>
      )}
    </AnimatePresence>
  );
}