import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import MapChart from '../../../../data/timelapse_emissions_map';
import { StandardButton, Title } from './ExternalUILibrary/ExternalComponents';
import { useNavigate } from 'react-router-dom';
import { IoIosCloudOutline } from "react-icons/io";
import { LiaCarSideSolid } from "react-icons/lia";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { LuHome } from "react-icons/lu";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const animatedStyle = css`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(0px)')};
  animation: ${props => props.isVisible ? fadeIn : fadeOut} 0.6s ease-out;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  background-color: white;

  @media (max-width: 768px) {
    padding-top: 0px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 90%;
  max-width: 1200px;
  margin-bottom: 40px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`;

const MapContainer = styled.div`
  flex: 1;
  max-width: 50%;
  margin-right: 40px;
  margin-left: 8%;
  ${animatedStyle}

  @media (max-width: 1024px) {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
`;

const TextContent = styled.div`
  flex: 1;
  margin-top: 2%;
  ${animatedStyle}

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  ${animatedStyle}
  margin-bottom: 30px;
  color: #0A3C57;
`;

const Paragraph = styled.p`
  font-size: 18px;
  color: #315D79;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify;
  ${animatedStyle}
`;

const Highlight = styled.span`
  color: #1EB1B5;
`;

const StyledButton = styled(StandardButton)`
  ${animatedStyle}
  background-color: #0A3C57;
  margin-top: 3%;
  &:hover {
    background-color: #179fa3;
  }
`;

const EquivalenceRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  // max-width: 1400px;
  margin-top: 5px;
  margin-bottom: 3%;
  ${animatedStyle}

  @media (max-width: 768px) {
    flex-wrap: wrap;
    width: 90%;
  }
`;

const EquivalenceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0;
  width: 22%;

  @media (max-width: 768px) {
    width: 37%;
    margin-bottom: 20px;
  }
`;

const Icon = styled.div`
  font-size: 48px;
  margin-bottom: 10px;
  color: #0A3C57;
`;

const EquivalenceText = styled.span`
  font-size: 16px;
  text-align: left
  color: #315D79;
  // max-width: 120px;
`;

const HighlightNumber = styled.span`
  color: #1EB1B5;
  font-weight: bold;
`;

const HighlightMetric = styled.span`
  color: #0A3C57;
  font-weight: bold;
`;


const Divider = styled.span`
  color: #315D79;
  margin: 0 10px;
  font-size: 48px;
  color: #1EB1B5;
`;

const SolutionSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);


  return (
    <SectionContainer ref={sectionRef}>
      <StyledTitle isVisible={isVisible}>Our Solution</StyledTitle>
      <ContentWrapper>
        <MapContainer isVisible={isVisible}>
          <MapChart />
        </MapContainer>
        <TextContent isVisible={isVisible}>
          <Paragraph isVisible={isVisible} delay={.4}>
            In recent decades, global CO<sub>2</sub> emissions have steadily increased from 32.8 billion metric tons in 2010 to <Highlight style={{fontWeight: 'bold'}}>37.4 billion</Highlight> in 2023. Despite international efforts to curb emissions, the world continues to struggle with reducing its carbon footprint.
          </Paragraph>
          <Paragraph isVisible={isVisible} delay={.4}>
            At Carbonsmith, we believe that achieving a net zero goal requires <Highlight style={{fontWeight: 'bold'}}>collaborative</Highlight> effort and <Highlight style={{fontWeight: 'bold'}}>transparent</Highlight> solutions. Our marketplace and advanced SaaS tools empower businesses and governments to make a tangible impact on reducing CO<sub>2</sub> emissions.
          </Paragraph>
          <StyledButton onClick={() => navigate('/our-team')} isVisible={isVisible} delay={.4}>
          {/* <a href="#contact-form" style={{ textDecoration: 'none', color: 'white'}}> */}
            Learn more
          {/* </a> */}
          </StyledButton>
        </TextContent>
      </ContentWrapper>
      <EquivalenceRow isVisible={isVisible}>
        <EquivalenceItem>
          <Icon><IoIosCloudOutline /></Icon>
          <EquivalenceText>
            <HighlightNumber>1</HighlightNumber> <HighlightMetric>metric ton</HighlightMetric>
            <br />
            carbon dioxide
          </EquivalenceText>
        </EquivalenceItem>
        <Divider>=</Divider>
        <EquivalenceItem>
          <Icon><LiaCarSideSolid /></Icon>
          <EquivalenceText>
            <HighlightNumber>2,500</HighlightNumber> <HighlightMetric>miles driven</HighlightMetric>
            <br />
            by a gasoline car
          </EquivalenceText>
        </EquivalenceItem>
        <Divider>/</Divider>
        <EquivalenceItem>
          <Icon><HiOutlineDevicePhoneMobile /></Icon>
          <EquivalenceText>
            <HighlightNumber>66,000</HighlightNumber> <HighlightMetric>phones</HighlightMetric>
            <br />
            fully charged 
          </EquivalenceText>
        </EquivalenceItem>
        <Divider>/</Divider>
        <EquivalenceItem>
          <Icon><LuHome /></Icon>
          <EquivalenceText>
            <HighlightNumber>0.13</HighlightNumber> <HighlightMetric>homes</HighlightMetric>
            <br />
            energy use per year
          </EquivalenceText>
        </EquivalenceItem>
      </EquivalenceRow>
    </SectionContainer>
  );
};

export default SolutionSection;