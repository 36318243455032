import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
`;

const GameContent = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
`;

const GameContainer = styled.canvas`
  border: 2px solid white;
  margin: 20px 0;
`;

const LoadingMessage = styled.div`
  font-size: 20px;
  margin-bottom: 20px;
`;

const ScoreDisplay = styled.div`
  font-size: 18px;
  margin-top: 10px;
`;


const LoadingGameOverlay = () => {
  const canvasRef = useRef(null);
  const gameContentRef = useRef(null);
  const [score, setScore] = useState(0);
  const [highScore, setHighScore] = useState(() => parseInt(localStorage.getItem('highScore')) || 0);

  useEffect(() => {
    const updatePosition = () => {
      if (gameContentRef.current) {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const viewportHeight = window.innerHeight;
        const centerPosition = scrollTop + (viewportHeight / 2);
        gameContentRef.current.style.top = `${centerPosition}px`;
      }
    };

    // Initial position
    updatePosition();

    // Add scroll listener
    window.addEventListener('scroll', updatePosition, { passive: true });
    // Add resize listener to handle window resizing
    window.addEventListener('resize', updatePosition, { passive: true });

    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('resize', updatePosition);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let ballX, ballY, dx, dy, paddleX, currentScore;
    let ballColor = '#0095DD';
    let paddleColor = '#0095DD';

    const ballRadius = 10;
    const paddleHeight = 10;
    const paddleWidth = 75;
    let rightPressed = false;
    let leftPressed = false;

    // Reset game variables
    const resetGame = () => {
      ballX = canvas.width / 2;
      ballY = canvas.height - 30;
      dx = 2;
      dy = -2;
      paddleX = (canvas.width - paddleWidth) / 2;
      currentScore = 0;
      setScore(0);
      ballColor = getRandomColor();
      paddleColor = getRandomColor();
    };

    const getRandomColor = () => {
      const colors = ['#FF5733', '#33FF57', '#3357FF', '#FF33A1', '#FFC300', '#00FFFF'];
      return colors[Math.floor(Math.random() * colors.length)];
    };

    const updateHighScore = () => {
      if (currentScore > highScore) {
        setHighScore(currentScore);
        localStorage.setItem('highScore', currentScore);
      }
    };

    resetGame();

    const drawBall = () => {
      ctx.beginPath();
      ctx.arc(ballX, ballY, ballRadius, 0, Math.PI * 2);
      ctx.fillStyle = ballColor;
      ctx.fill();
      ctx.closePath();
    };

    const drawPaddle = () => {
      ctx.beginPath();
      ctx.rect(paddleX, canvas.height - paddleHeight, paddleWidth, paddleHeight);
      ctx.fillStyle = paddleColor;
      ctx.fill();
      ctx.closePath();
    };

    const movePaddle = () => {
      if (rightPressed && paddleX < canvas.width - paddleWidth) {
        paddleX += 7;
      } else if (leftPressed && paddleX > 0) {
        paddleX -= 7;
      }
    };

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawBall();
      drawPaddle();
      movePaddle();

      // Bounce off the walls
      if (ballX + dx > canvas.width - ballRadius || ballX + dx < ballRadius) {
        dx = -dx;
      }
      // Bounce off the top
      if (ballY + dy < ballRadius) {
        dy = -dy;
      }
      // Check if ball hits the bottom (lose condition)
      else if (ballY + dy > canvas.height - ballRadius) {
        // Check if the ball hits the paddle
        if (ballX > paddleX && ballX < paddleX + paddleWidth) {
          dy = -dy;
          currentScore += 1;
          setScore(currentScore);
          ballColor = getRandomColor();
          paddleColor = getRandomColor();
        } else {
          // 2-second break before resetting the game
          updateHighScore();
          setTimeout(() => resetGame(), 2000);
          return;
        }
      }

      // Move ball
      ballX += dx;
      ballY += dy;
    };

    const keyDownHandler = (e) => {
      if (e.key === 'Right' || e.key === 'ArrowRight') {
        rightPressed = true;
      } else if (e.key === 'Left' || e.key === 'ArrowLeft') {
        leftPressed = true;
      }
    };

    const keyUpHandler = (e) => {
      if (e.key === 'Right' || e.key === 'ArrowRight') {
        rightPressed = false;
      } else if (e.key === 'Left' || e.key === 'ArrowLeft') {
        leftPressed = false;
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('keyup', keyUpHandler);

    const interval = setInterval(draw, 10);
    return () => {
      clearInterval(interval);
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('keyup', keyUpHandler);
    };
  }, [highScore]);

  return (
    <>
      <Overlay />
      <GameContent ref={gameContentRef}>
        <LoadingMessage>Play a game while we create your specialized content! This may take a minute or two.</LoadingMessage>
        <GameContainer ref={canvasRef} width="480" height="320" />
        <ScoreDisplay>Score: {score}</ScoreDisplay>
        <ScoreDisplay>High Score: {highScore}</ScoreDisplay>
      </GameContent>
    </>

  );
};

export default LoadingGameOverlay;