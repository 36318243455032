import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import moment from "moment-timezone";

const StyledH4 = styled.h4`
  color: #325d79ff; // Darker Teal
  font-size: 1.2em;
  margin-bottom: 8px;
  font-weight: bold;
`;

const StyledParagraph = styled.p`
  color: #5f7d95ff; // Dark Grey
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 10px;
  text-align: left;
  margin-left: 10%;
`;

const MainGoalsContainer = styled.div`
  padding: 2%;
  display: flex;
  flex-direction: column;
  padding-bottom: 1%;
  // background-color: white;
`;

const MainGoalsContainerTitle = styled.p`
  color: #325d79ff;
  font-weight: bold;
  margin-top: 0.7%;
  text-align: left;
  font-size: 1.25em;
  margin-left: 2%;
`;

const GoalsContainerMain = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  padding: 1%;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 2vh;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const GoalsIndivContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5%;
  background-color: #f2f2f2;
  // padding: 2%:
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 25%;
  padding-top: 2%;
  padding-bottom: 1%;
  border: 1px solid #325d7980;

  &:hover {
    transform: scale(1.02);
    cursor: pointer;
  }
`;

const GoalsTitleDiv = styled.div`
  margin-bottom: 1%;
`;

const GoalsBodyDiv = styled.div`
  font-size: 0.9em;
  text-align: left;
  padding: 4%;
`;

const GoalsTitleIndiv = styled.p`
  color: #325d79ff;
  font-weight: bold;
  margin: 0 0 1% 0;
`;

const GoalsBodyLine = styled.div`
  display: flex;
  flex-direction: row;
`;

const GoalsBodyTitle = styled.p`
  font-weight: bold;
  color: #5f7d95ff;
  margin-top: 0;
  margin-bottom: 0;
`;

const GoalsBodyIndiv = styled.p`
  color: #5f7d95ff;
  margin-left: 2%;
  margin-top: 0;
  margin-bottom: 0;
`;

function GoalsPreviewsProfile() {
  const [userDataAll, setUserDataAll] = useState({});
  const [userTargetsAll, setUserTargetsAll] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem("user");

    const getUserInfoAll = async () => {

      try {
        const res = await axios.get(
            "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/all_user_data",
            {
              params: {
                username: user
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`
              }
            }
        );
        // console.log('all data: ', res.data);
        // console.log('object type: ', typeof(res.data.data.targets))
        setUserDataAll(res.data.data);
        //console.log("targets", res.data.data.targets);
        //console.log("goals", res.data.data.goals);

        // For now, we name buyers' targets as "goals", and sellers' targets as "targets".
        // The endpoints to get them are different, one is "seller_targets", and another is "buyer_goals".
        // For each goal in "goals" in buyers side, the link to the detailed page is "/Removal-Goals/{goalName}" (GoalIndividualBuyerPage component),
        // but for each target in "targets" in sellers side, the detailed page's link is "/target/{targetName}" (TargetPageIndiv component).
        // In the future, we want to rename "goals" as "targets" in buyers side.
        // But for now, we still have to differentiate them.
        // Since two endpoints "seller_targets" and "buyer_goals" cannot auth user types,
        // so the way to differentiate now is to check whether the previous response data has "targets" list.
        // If not, then we will define the user as a buyer and get "goals" list.
        // Since now "goals" and "targets" may not have exact same amount of features, we have to fix the format of every goal to be the same as every target.
        const formatGoals = (goals) => {
          return Object.keys(goals).reduce((acc, key) => {
            const goal = goals[key];
            acc[key] = {
              targetName: goal.goalName,
              Status: goal.Status,
              targetType: goal.goalType,
              endDate: goal.endDate,
              addedAt: goal.addedAt || null,
              modifiedAt: goal.modifiedAt || null,
              startDate: goal.startDate || null,
            };
            return acc;
          }, {});
        };

        // Check whether response data has "targets" first.
        // We make userTargetsAll contain two attributes, one is type (to help check whether it is a seller or buyer),
        // another is the real data list.
        if (res.data.data.targets) {
          setUserTargetsAll({ type: "target", data: res.data.data.targets });
        } else if (res.data.data.goals) {
          // If not, then we know it is a buyer, so get "goals".
          const formattedGoals = formatGoals(res.data.data.goals);
          setUserTargetsAll({ type: "goal", data: formattedGoals });
        }
      } catch (error) {
        console.log("An error occurred: ", error);
      }
    };

    getUserInfoAll();
  }, []);

  // If userTargetsAll's type is target, then every goal card will be linked to TargetPageIndiv, else Removal-Goals.
  return (
      <MainGoalsContainer>
        <MainGoalsContainerTitle>Company Goals</MainGoalsContainerTitle>
        {userTargetsAll.data ? (
            <GoalsContainerMain>
              {Object.keys(userTargetsAll.data).length > 0 &&
                  Object.values(userTargetsAll.data).map((target) => (
                      <GoalsIndivContainer
                          onClick={() => {
                            const targetUrl = userTargetsAll.type === "target"
                                ? `/target/${target.targetName}`
                                : `/Removal-Goals/${target.targetName}`;
                            navigate(targetUrl);
                          }}
                      >
                        <GoalsTitleDiv>
                          <GoalsTitleIndiv>{target.targetName}</GoalsTitleIndiv>
                        </GoalsTitleDiv>
                        <GoalsBodyDiv>
                          <GoalsBodyLine>
                            <GoalsBodyTitle>Status:</GoalsBodyTitle>
                            <GoalsBodyIndiv> {target.Status}</GoalsBodyIndiv>
                          </GoalsBodyLine>
                          {Object.entries(target["targetType"]).map(([key, value]) =>
                              key === "CO2 Removed" ? (
                                  <GoalsBodyLine>
                                    <GoalsBodyTitle>{key}:</GoalsBodyTitle>
                                    <GoalsBodyIndiv>{value} Tons</GoalsBodyIndiv>
                                  </GoalsBodyLine>
                              ) : (
                                  <GoalsBodyLine>
                                    <GoalsBodyTitle>{key}:</GoalsBodyTitle>
                                    <GoalsBodyIndiv>{value}</GoalsBodyIndiv>
                                  </GoalsBodyLine>
                              )
                          )}
                          <GoalsBodyLine>
                            <GoalsBodyTitle>Target Date:</GoalsBodyTitle>
                            <GoalsBodyIndiv> {target.endDate}</GoalsBodyIndiv>
                          </GoalsBodyLine>
                        </GoalsBodyDiv>
                      </GoalsIndivContainer>
                  ))}
            </GoalsContainerMain>
        ) : (
            <>
              {/* <StyledH4>Contracts</StyledH4> */}
              <StyledParagraph>
                Begin uploading your company targets to see them here!
              </StyledParagraph>
            </>
        )}
      </MainGoalsContainer>
  );
}

export default GoalsPreviewsProfile;