import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import LandingBodyTabsBuyer from './LandingBodyTabsBuyer';
import AvailableCreditsFooter from './UniversalFcnsBuyerFE/AvailableCreditsScroll';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import { format2Decimals, formatNumberDollars } from '../../../UniversalFunctions/Numeric/Format2Decimal';
import SearchBar from '../../../UI/Library/SearchBar';
import ProgressBar from '../CoreProductFrontEndSeller/GraphSourcing/ProgressBar';
import { ContractStatus } from '../../../UI/Library/SellerComponents';
import { PageContainer, MainContent, PageHeader } from '../../../UI/Library/PageStructure';
import { Title, Subtitle, HelpLink, ContractDetail, ContractValue, ContractLabel, SearchAndButton, ProgressAmount, RemainingAmount, ContractsHeader, ContractHeader, ContractTitle, ContractsTitle,
    AddButton, ContractsSection
 } from '../../../UI/Library/SellerComponents';
import { FiSearch, FiPlus } from 'react-icons/fi';
import { filter } from 'jszip';
import { useSearch } from '../../../UniversalFunctions/useSearch';
import { SummarySectionBuyer } from '../../../UI/Library/PageSections/ContractSummarySection';


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ContractGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const ContractCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.25rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  
  &:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
`;

const ContractProgress = styled.div`
  margin-top: 1rem;
`;

const ContractProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #5F7D95;
`;

const calculateMetrics = (contracts) => {
    return {
      totalRevenue: contracts.reduce((sum, contract) => sum + parseFloat(contract.Revenue), 0),
      tonsUnderContract: contracts.reduce((sum, contract) => sum + Number(contract.tonsToCapture), 0),
      totalDelivered: contracts.reduce((sum, contract) => sum + Number(contract.carbonRemoved), 0),
    };
  };

function ContractHistoryBuyers() {
    const navigate = useNavigate();
    const [contracts, setContracts] = useState([]);
    const [metrics, setMetrics] = useState(null);
    const [activeStatus, setActiveStatus] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    const handleStatusClick = (status) => {
        if (status === activeStatus) {
          setActiveStatus('');
          setStatusFilter('');
        } else {
          setActiveStatus(status);
          setStatusFilter(status);
        }
      };
    
    const handleClearStatus = () => {
        setActiveStatus('');
        setStatusFilter('');
      };
    

    useEffect(() => {
        // Fetch contracts data and set it using setContracts
        const fetchContracts = async () => {
            const requestParams = {};
            const username = localStorage.getItem('user');
            const accessToken = localStorage.getItem('access_token');
            requestParams['username'] = username;
            const config = {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }
            try {
                const response = await axios.get(
                    'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/user_all_contracts',
                    {
                        params: {
                            username: username
                        },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`
                        }
                    });

                const fetchedContracts = response.data.data;
                //console.log("ContractHistoryBuyers user_all_contracts", response.data.data)

                if (fetchedContracts) {
                    setContracts(Object.values(fetchedContracts));
                    setMetrics(calculateMetrics(Object.values(fetchedContracts)));
                } else {
                    setContracts([]);
                }
                //console.log('data ', fetchedContracts);
  
                // setContracts(fetchedContracts || [])
            } catch (error) {
                setContracts([]);
                console.log('error :', error);
            }
            setIsLoading(false);
        };
        fetchContracts();
  
      }, []);

    const { searchTerm, setSearchTerm, searchResults, setStatusFilter } = useSearch(contracts, ['contractName', 'contract_hedera', 'Status', 'company']);


    //console.log('contracts in state:' ,contracts);

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <PageContainer style={{backgroundColor: '#E4E8E8'}}>
        <LandingBodyTabsBuyer />
        <MainContent>
          <PageHeader>
            <div>
              <Title>Contracts</Title>
              <Subtitle>Access and manage your contracts</Subtitle>
            </div>
            <HelpLink href="#">Help?</HelpLink>
          </PageHeader>
        
          <SummarySectionBuyer contracts={contracts} onStatusClick={handleStatusClick} activeStatus={activeStatus} onClearStatus={handleClearStatus}/>
          
          <ContractsSection>
            <ContractsHeader>
              <ContractsTitle>All Contracts</ContractsTitle>
            </ContractsHeader>
            <SearchAndButton>
              <SearchBar
                searchTerm={searchTerm}
                onSearch={setSearchTerm}
                placeholder="Search Contract"
              />
              <>
              </>
            <AddButton onClick={() => navigate('/')}>
                {/* This should take to markets page once developed */}
                <FiPlus style={{ marginRight: '0.5rem' }} />
                Add Contracts
            </AddButton>
            </SearchAndButton>
            
            <ContractGrid>
              {searchResults && searchResults.length > 0 ? (
                searchResults.map(contract => (
                  <ContractCard key={contract.contractName} onClick={() => navigate(`/purchased-contract/${contract.contractName}`)}>
                    <ContractHeader>
                      <ContractTitle>{contract.contractName}</ContractTitle>
                        {contract.Status === 'Uninitialized' && (
                            <ContractStatus>{contract.Status}</ContractStatus>
                          )}
                          {contract.Status === 'In Progress' && (
                            <ContractStatus style={{ backgroundColor: '#b2e0b2', color: 'white'}}>{contract.Status}</ContractStatus>
                          )}
                          {contract.Status === 'Completed' && (
                            <ContractStatus style={{ backgroundColor: '#ADD8E6', color: 'white'}}>{contract.Status}</ContractStatus>
                          )}
                    </ContractHeader>
                    <ContractDetail>
                      <ContractLabel>Contract ID:</ContractLabel>
                      <ContractValue>{contract.contract_hedera}</ContractValue>
                    </ContractDetail>
                    <ContractDetail>
                      <ContractLabel>Project:</ContractLabel>
                      <ContractValue>{contract.contractProject?.project_name || 'N/A'}</ContractValue>
                    </ContractDetail>
                    <ContractDetail>
                      <ContractLabel>Price:</ContractLabel>
                      <ContractValue>${contract.priceTonUSD} / ton</ContractValue>
                    </ContractDetail>
                    <ContractProgress>
                      <ProgressBar percent={(contract.carbonRemoved / contract.tonsToCapture) * 100} />
                      <ContractProgressText>
                        <div><ProgressAmount>${formatNumberDollars(contract.carbonRemoved * contract.priceTonUSD)}</ProgressAmount> <RemainingAmount> / ${formatNumberDollars(contract.tonsToCapture * contract.priceTonUSD)} USD</RemainingAmount></div>
                        <div><ProgressAmount>{contract.carbonRemoved}</ProgressAmount><RemainingAmount> / {contract.tonsToCapture} t</RemainingAmount></div>
                      </ContractProgressText>
                    </ContractProgress>
                  </ContractCard>
              ))) : (<p>No contracts found.</p>)
              }
            </ContractGrid>
          </ContractsSection>
        </MainContent>
      </PageContainer>
            // <MainContainer>
            //     <BodyDiv>
            //         {isLoading ? (<Spinner />): (
            //             <>
            //         <UpperDivMain>
            //             <PageTitle>Contract History</PageTitle>
                        
            //             {contracts && Object.keys(contracts).length > 0 ? (
            //                 Object.values(contracts)
            //                 //sorting by alphabetical order (between 'completed', 'unitialized','in progress' -> ideally showing in order of progress completed)
            //                 .sort((a, b) => a.status.localeCompare(b.status))
            //                 .map((contract) => (
            //                     <ContractItem onClick={() => {navigate(`/purchased-contract/${contract.contractName}`)}}>
            //                     <ContractTitle key={contract.contract_hedera}>{contract.contractName}</ContractTitle>
            //                     <ContractDesc><ContractDescTitle>Contract ID: </ContractDescTitle>{contract.contract_hedera}</ContractDesc>
            //                     <ContractDesc><ContractDescTitle>Tons: </ContractDescTitle>{contract.tonsToCapture}</ContractDesc>
            //                     <ContractDesc><ContractDescTitle>Price Per Ton: </ContractDescTitle>${formatNumberDollars(contract.priceTonUSD)}</ContractDesc>
            //                     <ContractDesc><ContractDescTitle>Status: </ContractDescTitle>{contract.status}</ContractDesc>
            //                     </ContractItem>
            //                 ))
            //             ) : (
            //                 <NoContractsMessage>
            //                     <h1>It looks like you don't have any contracts</h1>
            //                     <AnimatedParagraph>Get started by adding contracts below!</AnimatedParagraph>
            //                 </NoContractsMessage>
            //             )}
            //         </UpperDivMain>

            //         <LowerDivMain>
            //             <AvailableCreditsFooter />
            //         </LowerDivMain>
            //         </>
            //         )}
            //     </BodyDiv>


                
            // </MainContainer>
    );
}

export default ContractHistoryBuyers;