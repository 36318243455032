import React, { useState } from 'react';
import styled from 'styled-components';

const FileInputContainer = styled.div`
    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const StyledFileInput = styled.input`
    display: none;
`;

const FileInputLabel = styled.label`
    background-color: #5f7d95ff;
    color: white;
    padding: 8px 15px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 2%;
    font-size: .8rem;

    &:hover {
        transform: scale(1.02);
    }
`;

const RemoveButton = styled.button`
    background-color: red;
    color: white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    margin-left: 10px;

    &:hover {
        transform: scale(1.1);
    }
`;

const FileNameDisplay = styled.div`
    margin-top: 10px;
    display: flex;
    align-items: center;
`;

const FileUpload = ({ onUploadSuccess, onFileSelect }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const newFiles = Array.from(event.target.files);
        if (newFiles.length > 0) {
            const updatedFiles = [...selectedFiles, ...newFiles];
            setSelectedFiles(updatedFiles);
            onFileSelect(updatedFiles);
            onUploadSuccess(true);
        }
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
        onFileSelect(updatedFiles);
    };

    return (
        <FileInputContainer>
            <StyledFileInput
                type="file"
                id="fileInput"
                multiple
                onChange={handleFileChange}
            />
            <FileInputLabel htmlFor="fileInput">Choose Files</FileInputLabel>
            {selectedFiles.map((file, index) => (
                <FileNameDisplay key={index}>
                    {file.name}
                    <RemoveButton onClick={() => handleRemoveFile(index)}>X</RemoveButton>
                </FileNameDisplay>
            ))}
        </FileInputContainer>
    );
};

export default FileUpload;
