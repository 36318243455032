import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

// Define the keyframes
const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Define the styled component using the keyframes
const Loader = styled.div`
  margin: auto;
  border: 20px solid #EAF0F6; /* Lighter grey border */
  border-radius: 50%;
  border-top: 20px solid #325d7980; /* Colorful part of the border */
  width: 200px;
  height: 200px;
  animation: ${rotate} 4s linear infinite;
`;

// Component to use in your app
const Spinner = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
  }, [navigate]);

  return <Loader />;
}

export default Spinner;
