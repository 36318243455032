import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { Title as ExternalTitle } from './ExternalUILibrary/ExternalComponents';
import logo1 from '../../../../images/Venture_Accelerator_UCLA Anderson.png'; // Replace with actual path to your logo
import logo2 from '../../../../images/clean_leap.png';
import logo3 from '../../../../images/UCLA_Anderson_School_of_Management_logo_2019.svg.png';
import { useNavigate } from 'react-router-dom';
// import logo4 from '../../../../images/carbonsmith_logo_final.jpg';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const animatedStyle = css`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(10px)')};
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)} 0.6s ease-out;
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
`;

const SectionContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: white;
  text-align: center;
  overflow: hidden;
  @media (max-width: 750px) {
    // margin-bottom: 60%;
  }
`;

const GridBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, #BBE0E1 1px, transparent 1px), 
                    linear-gradient(to bottom, #BBE0E1 1px, transparent 1px);
  background-size: 20px 20px;
  mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  z-index: 1;
  ${animatedStyle}
`;

const Title = styled(ExternalTitle)`
  z-index: 2;
  ${animatedStyle}
`;

const LogosContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
  width: 80%;
  margin-bottom: 5%;
  background-color: transparent;
  z-index: 2;

  @media (max-width: 750px) {
    flex-direction: column;
    margin-top: 10%;
  }
`;

const Logo = styled.img`
  max-width: 200px;
  max-height: 100px;
  margin: 0 20px;
  z-index: 2;
  ${animatedStyle}

  @media (max-width: 750px) {
    margin-bottom: 15%;
  }
`;

const Text = styled.p`
  font-size: 24px;
  color: #315D79;
  width: 80%;
  margin-top: 30px;
  z-index: 2;
  ${animatedStyle}
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  z-index: 2;
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: #1EB1B5;
  border-radius: 50%;
  ${animatedStyle}
`;

const OurPartners = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2, // Trigger when 20% of the section is visible
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <SectionContainer ref={sectionRef}>
      <GridBackground isVisible={isVisible} />
      <Title isVisible={isVisible}>Our Partners</Title>
      <LogosContainer>
        <a href="https://www.anderson.ucla.edu/about/centers/price-center-for-entrepreneurship-and-innovation/anderson-venture-accelerator">
        <Logo src={logo1} alt="UCLA Venture Accelerator Logo" isVisible={isVisible} />
        </a>
        <a href="https://cleanleap.org/">
        <Logo src={logo2} alt="Clean Leap Partner Logo" isVisible={isVisible} />
        </a>
        <a href="https://www.anderson.ucla.edu/">
        <Logo src={logo3} alt="UCLA Anderson Logo " isVisible={isVisible} style={{width: '250px', height: '50px'}} />
        </a>
        <a href="https://cdr.fyi" target="_blank">
        <Logo src="https://cdr.fyi/badge/v1/badge_light.svg" alt="CDR.fyi Partner Badge" isVisible={isVisible} />
        </a>
        {/* <Logo src={logo4} alt="Partner Logo 4" isVisible={isVisible} /> */}
      </LogosContainer>
      <Dots style={{ marginTop: '3%' }}>
        <Dot isVisible={isVisible} />
        <Dot isVisible={isVisible} />
        <Dot isVisible={isVisible} />
      </Dots>
      <Text isVisible={isVisible}>
        We bring trust, transparency, and standardization to sustainable finance.
      </Text>
      <Dots style={{ marginBottom: '5%' }}>
        <Dot isVisible={isVisible} />
        <Dot isVisible={isVisible} />
        <Dot isVisible={isVisible} />
      </Dots>
    </SectionContainer>
  );
};

export default OurPartners;

