import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import React from "react";
import styled from "styled-components";
import { interval } from "d3";
// import { ResponsiveContainer } from 'recharts';

const StyledTooltip = styled.div`
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
`;

const CustomTooltip = ({ active, payload, goalType }) => {
    if (active && payload && payload.length) {
      let unit;
      switch (goalType) {
        case "Total Emissions Reduced":
        case "CO2 Removed":
          unit = "Tons";
          break;
        
        case "Contracts Completed":
          unit = "Contracts";
          break;

        case "Partnerships Created":
          unit = "Companies";
          break;

        case "Total Emissions Reduced (%)":
          unit = "%";
          break;

        default:
          unit = "";
      }

      return (
        <StyledTooltip>
          <p>{goalType}: {payload[0].value} {unit}</p>
        </StyledTooltip>
      );
    }
  
    return null;
};
  
function ProgressBarChart({ data, goalType }) {
    // console.log('final dataaaa', data);
    // console.log('final goaltypeeee', goalType);

    if (!goalType || !goalType.name) {
        // should make a loading spinner or somethijng
      return <div>Loading...</div>;
    }

    const renderBar = () => {
      switch (goalType.name) {
        case "Total Emissions Reduced":
          return <Bar dataKey="goalTypeDataInt" fill="#8884d8" name="Total Emissions Reduced" />;
        
        case "CO2 Removed":
          return <Bar dataKey="goalTypeDataInt" fill="#8884d8" name="CO2 Removed" />;
        
        case "Contracts Completed":
          return <Bar dataKey="goalTypeDataInt" fill="#8884d8" name="Contract Completed" />;
        
        case "Partnerships Created":
          return <Bar dataKey="goalTypeDataInt" fill="#8884d8" name="Partnerships Created" />;
        
        case "Total Emissions Reduced (%)":
          return <Bar dataKey="goalTypeDataInt" fill="#8884d8" name="Total Emissions Reduced (%)" />;
        }
    }

    return (
        // <ResponsiveContainer width="100%" height={300}>
        <ResponsiveContainer width="100%" height={300}>

            <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip content={<CustomTooltip goalType={goalType.name}/>}/>
                <Legend />
                {renderBar()}
                {/* <Bar dataKey="emissionsReduced" fill="#8884d8" name="poop" /> */}
            </BarChart>
        </ResponsiveContainer>

    );
}

//need to change this function to take in both start and end
function generateIntervalDates(endDateStr) {
    const intervals = 6;
    const endDate = new Date(endDateStr);
    const currentDate = new Date();
    const totalInterval = endDate - currentDate;
    const singleInterval = totalInterval / intervals;
  
    const dates = [];
    for (let i = 0; i <= intervals; i++) {
      const intervalDate = new Date(currentDate.getTime() + singleInterval * i);
      dates.push(intervalDate);
    }
  
    return dates;
  }
  
  // Format the dates for display and prepare data for the bar chart
  function prepareChartData(dates, goalData, goalType) {
    switch (goalType) {
      case "Total Emissions Reduced":
        // console.log('dates', dates);
        const intervalEmissionReduction = Number((goalData / dates.length).toFixed(2));
        // console.log('interval',  intervalEmissionReduction);
        return dates.map((date, index) => ({
          date: date.toISOString().split('T')[0], // Format the date as 'YYYY-MM-DD'
          goalTypeDataInt: intervalEmissionReduction * (index + 1)
        }));
      case "CO2 Removed":
        // console.log('dates', dates);
        // console.log('goalData', goalData);
        const intervalCO2Removed = Number((goalData / dates.length).toFixed(2));
        // console.log('intco2', intervalCO2Removed);
        console.log(dates.map((date, index) => ({
          date: date.toISOString().split('T')[0],
          goalTypeDataInt: intervalCO2Removed * (index + 1)
        })))
        return dates.map((date, index) => ({
          date: date.toISOString().split('T')[0],
          goalTypeDataInt: intervalCO2Removed * (index + 1)
        }));
      case "Contracts Completed":
        const intervalContractsCompleted = Number(( goalData / dates.length).toFixed(0));
        return dates.map((date, index) => ({
          date: date.toISOString().split('T')[0],
          goalTypeDataInt: intervalContractsCompleted * (index + 1)
        }));
      case "Partnerships Created":
        const intervalPartnershipsCreated = Number(( goalData / dates.length).toFixed(0));
        return dates.map((date, index) => ({
          date: date.toISOString().split('T')[0],
          goalTypeDataInt: intervalPartnershipsCreated * (index + 1)
        }));
      case "Total Emissions Reduced (%)":
        const intervalPercentEmissionsReduced = Number(( goalData / dates.length).toFixed(2));
        return dates.map((date, index) => ({
          date: date.toISOString().split('T')[0],
          goalTypeDataInt: intervalPercentEmissionsReduced * (index + 1)
        }));
    }

    ;

  }

export default ProgressBarChart;
export { generateIntervalDates, prepareChartData };