import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";


const Card = styled.div`
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  min-width: 45%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CardHeader = styled.div`
  margin-bottom: 1rem;
`;

const CardTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: bold;
  // margin-bottom: 0.5rem;
  text-align: left;
  // margin-left: 2%;
  color: #345D72;
`;

const CardDescription = styled.p`
  font-size: 1rem;
  color: #6b7280;
  text-align: left;
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StandardButton = styled.button`
  background-color: #325d79ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #5f7d95ff;
  }
`;

const TabsContainer = styled.div`
//   width: 400px;
`;

const TabsListContainer = styled.div`
  text-align: left;
  margin-left: 4%;
`;

const TabsList = styled.div`
  display: inline;
//   grid-template-columns: repeat(2, 1fr);
  width: 100%;
  background-color: #f9f9f9;
  border-bottom: 1px solid #ddd;
`;

const TabsTrigger = styled.button`
  padding: 1% 1.5%;
  text-align: center;
  background: ${(props) => (props.isActive ? '#fff' : '#f9f9f9')};
  border: 1px solid #ddd;
  border-bottom: ${(props) => (props.isActive ? 'none' : '1px solid #ddd')};
  cursor: pointer;

  &:hover {
    background: #fff;
  }
`;

const TabHeader = styled.p`
  font-weight: bold;
  color: black;
`;

const TabsContent = styled.div`
  padding: 16px;
  border: 1px solid #ddd;
  border-top: none;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
`;

const HeaderContainer = styled.header`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 32px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 24px;
`;

const HeaderTitle = styled.h1`
  font-size: 2em;
  margin: 0;
  color: #212529;
  font-weight: 600;
  line-height: .75;
`;

const HeaderDescription = styled.p`
  font-size: 1em;
  margin: 8px 0 0;
  color: #495057;
  line-height: 1.5;
`;

const HeaderActions = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 8px;
`;

const HeaderButton = styled.button`
  padding: 8px 16px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;

  &:hover {
    background-color: #0056b3;
  }
`;

const ContractDetails = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const ContractItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
`;

const ContractTitle = styled.h3`
  font-size: 1.125rem;
  color: #345D72;
  margin: 0;
  font-weight: 600;
`;


const ContractId = styled.div`
  font-size: 0.875rem;
  color: #6b7280;
`;

const ContractName = styled.div`
  font-size: 1.125rem;
  font-weight: 600;
`;

const HeaderContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-evenly;
`;

const ContractIdRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const Title = styled.p`
  font-size: 1.75rem;
  color: #1D374A;
  margin: 0;
  font-weight: 600;
  text-align: left;
`;

const Subtitle = styled.p`
  font-size: 0.875rem;
  color: #8D9B9C;
  margin: 0.25rem 0 0 0;
`;


const HelpLink = styled.a`
  color: #325D79;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 600;
  
  &:hover {
    text-decoration: underline;
  }
`;

const ContractCard = styled(motion.div)`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
`;

const BackButton = styled(Link)`
  display: inline-flex;
  align-items: start;
  color: #1EB1B5;
  text-decoration: none;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.3s ease;

  &:hover {
    color: #345D72;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const TitleAndStatus = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;

  @media (min-width: 1000px) {
  width: 33%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ContractStatus = styled.span`
  align-content: center;
  padding: 0.5rem 0.6rem;
  border-radius: 12px;
  font-size: 0.875rem;
  font-weight: 500;
  background-color: ${props => {
    switch (props.status) {
      case 'Uninitialized':
        return '#FFEBD4';
      case 'In Progress':
        return '#E4F2CC';
      case 'Completed':
        return 'gray';
      default:
        return '#d1e7dd'; // Default background color
    }
  }};
  color: ${props => {
    switch (props.status) {
      case 'Uninitialized':
        return '#F76A1D';
      case 'In Progress':
        return '#43A800';
      case 'Completed':
        return 'white';
      default:
        return '#0f5132'; // Default text color
    }
  }};
`;

const ContractGrid = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const InfoLabel = styled.span`
  font-size: 0.875rem;
  color: #345D72;
  margin-bottom: 0.25rem;
  font-weight: bold;
`;

const InfoValue = styled.span`
  font-size: 1rem;
  color: #1D374A;
  font-weight: 400;
    font-size: 0.875rem;
    text-align: left;
`;

const InfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2%;
`;

const ContractInfoValue = styled(InfoValue)`

&:hover {
  cursor: pointer
  }
`;

const ContractDetail = styled.div`
  font-size: 0.875rem;
  color: #345D72;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
`;

const ContractLabel = styled.span`
  color: #345D72;
`;

const ContractValue = styled.span`
  color: #345D72;
  font-weight: 500;
`;

const SearchAndButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ProgressAmount = styled.span`
  font-weight: bold;
  color: #373D46;
`;

const RemainingAmount = styled.span`
  color: #373D46;
`;

const ContractsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContractsTitle = styled.p`
  font-size: 1.5rem;
  color: #0A3C57;
  margin-left: 1%;
  font-weight: 600;
  text-align: left;
`;

const AddButton = styled.button`
  background-color: #0A3C57;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: auto 0;
  
  &:hover {
    background-color: #155f71;
  }
`;

const ContractsSection = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
`;

const OverviewCard = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 23.5%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  padding-bottom: 10px;

  @media (max-width: 800px) {
    width: 80%;
    margin: 10px auto;

  }
`;

const OverviewCardTitleTop = styled.h3`
  font-size: 18px;
  color: #345D72;
  margin-bottom: 10px;
  text-align: left;
`;

const OverviewCardValue = styled.div`
  font-size: 26px;
  color: #00344F;
  font-weight: bold;
  text-align: left;
//   display: inline;
`;

export { Card, CardHeader, CardTitle, CardDescription, CardContent, StandardButton, TabsContainer, TabsList, TabsTrigger, TabsContent, TabHeader, TabsListContainer, HeaderContainer, HeaderTitle, HeaderDescription, HeaderActions, HeaderButton,
  ContractDetails, ContractItem, ContractStatus, ContractId, ContractName, HeaderContentDiv, ContractIdRow, Title, Subtitle, HelpLink, ContractCard, BackButton, TitleRow, TitleAndStatus, ContractGrid, InfoLabel, InfoValue, InfoItem, ContractInfoValue,
  ContractDetail, ContractLabel, ContractValue, SearchAndButton, ProgressAmount, RemainingAmount, ContractsHeader, ContractHeader, ContractTitle, ContractsTitle, AddButton, ContractsSection, OverviewCard, OverviewCardTitleTop,
  OverviewCardValue

 };