import { client } from "../../../../utils/sanity/client";
import React, { useState, useEffect, useRef } from "react";
import imageUrlBuilder from '@sanity/image-url';
import styled from "styled-components";
import { Link, useNavigate, useParams } from "react-router-dom";
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import NewFooter from '../ExternalSite/NewFooter';
import { NewHeaderLight } from '../ExternalSite/NewHeader';
import BlockContent from '@sanity/block-content-to-react';

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  text-align: left;
  margin-top: 3%;
  
  @media (max-width: 768px) {
    padding: 10px;
    margin-top: 8%;
  }
`;

const PostHeader = styled.div`
  margin-bottom: 20px;
`;

const CategoryLink = styled(Link)`
  font-size: 0.9em;
  color: #007db8;
  text-decoration: none;
  margin-right: 10px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #0A3C57;
  margin: 10px 0;
`;

const Subtitle = styled.p`
  font-size: 1.2em;
  color: #555;
  margin: 10px 0;
`;

const DateShareContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Line = styled.hr`
  border: 0;
  height: 1px;
  background: black;
  margin: 20px 0;
`;

const ShareButton = styled.button`
  background: none;
  border: none;
  color: #007db8;
  cursor: pointer;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 5px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin: 20px 0;
`;

const PostContent = styled.div`
  font-size: 1.1em;
  line-height: 1.8;
  color: #444;
`;

const MetaTags = styled.div`
  margin-top: 20px;
`;

const Tag = styled.span`
  background-color: #e0e0e0;
  color: #333;
  border-radius: 3px;
  padding: 5px 10px;
  margin-right: 5px;
  font-size: 0.9em;
`;

const RelatedPosts = styled.div`
  margin-top: 40px;
`;

const RelatedPostTitle = styled.h3`
  font-size: 1.5em;
  color: #00457c;
  margin-bottom: 20px;
`;

const RelatedPostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const RelatedPost = styled.div`
  flex: 1 1 calc(33% - 20px);
  box-sizing: border-box;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  max-width: 300px;

  @media (max-width: 1024px) {
    flex: 1 1 calc(50% - 20px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }
`;

const RelatedPostImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const RelatedPostTitleLinkContainer = styled.div`
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 1em;
  margin: auto;
  text-align: left;
  height: 100%;
`;

const RelatedPostTitleLink = styled(Link)`
  color: #00457c;
  text-decoration: none;
  font-weight: bold;
  
  &:hover {
    text-decoration: underline;
  }
`;

const RelatedPostMeta = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.9em;
  color: #555;
  margin-top: auto;
`;

const BackButton = styled(Link)`
  margin-top: 20px;
  display: inline-block;
  background: none;
  border: none;
  color: #007db8;
  cursor: pointer;
  font-size: 1em;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

// Create a builder for image URLs
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

// Fetch content with GROQ. Explicitly point out what attributes we want for the current post.
async function getPost(postId) {
  const CONTENT_QUERY = `*[_type == "post" && _id == $postId && !(_id in path('drafts.**'))] {
    ...,
    author->{
      name,
      _id
    },
    mainImage {
      asset->{
        _id,
        url
      },
      alt
    },
    categories[]-> {
      title,
      _id
    },
    body[]{
      ...,
      _type == 'image' => {
        ...,
        asset->{
          _id,
          url
        }
      }
    },
    publishedAt,
    readTime,
    tags
  }[0]`

  try {
    const post = await client.fetch(CONTENT_QUERY, { postId });
    console.log('post: ', post);
    return post;
  } catch (error) {
    console.error('Error fetching post:', error);
    return null;
  }
}

// Explicitly point out what attributes we want for the related posts.
async function getRelatedPosts() {
  const CONTENT_QUERY = `*[_type == "post" && !(_id in path('drafts.**')) && publishedAt < now()] | order(publishedAt desc)[0...3] {
    ...,
    mainImage {
      asset->{
        _id,
        url
      }
    },
    categories[]->{
      title,
      _id
    },
    publishedAt,
    title,
    _id
  }`
  const relatedPosts = await client.fetch(CONTENT_QUERY);
  console.log(relatedPosts);
  return relatedPosts;
}

// Set up serializers to customize all attributes we want to get from sanity, including embedded pictures, bold characters, hyperlink, etc.
const serializers = {
  types: {
    block: (props) => {
      // Render different blocks using different formats.
      switch (props.node.style) {
        case 'h1':
          return <h1>{props.children}</h1>;
        case 'h2':
          return <h2>{props.children}</h2>;
        case 'strong':
          return <strong>{props.children}</strong>;
        default:
          return <p>{props.children}</p>;
      }
    },
    image: ({ node }) => {
      return (
        <img
          src={urlFor(node).url()}
          alt={node.alt || ' '}
          style={{ width: '100%' }}
        />
      );
    }
  },
  marks: {
    link: ({ mark, children }) => {
      return <a href={mark.href}>{children}</a>;
    }
  }
};

function ArticlePage() {
  const navigate = useNavigate();
  const headerRef = useRef(null);
  const footerRef = useRef(null);
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [loading, setLoading] = useState(true);
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);

  useEffect(() => {
    const updateHeight = () => {
      if (headerRef.current && footerRef.current) {
        const headerHeight = headerRef.current.offsetHeight;
        const footerHeight = footerRef.current.offsetHeight;
        setCalculatedHeight(headerHeight + footerHeight);
        setLoading(false);
      }
    };
    updateHeight();

  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      const fetchedPost = await getPost(postId);
      setPost(fetchedPost);
    };
    fetchPost();

    const fetchRelatedPosts = async () => {
      const fetchedRelatedPosts = await getRelatedPosts();
      setRelatedPosts(fetchedRelatedPosts);
    };
    fetchRelatedPosts();
  }, [postId]);

  if (!post) return <Spinner />;

  return (
    <>
      <NewHeaderLight />
      <MainContainer>
        <PostHeader>
          {/* The current post's category will be shown here, which is clickable and can navigate users to /blog, and all thumbnails shown will be in the same category with this post. */}
          {post.categories && post.categories.length > 0 && (
            <div>
              {post.categories.map(category => (
                <CategoryLink key={category._id} to={`/blog?category=${category._id}`}>
                  {category.title}
                </CategoryLink>
              ))}
            </div>
          )}
          <Title>{post.title}</Title>
          {post.subtitle && <Subtitle>{post.subtitle}</Subtitle>}
          <DateShareContainer>
            <span>{new Date(post.publishedAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}</span>
            <a href="https://www.linkedin.com/">
              <ShareButton>
                <FontAwesomeIcon icon={faShareAlt} />
                Share
              </ShareButton>
            </a>
          </DateShareContainer>
        </PostHeader>
        {post.mainImage && (
          <PostImage src={urlFor(post.mainImage.asset).url()} alt={post.title} />
        )}
        <PostContent>
          {post.body && (
            <BlockContent
              blocks={post.body}
              serializers={serializers}
              projectId={client.config().projectId}
              dataset={client.config().dataset}
            />
          )}
        </PostContent>
        <div style={{ marginTop: '4%' }}>
          {post.author && (
            <span>By {post.author.name} | {new Date(post.publishedAt).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}</span>
          )}
        </div>
        {post.tags && (
          <MetaTags>
            {post.tags.map(tag => <Tag key={tag}>{tag}</Tag>)}
          </MetaTags>
        )}
        <Line />
        <RelatedPosts>
          <RelatedPostTitle>Related</RelatedPostTitle>
          <RelatedPostContainer>
            {/* Related posts' thumbnails. The category is also clickable and can achieve the same effect. */}
            {relatedPosts.map((relatedPost) => {
              const hasCategory = relatedPost.categories && relatedPost.categories.length > 0;
              return (
                <RelatedPost key={relatedPost._id}>
                  <Link to={`/blog/${relatedPost._id}`}>
                    {relatedPost.mainImage ? (
                      <RelatedPostImage src={urlFor(relatedPost.mainImage.asset).url()} alt={relatedPost.title} />
                    ) : (
                      <RelatedPostImage src="../../../../images/background_beach_sky.jpeg" alt="Default Image" />
                    )}
                  </Link>
                  <RelatedPostTitleLinkContainer>
                    <RelatedPostTitleLink to={`/blog/${relatedPost._id}`}>
                      {relatedPost.title}
                    </RelatedPostTitleLink>
                    <RelatedPostMeta>
                      <span style={{ color: '#00457c' }}>
                        {new Date(relatedPost.publishedAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </span>
                      {hasCategory && (
                        <>
                          <span style={{ margin: '0 5px' }}>|</span>
                          <CategoryLink to={`/blog?category=${relatedPost.categories[0]?._id}`}>
                            {relatedPost.categories[0]?.title || 'No Category'}
                          </CategoryLink>
                        </>
                      )}
                    </RelatedPostMeta>
                  </RelatedPostTitleLinkContainer>
                </RelatedPost>
              );
            })}
          </RelatedPostContainer>
          <Line style={{ marginTop: '2%' }} />
        </RelatedPosts>
        <BackButton to="/blog">&lt; Back</BackButton>
      </MainContainer>
      <NewFooter />
    </>
  );
}

export default ArticlePage;
