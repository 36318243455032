import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const MapContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 20px;
`;

const ProjectLocationMap = ({ projectLocation }) => {
    const [map, setMap] = useState(null);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [isLoading, setIsLoading] = useState(true);
  
    const mapContainerStyle = {
      width: '100%',
      height: '100%',
    };
  
    const onLoad = useCallback((map) => {
      setMap(map);
    }, []);
  
    const onUnmount = useCallback(() => {
      setMap(null);
    }, []);
  
    const geocodeAddress = () => {
      if (map && projectLocation) {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ address: projectLocation }, (results, status) => {
          if (status === 'OK') {
            const { lat, lng } = results[0].geometry.location;
            setCenter({ lat: lat(), lng: lng() });
            map.panTo({ lat: lat(), lng: lng() });
            setIsLoading(false);
          } else {
            console.error('Geocode was not successful for the following reason: ' + status);
            setIsLoading(false);
          }
        });
      }
    };
  
    React.useEffect(() => {
      if (map) {
        geocodeAddress();
      }
    }, [map, projectLocation]);
  
    return (
      <MapContainer>
        <LoadScript googleMapsApiKey="AIzaSyCMXFxk5EumfknRUVk0VQGrgABsIuAih3Y">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={10}
            center={center}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            {!isLoading && <Marker position={center} />}
          </GoogleMap>
        </LoadScript>
        {isLoading && <div>Loading map...</div>}
      </MapContainer>
    );
  };

  export default ProjectLocationMap;