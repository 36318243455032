function formatNumberDollars(number) {
    if (typeof number === 'string' && number.includes('USD')) {
        number = number.replace('USD', '').trim();
      }

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  }

function format2Decimals(number){

    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }).format(number);
}

export { formatNumberDollars, format2Decimals };