import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { FaArrowLeft, FaCubes } from 'react-icons/fa';
import LandingBodyTabsBuyer from './LandingBodyTabsBuyer';
import CarbonRemovedProgressPie from '../CoreProductFrontEndSeller/GraphSourcing/CarbonRemovalPieChart';
import AvailableCreditsFooter from './UniversalFcnsBuyerFE/AvailableCreditsScroll';
import { formatNumberDollars, format2Decimals } from '../../../UniversalFunctions/Numeric/Format2Decimal';
import { PageContainer, MainContent, PageHeader } from '../../../UI/Library/PageStructure';
import { Title, Subtitle, HelpLink, ContractCard, BackButton, TitleRow, TitleAndStatus, ContractStatus, ContractGrid, InfoItem, InfoLabel, InfoValue, ContractInfoValue } from '../../../UI/Library/SellerComponents';
import { Timeline } from '../../../UI/Library/SellerFunctions';


const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 1rem;
`;

const ContractTitle = styled.h1`
  font-size: 1.75rem;
  color: #0A3C57;
  margin: 0;
  font-weight: 600;
`;

const Card = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);

  &:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
`;

const CardTitle = styled.h2`
  font-size: 1.25rem;
  color: #345D72;
  margin-top: 0;
  margin-bottom: 1rem;
  font-weight: 600;
`;

const InfoGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// const InfoLabel = styled.span`
//   font-size: 0.875rem;
//   color: #5F7D95;
//   font-weight: 500;
// `;

// const InfoValue = styled.span`
//   font-size: 0.875rem;
//   color: #345D72;
//   font-weight: 400;
//   text-align: right;
// `;

const ProgressSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ProgressChart = styled.div`
  width: 300px;
  height: 200px; // Adjust this value as needed
  margin-left: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProgressDetails = styled.div`
  flex: 1;
  margin-left: 2rem;
`;

const ProgressItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const ProgressLabel = styled.span`
  font-size: 0.875rem;
  color: #345D72;
  font-weight: bold;
`;

const ProgressValue = styled.span`
  font-size: 1rem;
  color: #345D72;
  font-weight: 500;
`;

const ProgressBarContainer = styled.div`
  background-color: #E9ECEF;
  border-radius: 4px;
  height: 8px;
  margin-top: 0.5rem;
  overflow: hidden;
`;

const ProgressBarFill = styled.div`
  background-color: #1EB1B5;
  height: 100%;
  width: ${props => props.progress}%;
  transition: width 0.3s ease-in-out;
`;

const LastUpdated = styled.span`
  font-size: 0.75rem;
  color: #6C757D;
  display: block;
  margin-top: 0.5rem;
`;

const InfoTimelineCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-right: 2%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const InfoSection = styled.div`
  margin: auto 0;

  @media (max-width: 800px) {
    margin-top: 5%;
  }
`;

const ProgressRemovalsCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  background-color: #f8f9fa;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f8f9fa;
  }
`;

const TableHeader = styled.th`
  padding: 0.75rem;
  text-align: left;
  font-weight: 600;
  color: #333;
  font-size: 0.875rem;
`;

const TableCell = styled.td`
  padding: 0.75rem;
  color: #495057;
  font-size: 0.875rem;
`;

const RemovalsTableContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;

const formatDateTime = (dateString) => {
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }
    return format(date, 'MMM dd, yyyy HH:mm:ss');
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Invalid Date';
  }
};

const dummyRemovals = [
  { dateTime: '2024/05/05 09:00:00 PDT', removed: 50.1, transactionId: '0x6108990B17222958731...' },
  { dateTime: '2024/05/06 09:00:00 PDT', removed: 50.1, transactionId: '0x6108990B17222958731...' },
  { dateTime: '2024/05/09 09:00:00 PDT', removed: 50.1, transactionId: '0x6108990B17222958731...' },
  { dateTime: '2024/05/10 09:00:00 PDT', removed: 50.1, transactionId: '0x6108990B17222958731...' },
];

function ContractIndividualBuyer() {
  const { contractName } = useParams();
  const [contract, setContract] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token');

  const timelineEvents = [
    { name: 'Created', date: contract?.addedAt || null },
    { name: 'Initialized', date: contract?.addedAt || null },
    { name: 'Last Updated', date: contract?.modifiedAt || null },
    { name: 'Completed', date: contract?.completionTime || null },
  ];

  useEffect(() => {
    const fetchContract = async () => {
      try {

        const response = await axios.get(
            "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/user_contract",
            {
              params: {contract_name: contractName, username: localStorage.getItem("user")},
              headers: {Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`}
            }
        );
        console.log("here", response.data.data)
        setContract(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching contract:", error);
        setLoading(false);
      }
    };
    fetchContract();
  }, [contractName, accessToken]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!contract) {
    return <div>Contract not found</div>;
  }

  const progress = (contract.carbonRemoved / contract.tonsToCapture) * 100;

  return (
    <PageContainer style={{backgroundColor: '#E4E8E8'}}>
      <LandingBodyTabsBuyer />
      <MainContent>
        <PageHeader>
          <div>
            <Title>Contracts</Title>
            <Subtitle>Access and manage your contracts</Subtitle>
          </div>
          <HelpLink href="#">Help?</HelpLink>
        </PageHeader>

        <ContractCard
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <ContractHeader>
            <div style={{textAlign: 'left', width: '100%'}}>
            <BackButton to="/Contract-History-Buyer">
              <FaArrowLeft /> Back
            </BackButton>
            <TitleRow>
              <TitleAndStatus>
                <ContractTitle>{contract.contractName}</ContractTitle>
                <div style={{alignContent: 'center'}}>
                <ContractStatus status={contract.Status}>{contract.Status}</ContractStatus>
                </div>
              </TitleAndStatus>
              <LastUpdated>Last Updated: {formatDateTime(contract.modifiedAt)}</LastUpdated>
              </TitleRow>
            </div>
          </ContractHeader>
          
          
          <ContractGrid>
            <InfoTimelineCard>
              <CardTitle>Contract Information</CardTitle>
              <Timeline events={timelineEvents} />
              <InfoSection>
                <InfoGrid>
                <InfoItem>
                    <InfoLabel>Contract ID</InfoLabel>
                    <ContractInfoValue style={{width: 'fit-content', display: 'inline'}}  onClick={() => window.open(`https://hashscan.io/testnet/contract/${contract.contract_hedera}`, '_blank')}>{contract.contract_hedera} <FaCubes style={{color: '#568893'}}/></ContractInfoValue>
                  </InfoItem>
                  <InfoItem>
                    <InfoLabel>Project</InfoLabel>
                    <InfoValue>{contract.contractProject?.project_name || 'N/A'}</InfoValue>
                  </InfoItem>
                  <InfoItem>
                    <InfoLabel>Company</InfoLabel>
                    <InfoValue>{contract.company}</InfoValue>
                  </InfoItem>
                  <InfoItem>
                    <InfoLabel>Price / Ton</InfoLabel>
                    <InfoValue>${parseFloat(contract["priceTonUSD"]).toFixed(2)}</InfoValue>
                  </InfoItem>
                  <InfoItem>
                    <InfoLabel>Revenue</InfoLabel>
                    <InfoValue>${parseFloat(contract.Revenue).toFixed(2)}</InfoValue>
                  </InfoItem>
                  <InfoItem>
                    <InfoLabel>Duration</InfoLabel>
                    <InfoValue>{formatDateTime(contract["addedAt"])} - {formatDateTime(contract.modifiedAt)}</InfoValue>
                  </InfoItem>
                </InfoGrid>
              </InfoSection>
            </InfoTimelineCard>
            <ProgressRemovalsCard>
            
            <Card style={{paddingBottom: '2%', paddingLeft: '0'}}>
              <CardTitle style={{paddingLeft: '2rem'}}>Contract Progress</CardTitle>
                <ProgressSection>
                  <ProgressChart>
                    <CarbonRemovedProgressPie
                      data={[
                        { name: "CO2 Removed", value: contract.carbonRemoved, fill: "#1EB1B5" },
                        { name: "Remaining", value: contract.tonsToCapture - contract.carbonRemoved, fill: "#E4E8E8" },
                      ]}
                    />
                  </ProgressChart>
                  <ProgressDetails>
                    <ProgressItem>
                      <ProgressLabel>Total Progress:</ProgressLabel>
                      <ProgressValue>{progress.toFixed(2)}%</ProgressValue>
                    </ProgressItem>
                    <ProgressItem style={{ paddingBottom: '7.5%', borderBottom: "1px solid #568893"}}>
                      <ProgressLabel>Delivered:</ProgressLabel>
                      <ProgressValue>{(contract.carbonRemoved).toFixed(2)} t</ProgressValue>
                    </ProgressItem>
                    <ProgressItem style={{ paddingTop: '7.5%'}}>
                      <ProgressLabel>Remaining:</ProgressLabel>
                      <ProgressValue>{(contract.tonsToCapture - contract.carbonRemoved).toFixed(2)} t</ProgressValue>
                    </ProgressItem>
                    <ProgressBarContainer>
                      <ProgressBarFill progress={progress} />
                    </ProgressBarContainer>
                  </ProgressDetails>
                </ProgressSection>
            </Card>
            <Card>
                <CardTitle>Removals</CardTitle>
                <Subtitle>This is example data. Begin uploading your deliveries to track them here.</Subtitle>
                <RemovalsTableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeader>Date/Time</TableHeader>
                        <TableHeader>Removed</TableHeader>
                        <TableHeader>Transaction ID</TableHeader>
                      </TableRow>
                    </TableHead>
                    <tbody>
                      {dummyRemovals.map((removal, index) => (
                        <TableRow key={index}>
                          <TableCell>{formatDateTime(removal.dateTime)}</TableCell>
                          <TableCell>{removal.removed.toFixed(1)} t</TableCell>
                          <TableCell>{removal.transactionId}</TableCell>
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </RemovalsTableContainer>
              </Card>
            </ProgressRemovalsCard>
          </ContractGrid>
        </ContractCard>

        
        <AvailableCreditsFooter />
      </MainContent>
    </PageContainer>
  );
}

export default ContractIndividualBuyer;

