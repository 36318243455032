import React, { useState } from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaTwitter } from 'react-icons/fa';
import { Form, Input, Button as BaseButton, Label } from './StyledComponents';
import LoadingGameOverlay from './LoadingGameOverlay';
import TextFormatter from './TextFormatter';

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Button = styled(BaseButton)`
  background-color: #007bff;
  color: white;
  border: none;
  margin: 5px;
  // padding: 5px 10px;
  // border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  &:hover {
    background-color: #0056b3;
  }
`;

const ToggleFormButton = styled(Button)`
  margin-right: 16px;
`;

const FormSection = styled.div`
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  display: ${props => props.$visible ? 'block' : 'none'};
`;

const PromptInfo = styled.div`
  width: 100%;
  background-color: #e8f5e9;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid #81C784;
`;

const PromptDetail = styled.p`
  margin: 5px 0;
  font-size: 0.95em;
  color: #2e7d32;
`;

const OutputContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const AgentOutput = styled.div`
  margin-top: 20px;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
  position: relative;
`;

const AgentTitle = styled.h3`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 8px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const SocialLink = styled.a`
  margin-left: 10px;
  color: #0a66c2;
  &:hover {
    opacity: 0.7;
  }
`;

const NewPromptForm = styled(Form)`
  max-width: 100%;
  margin: 0;
  background-color: white;
`;

const OutputText = styled.div`
  font-family: system-ui, -apple-system, sans-serif;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  position: relative;
`;

const OutputDisplay = ({ output, onBack, onSubmit, inputData }) => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    companyName: '',
    topic: ''
  });
  const [loading, setLoading] = useState(false);

  //sorting agent output to make linkedin post first
  const sortAgentOutput = (tasks) => {
    const orderMap = {
      "LinkedIn Post Creator": 1,
      "Blog Writer": 2,
      "Topic Researcher": 3
    };
  
    return [...tasks].sort((a, b) => {
      const orderA = orderMap[a.agent] || Infinity;
      const orderB = orderMap[b.agent] || Infinity;
      return orderA - orderB;
    });
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await onSubmit({
        website_url: formData.companyName,
        topic: formData.topic
      });
    } finally {
      setLoading(false);
      setShowForm(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    alert('Text copied to clipboard!');
  };

  const openLinkedInDraft = (text) => {
    copyToClipboard(text);
    window.open("https://www.linkedin.com/sharing/share-offsite/?url=", "_blank");
  };

  const renderSocialLink = (agent) => {
    if (agent.includes('LinkedIn')) {
      return (
        <SocialLink href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
          <FaLinkedin size={20} />
        </SocialLink>
      );
    } else if (agent.includes('Twitter') || agent.includes('X ')) {
      return (
        <SocialLink href="https://www.x.com" target="_blank" rel="noopener noreferrer">
          <FaTwitter size={20} />
        </SocialLink>
      );
    }
    return null;
  };

  return (
    <Wrapper>
      
      <TopBar>
        <div>
          <Button $secondary onClick={onBack}>Back to Start</Button>
          <ToggleFormButton onClick={() => {
            setShowForm(!showForm);
            }}>
            {showForm ? 'Hide Form' : 'New Prompt'}
          </ToggleFormButton>
        </div>
      </TopBar>

      <FormSection $visible={showForm}>
      {loading && <LoadingGameOverlay />}
        <NewPromptForm onSubmit={handleSubmit}>
          <Label htmlFor="companyName">Website URL:</Label>
          <Input
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            placeholder="Enter your company website..."
            required
            disabled={loading}
          />

          <Label htmlFor="topic">Topic:</Label>
          <Input
            id="topic"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            placeholder="Enter the topic..."
            required
            disabled={loading}
          />

          <Button type="submit" disabled={loading}>
            {loading ? 'Processing...' : 'Run Again'}
          </Button>
        </NewPromptForm>
      </FormSection>

      <OutputContainer>
        <PromptInfo>
          <PromptDetail><strong>Website URL:</strong> {inputData?.website_url}</PromptDetail>
          <PromptDetail><strong>Topic:</strong> {inputData?.topic}</PromptDetail>
        </PromptInfo>

          {output.tasks_output &&
            Array.isArray(output.tasks_output) &&
            sortAgentOutput(output.tasks_output).map((task, index) => (
              <AgentOutput key={index}>
                <AgentTitle>
                  {task.agent} Output:
                  {renderSocialLink(task.agent)}
                </AgentTitle>
                <ButtonGroup>
                  <Button $secondary onClick={() => copyToClipboard(task.raw)}>Copy</Button>
                  {(task.agent === "LinkedIn Post Creator" || task.agent === "Blog Writer") && (
                    <Button onClick={() => openLinkedInDraft(task.raw)}>Post to LinkedIn</Button>
                  )}
                </ButtonGroup>
                <OutputText>
                  <TextFormatter text={task.raw} />
                </OutputText>
              </AgentOutput>
            ))}

      </OutputContainer>
    </Wrapper>
  );
};

export default OutputDisplay;