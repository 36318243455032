import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, LineController } from 'chart.js';
import Chart from 'chart.js/auto';
import styled from 'styled-components';

const HeaderTitle = styled.h2`
  font-size: 1.5em;
  color: #325d79ff;
//   margin-bottom: 5%;
  font-weight: bold;
  margin-top: 5%;
  padding-top: 5%;
  border-top: 1px solid #325d7980;
`;

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, LineController);

const ContractsRadarChart = ({ contracts, averageMarketPrice }) => {
  // Prepare data for radar chart
  const labels = [
    'Efficiency (Percent Completed)', 
    'Financial Performance', 
    'Cost-Effectiveness (Affordability)', 
    'Completion Rate'
  ];
  const data = {
    labels,
    datasets: Object.values(contracts).map(contract => ({
      label: contract.contractName,
      data: [
        (contract.carbonRemoved / contract.tonsToCapture) * 100, // Efficiency
        parseInt(contract.Revenue.replace(' USD', '')) / (contract.tonsToCapture * contract.priceTonUSD), // Financial Performance
        100 - (contract.priceTonUSD / averageMarketPrice) * 100, // Cost-Effectiveness (Assuming lower price is better, adjust as needed)
        contract.Status === 'In Progress' ? (contract.carbonRemoved / contract.tonsToCapture) * 100 : 100, // Completion Rate
      ],
      fill: true,
      backgroundColor: `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 0.2)`,
      borderColor: `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 1)`,
      pointBackgroundColor: `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 1)`,
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: `rgba(${Math.random()*255}, ${Math.random()*255}, ${Math.random()*255}, 1)`,
    })),
  };

  const options = {
    elements: {
      line: {
        borderWidth: 3,
      },
    },
    layout: {
      // Adjust padding as needed to reduce space around the chart
    //   padding: {
    //     top: 10, // Reduces space at the top of the chart
    //     bottom: 20, // Adjust bottom padding if needed
    //     left: 20, // Adjust left padding if needed
    //     right: 20, // Adjust right padding if needed
    //   },
    padding: 0,
    margin: 0,
    },
    plugins: {
      legend: {
        // Adjust legend position and padding
        position: 'top',
        labels: {
          padding: 20, // Reduces the padding around legend labels
          paddingBottom: 0,
          boxWidth: 10, // Adjust legend box width if necessary
          boxHeight: 10, // Adjust legend box height if necessary
        },
      },
      title: {
        display: true,
        text: 'Contract Performance Insights',
        padding: {
          top: 10,
          bottom: 10, // Adjust title padding to reduce space if title is enabled
        },
      },
    },
    scales: {
      r: {
        // Adjust the scale options for radar chart to control label spacing
        pointLabels: {
          padding: 10, // Adjusts the padding around the point labels (category labels)
          paddingBottom: 0,
        },
        ticks: {
          backdropPadding: 10, // Adjusts padding around scale ticks
          
        },
      },
    },
  };
  

  return (
    <div>
      <HeaderTitle>Contract Performance Insights</HeaderTitle>
      <Radar data={data} options={options} />
    </div>
  );
};

export default ContractsRadarChart;
