import React from "react";
import styled from "styled-components";
import background from "../../../../images/sunset_photo_with_person.jpg";
import Header from "../LandingHeader";
import Footer from "../LandingFooter";
import { redirect, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
const PageContainer = styled.div`
  background-color: #fff; /* Background color to match your site's design */
  text-align: center; /* Center the content */
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSidePage = styled.div`
  width: 50%;
  min-height: 90vh;
  background-image: url(${background});
  background-size: 100%;
  background-position: center;
  position: relative;
  opacity: 1;

  @media (max-width: 768px) {
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    align-content: center;
    min-height: 80vh;
    border-radius: 10px;
  }
`;

const LeftSideBlock = styled.div`
  margin: 10% 10%;

  @media (max-width: 768px) {
    margin-top: 17%;
  }
`;

const LeftSideTitle = styled.h2`
  text-align: left;
  font-size: 3rem;
  font-weight: 600;
  color: white;
`;

const LeftSideDesc = styled.p`
  font-weight: 500;
  color: white;
  font-size: 1.6rem;
  text-align: left;
`;

const RightSidePage = styled.div`
  width: 50%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  background-color: white; /* Background color for the form */
  @media (max-width: 768px) {
    width: 100%;
    min-height: 50vh;
  }
`;

const RightSidePageTop = styled.div`
  height: 65%;
`;

const AuthForm = styled.form`
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 20px;
  // border-radius: 5px;
  position: relative;
  border-bottom: 1px solid #325d7980;
  text-align: center;
  // box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @media (min-width: 768px) {
    max-width: 100%;
    padding-top: 0px;
  }
`;

const AuthDiv = styled.div`
  justify-content: center;
  margin: auto;
  width: 50%;
  border: 1px solid #325d79ff;
  border-radius: 20px;
  padding: 3% 10%;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 80%;
  }
`;
const RightSideBottomTitle = styled.h2`
  font-weight: 500;
  font-size: 1.75rem;
  color: #325d79ff;
  margin-top: 5%;
`;
const FormTitle = styled.h4`
  margin-bottom: 20px;
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #325d79ff;
  transition: all 0.5s ease-in-out;

  &:focus-within {
    color: #325d79ff;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  transition: border-color 0.3s ease-in-out;

  &:focus {
    outline: none;
    border-color: #325d79ff;
  }

  &:focus + ${FormLabel} {
    color: #325d79ff;
  }
`;

const FormButton = styled.button`
  // background-color: #325d79ff; /* Button background color */
  background-color: #325d79ff;
  color: #fff; /* Button text color */
  padding: 12px 24px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #325d7980;
    transform: scale(1.05);
  }
`;
const SuccessAlert = styled.div`
  background-color: #f3f9e8;
  color: #739e9f;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
`;
const ErrorAlert = styled.div`
  background-color: #ffebd4;
  color: #f76a1d;
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
`;
const ResetPassword = () => {
  let [searchParams, letSearchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // The reset id token (firebase id token) generated from the backend password_recovery.
  const resetToken = searchParams.get("token");
  const email = resetToken ? jwtDecode(resetToken).sub : "";

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [alert, setAlert] = useState({
    type: "",
    message: "",
  });
  const nav = useNavigate();
  const [singedIn, setSignedIn] = useState(false);

  useEffect(() => {
    if (resetToken == null) {
      nav("/login");
    }
  }, []);

  const handlePasswordUpdate = async (e) => {
    e.preventDefault();
    setSubmitDisabled(true);
    //validation here in future
    if (newPassword.length < 6) {
      setAlert({
        type: "error",
        message: "Password must be at least 6 characters",
      });
      setSubmitDisabled(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      setAlert({
        type: "error",
        message: "Passwords do not match",
      });
      setSubmitDisabled(false);
      return;
    }

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
        },
      };
      await axios
        .post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/reset_password",
          {
            password: newPassword,
            resetToken,
          },
          {
            headers: { Authorization: `Bearer ${resetToken}`}
          }
        )
        .then((res) => {
          console.log(res.data);
          setAlert({
            type: "success",
            message: "Password reset successful",
          });
        })
        .catch((err) => {
          console.log(err);
          setAlert({
            type: "error",
            message: "Password reset failed",
          });
        });
    } catch (error) {
      console.log("error resetting pass");
      setAlert({
        type: "error",
        message: "Password reset failed",
      });
    }
    setSubmitDisabled(false);
    setTimeout(() => {
      nav("/login");
    }, 2000);
    setTimeout(() => {
      setAlert({
        type: "",
        message: "",
      });
    }, 10000);
  };
  const login = async (e) => {
    e.preventDefault();
    try {
      const userData = {
        username: email,
        password: password,
      };
      console.log(userData);
      axios
        .post(
          "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/login",
          userData
        )
        .then((res) => {
          // console.log('Headers: ', res.headers);
          if (res.status === 200) {
            console.log("response: ", res);
            setSignedIn(true);
          }
        })
        .catch((error) => {
          console.log(error);
          if (
            String(error.response.data.error).includes("Username and password")
          ) {
            setAlert({
              type: "error",
              message: "Invalid username or password",
            });
          }
          if (String(error.response.data.error).includes("Invalid")) {
            setAlert({
              type: "error",
              message: "Invalid username or password",
            });
          } else {
            console.error("Error " + error.response.data.error);
            setAlert({
              type: "error",
              message: error.response.data.error,
            });
          }
        });
      setTimeout(() => {
        setAlert({
          type: "",
          message: "",
        });
      }, 10000);
    } catch (error) {
      console.error("Error " + error);
    }
  };

  return (
    <PageContainer>
      <Header />
      <MainContainer>
        <LeftSidePage>
          <LeftSideBlock>
            <LeftSideTitle>Welcome back.</LeftSideTitle>
            <LeftSideDesc>
              Pick up where you left off and keep making a difference.
            </LeftSideDesc>
          </LeftSideBlock>
        </LeftSidePage>

        <RightSidePage>
          <RightSidePageTop>
            {!singedIn && (
              <AuthForm onSubmit={login}>
                <AuthDiv>
                  <RightSideBottomTitle>Sign In</RightSideBottomTitle>
                  <FormLabel>Email</FormLabel>
                  <FormInput
                    type="email"
                    disabled
                    defaultValue={email}
                    required
                  />
                  <FormLabel>Password</FormLabel>
                  <div>
                    <FormInput
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                  <div style={{ position: "relative" }}>
                    <FormButton type="submit">Login</FormButton>
                  </div>
                  {alert.type === "success" && (
                    <SuccessAlert>
                      <p>{alert.message}</p>
                    </SuccessAlert>
                  )}
                  {alert.type === "error" && (
                    <ErrorAlert>
                      <p>{alert.message}</p>
                    </ErrorAlert>
                  )}
                </AuthDiv>
              </AuthForm>
            )}
            {singedIn && (
              <AuthForm onSubmit={handlePasswordUpdate}>
                <AuthDiv>
                  <FormTitle>Reset Password</FormTitle>
                  <FormLabel>Enter a strong password</FormLabel>
                  <FormInput
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                  <FormLabel>Confirm Password</FormLabel>
                  <FormInput
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                  <FormButton type="submit" disabled={submitDisabled}>
                    Reset Password
                  </FormButton>
                  {alert.type === "success" && (
                    <SuccessAlert>{alert.message}</SuccessAlert>
                  )}
                  {alert.type === "error" && (
                    <ErrorAlert>{alert.message}</ErrorAlert>
                  )}
                </AuthDiv>
              </AuthForm>
            )}
          </RightSidePageTop>
        </RightSidePage>
      </MainContainer>
      <Footer />
    </PageContainer>
  );
};

export default ResetPassword;

