import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';
import { StandardButton, Title as ExternalTitle } from './ExternalUILibrary/ExternalComponents';
import portfolio_management_img from '../../../../images/portfolio_management_img.png';
import screenshot_2 from '../../../../images/markets_screenshot_demo.png';
//import screenshot_2 from '../../../../images/Attempt_2.png';
import screenshot_3 from '../../../../images/insights_page.png';
import { useNavigate } from 'react-router-dom';
import saas_icon from '../../../../images/Icon-SaaS.png';
import blockchain_icon from '../../../../images/Icon-blockchain.png';

const animatedStyle = css`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(10px)')};
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
`;

const SectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 20px;
  background-color: #0A3C57;
  color: white;
  @media (max-width: 768px) {
    padding-top: 40px;
  }
`;

const Title = styled(ExternalTitle)`
  ${animatedStyle}
  color: white;
  font-size: 32px;
  margin-bottom: 40px;
`;

const FeaturesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 60px;
  max-width: 90%;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const FeatureBox = styled.div`
  background-color: #0A3C57;
  border: 2px solid white;
  border-radius: 12px;
  padding: 40px 20px;
  width: 100%;
  ${animatedStyle}

  @media (max-width: 1000px) {
    margin-bottom: 10%;
  }
`;

const FeatureColumn = styled.div`
  width: 48%;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const FeatureTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  ${animatedStyle}
`;

const FeatureIcon = styled.span`
  margin-right: 10px;
  font-size: 24px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 10%;
  font-size: 20px;
  ${animatedStyle}

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const CheckIcon = styled(FaCheckCircle)`
  color: #1EB1B5;
  margin-right: 10px;
  font-size: 20px;
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;

const Dot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#1EB1B5' : 'white'};
  margin: 0 5px;
  cursor: pointer;
`;

const PortfolioSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 90%;
  margin-top: 40px;
  overflow: hidden;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-top: 20px;
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const PortfolioContent = styled.div`
  width: 48%;
  
  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const PortfolioTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 6%;
  text-align: left;
  ${animatedStyle}
`;

const PortfolioDescription = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: left;
  width: 90%;
  ${animatedStyle}
`;

const PortfolioList = styled.ul`
  list-style: none;
  padding: 2% 4%;
  margin: 0;
`;

const PortfolioItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5%;
  font-size: 18px;
  ${animatedStyle}

  @media (max-width: 768px) {
    text-align: left;
  }
`;

const RequestDemoButton = styled(StandardButton)`
  background-color: #1EB1B5;
  margin-top: 20px;
  &:hover {
    background-color: #179fa3;
  }
`;

const FeatureHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  ${animatedStyle}

  @media (max-width: 750px) {
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
`;

const OrangeCheckIcon = styled(CheckIcon)`
  color: #FFA500;
`;

const IconContainer = styled.div`
  margin-right: 10px;
  font-size: 60px;

  @media (max-width: 750px) {
    font-size: 60px;
  }
`;

const CarouselContainer = styled.div`
  width: 48%;
  ${animatedStyle}
  position: relative;
  overflow: hidden;
  user-select: none;
  height: 400px;
  

  @media (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
`;

const SlideWrapper = styled.div`
  display: flex;
  transition: transform 0.6s ease;
  transform: translateX(${props => props.translateX}%);
`;

const Slide = styled.div`
  min-width: 100%;
  height: 100%;
`;

const SlideImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 8px;
`;

const NewFeatures = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  const images = [
    portfolio_management_img,
    screenshot_2,
    screenshot_3,
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const numItems = images.length;

  const startXRef = useRef(0);
  const isDraggingRef = useRef(false);
  const deltaXRef = useRef(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.2,
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const handleMouseDown = (e) => {
    isDraggingRef.current = true;
    startXRef.current = e.clientX;
  };

  const handleMouseMove = (e) => {
    if (!isDraggingRef.current) return;
    deltaXRef.current = e.clientX - startXRef.current;
  };

  const handleMouseUp = () => {
    if (!isDraggingRef.current) return;
    if (deltaXRef.current > 50) {
      setActiveIndex((prevIndex) => (prevIndex - 1 + numItems) % numItems);
    } else if (deltaXRef.current < -50) {
      setActiveIndex((prevIndex) => (prevIndex + 1) % numItems);
    }
    isDraggingRef.current = false;
    deltaXRef.current = 0;
  };

  const handleMouseLeave = () => {
    if (isDraggingRef.current) {
      handleMouseUp();
    }
  };

  const handleTouchStart = (e) => {
    isDraggingRef.current = true;
    startXRef.current = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (!isDraggingRef.current) return;
    deltaXRef.current = e.touches[0].clientX - startXRef.current;
  };

  const handleTouchEnd = () => {
    if (!isDraggingRef.current) return;
    if (deltaXRef.current > 50) {
      setActiveIndex((prevIndex) => (prevIndex - 1 + numItems) % numItems);
    } else if (deltaXRef.current < -50) {
      setActiveIndex((prevIndex) => (prevIndex + 1) % numItems);
    }
    isDraggingRef.current = false;
    deltaXRef.current = 0;
  };

  const handleWheel = (e) => {
    e.preventDefault();
    if (e.deltaY > 0) {
      setActiveIndex((prevIndex) => (prevIndex + 1) % numItems);
    } else {
      setActiveIndex((prevIndex) => (prevIndex - 1 + numItems) % numItems);
    }
  };

  const translateX = -activeIndex * 100;

  return (
    <SectionContainer ref={sectionRef}>
      <Title isVisible={isVisible}>Our Product Offerings</Title>

      <FeaturesWrapper>
        <FeatureColumn>
          <FeatureHeader isVisible={isVisible}>
            <IconContainer><img src={blockchain_icon} alt="Blockchain Icon" /></IconContainer>
            <FeatureTitle isVisible={isVisible}>Blockchain-Powered Marketplace</FeatureTitle>
          </FeatureHeader>
          <FeatureBox isVisible={isVisible}>
            <FeatureList>
              <FeatureItem isVisible={isVisible}><IconWrapper><CheckIcon /></IconWrapper>Competitive pricing for high-quality, durable credits</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><CheckIcon /></IconWrapper>Transparent ledger to prevent double-counting</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><CheckIcon /></IconWrapper>Financial support for innovative climate solutions</FeatureItem>
              <FeatureItem isVisible={isVisible} style={{marginBottom: '0'}}><IconWrapper><CheckIcon /></IconWrapper>Real-time smart contract fulfillment</FeatureItem>
            </FeatureList>
          </FeatureBox>
        </FeatureColumn>
        <FeatureColumn style={{marginBottom: '0'}}>
          <FeatureHeader isVisible={isVisible}>
            <IconContainer><img src={saas_icon} alt="SaaS Icon" /></IconContainer>
            <FeatureTitle isVisible={isVisible}>Data-Centric Software as a Service</FeatureTitle>
          </FeatureHeader>
          <FeatureBox isVisible={isVisible}>
            <FeatureList>
              <FeatureItem isVisible={isVisible}><IconWrapper><OrangeCheckIcon /></IconWrapper>Advanced data management and analytics</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><OrangeCheckIcon /></IconWrapper>Industry insights for benchmarking</FeatureItem>
              <FeatureItem isVisible={isVisible}><IconWrapper><OrangeCheckIcon /></IconWrapper>Robust due diligence</FeatureItem>
              <FeatureItem isVisible={isVisible} style={{marginBottom: '0'}}><IconWrapper><OrangeCheckIcon /></IconWrapper>Streamlined Monitoring, Reporting, and Verification</FeatureItem>
            </FeatureList>
          </FeatureBox>
        </FeatureColumn>
      </FeaturesWrapper>

      <Dots>
        {images.map((_, index) => (
          <Dot
            key={index}
            active={activeIndex === index}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </Dots>

      <PortfolioSection>
        <PortfolioContent>
          <FeatureTitle isVisible={isVisible}>Portfolio Management</FeatureTitle>
          <PortfolioDescription isVisible={isVisible}>
            We put data to work. By helping you make data-driven decisions, we make sure the best projects are the ones receiving funding. Our analytics suite saves you money by mitigating risk while providing forward investment in desirable climate projects.
          </PortfolioDescription>
          <PortfolioList>
            <PortfolioItem isVisible={isVisible}><CheckIcon />Internal insights and recommendations</PortfolioItem>
            <PortfolioItem isVisible={isVisible}><CheckIcon />Broader market analysis</PortfolioItem>
            <PortfolioItem isVisible={isVisible}><CheckIcon />Predictive consulting</PortfolioItem>
          </PortfolioList>
          <RequestDemoButton isVisible={isVisible} onClick={() => navigate('#contact-form')}>
            <a href="#contact-form" style={{ textDecoration: 'none', color: 'white'}}>
              Request a Demo
            </a>
          </RequestDemoButton>
        </PortfolioContent>

        <CarouselContainer
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onWheel={handleWheel}
          isVisible={isVisible}
        >
          <SlideWrapper translateX={translateX}>
            {images.map((image, index) => (
              <Slide key={index}>
                <SlideImage src={image} alt={`Carousel Image ${index + 1}`} />
              </Slide>
            ))}
          </SlideWrapper>
        </CarouselContainer>
      </PortfolioSection>
    </SectionContainer>
  );
};

export default NewFeatures;
