import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import {
  faTruckField,
  faCartShopping,
  faUser,
  faBuilding,
  faEnvelope,
  faBullseye,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IndivProfile = styled.div`
  position: relative;
  width: 20vw;
  max-width: 500px;
  border: 1px solid #325d7980;
  box-shadow: 0 1px 1px rgba(23, 43, 77, 0.2), 0 0 1px rgba(23, 43, 77, 0.2);
  border-radius: 4px;
  background-color: white;
  padding: 4%;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 60vw;
  }
`;

const AdditionalDetailsDiv = styled.div`
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  padding-top: 2vh;
  white-space: nowrap;
  flex-wrap: wrap;
  min-width: 100%;
`;

const AdditionalDetailsText = styled.p`
  color: #5f7d95ff;
  font-size: 1em;
  // border-bottom: 1px solid #eee;
  padding-bottom: 1%;
  text-align: left;
  width: 100%;
`;

const HeaderDiv = styled.div`
  padding: 2px 4px;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: "pink";
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const iconStyle = {
  width: "2vw",
  height: "2vh",
  color: "#324a75",
};

const IconLink = styled.a`
  width: 2vw;
  height: 2vh;
  color: #324a75;
  border-radius: 50%;
  cursor: pointer;
  :hover {
    color: #1f64e4;
  }
`;

const ItemDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px;
`;
const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover > ul {
    display: block;
  }
`;

const DropdownMenu = styled.ul`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  width: 20vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  padding: 3% 3%;

  @media (max-width: 800px) {
    min-width: 80px;
  }
`;
const DropdownItem = styled.li`
  color: black;
  padding: 4%;
  text-decoration: none;
  display: block;
  text-align: left;
  list-style-type: none;
  font-size: 0.5rem;

  &:hover {
    background-color: #f1f1f1;
  }
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const DropDownComponent = ({ setConfirmModal, containerId, idx, item }) => {
  const containerIds = [
    "Awaiting Review",
    "Review In Progress",
    "Completed",
    "Trash",
  ];
  return (
    <ItemDiv
      style={{ gridRowStart: 4, gridColumnStart: 1, justifySelf: "flex-end" }}
    >
      <DropdownContainer>
        <FontAwesomeIcon icon={faEllipsisH} style={iconStyle} />
        <DropdownMenu>
          {containerIds.reduce((acc, container) => {
            if (container !== containerId) {
              acc.push(
                <DropdownItem
                  key={container}
                  onClick={() => setConfirmModal({ isVisible: true,
                    source: {droppableId: containerId, index: idx},
                    destination: {droppableId: container, index: 0},
                    movedItem: item
                   })}
                >
                  Move to {container}
                </DropdownItem>
              );
            }
            return acc;
          }, [])}
        </DropdownMenu>
      </DropdownContainer>
    </ItemDiv>
  );
};
const AdditionalDetails = ({
  detailValue,
  idx,
  containerId,
  setConfirmModal,
}) => {
  return (
    <>
      <HeaderDiv>
        <span style={{ color: "#7686a3", fontWeight: "lighter" }}>
          {detailValue.email}
        </span>
        {detailValue.account_type === "supplier" && (
          // route to supplier page on click
          <IconLink>
            <FontAwesomeIcon icon={faTruckField} />
          </IconLink>
        )}
        {detailValue.account_type === "purchaser" && (
          <IconLink>
            <FontAwesomeIcon icon={faCartShopping} style={iconStyle} />
          </IconLink>
        )}
        {detailValue.account_type === "general" && (
          <IconLink>
            <FontAwesomeIcon icon={faUser} style={iconStyle} />
          </IconLink>
        )}
      </HeaderDiv>
      <AdditionalDetailsDiv>
        {detailValue.account_type === "general" && (
          <>
            <ItemDiv style={{ gridColumn: "1 / 5" }}>
              <FontAwesomeIcon icon={faBuilding} style={iconStyle} />
              {detailValue.company}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "1 / 4", gridRow: "2 / 3" }}>
              <FontAwesomeIcon icon={faUser} style={iconStyle} />
              {detailValue.name}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "1 / 5", gridRow: "3 / 4" }}>
              <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
              {detailValue.message}
            </ItemDiv>
            <DropDownComponent
              setConfirmModal={setConfirmModal}
              idx={idx}
              containerId={containerId}
              item={detailValue}
            />
          </>
        )}
        {detailValue.account_type === "supplier" && (
          <>
            <ItemDiv style={{ gridColumn: "1 / 4" }}>
              <FontAwesomeIcon icon={faBuilding} style={iconStyle} />
              {detailValue.company}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "1 / 4", gridRow: "2 / 3" }}>
              <FontAwesomeIcon icon={faUser} style={iconStyle} />
              {detailValue.name}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "1 / 5", gridRow: "3 / 4" }}>
              <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
              {detailValue.message}
            </ItemDiv>
            <DropDownComponent
              idx={idx}
              containerId={containerId}
              setConfirmModal={setConfirmModal}
              item={detailValue}
            />
            <p
              style={{
                color: "#e8ebf0",
                padding: "1px 2px",
                gridRowStart: 4,
                gridColumn: "4 / 5",
                backgroundColor: "#7686a3",
                borderRadius: "5px",
                alignSelf: "flex-end",
              }}
            >
              {detailValue.contractIntent}
            </p>
          </>
        )}

        {detailValue.account_type === "purchaser" && (
          <>
            <ItemDiv style={{ gridColumn: "1 / 4" }}>
              <FontAwesomeIcon icon={faBuilding} style={iconStyle} />
              {detailValue.company}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "1 / 4" }}>
              <FontAwesomeIcon icon={faUser} style={iconStyle} />
              {detailValue.name}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "1 / 5", gridRow: "3 / 4" }}>
              <FontAwesomeIcon icon={faEnvelope} style={iconStyle} />
              {detailValue.message}
            </ItemDiv>
            <ItemDiv style={{ gridColumn: "4 / 5", gridRow: "2 / 3" }}>
              <FontAwesomeIcon icon={faBullseye} style={iconStyle} />
              {detailValue.tonsGoal}
            </ItemDiv>
            <DropDownComponent
              setConfirmModal={setConfirmModal}
              idx={idx}
              containerId={containerId}
              item={detailValue}
            />

            <p
              style={{
                color: "#e8ebf0",
                borderRadius: "5px",
                padding: "1px 2px",
                backgroundColor: `${
                  detailValue.firstTime === "yes" ? "green" : "red"
                }`,
                gridRowStart: 4,
                gridColumn: "4 / 5",
                alignSelf: "flex-end",
              }}
            >
              first time
            </p>
          </>
        )}
      </AdditionalDetailsDiv>
    </>
  );
};

const DraggableAccount = ({ data, containerId, setConfirmModal }) => {
  return data.map(({ detailKey, ...detailValue }, index) => (
    <Draggable key={detailKey} draggableId={detailKey} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={{ ...provided.draggableProps.style }}
        >
          <IndivProfile>
            {/* <UsernameName>{detailValue.email}</UsernameName> */}
            {/* <AdditionalDetails detailValue={detailValue} /> */}
            <AdditionalDetails
              detailValue={detailValue}
              setConfirmModal={setConfirmModal}
              idx={index}
              containerId={containerId}
            />
          </IndivProfile>
        </div>
      )}
    </Draggable>
  ));
};

export default DraggableAccount;
