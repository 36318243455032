import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from './LandingHeader';
import Footer from './LandingFooter';
import background from '../../../images/sunset_mountains.jpeg';
import axios from 'axios';

// Styled components
const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

const LeftHandSide = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 4%;
  width: 80%;

  @media (max-width: 768px) {
    width: 90%;
  }

`;

const RightHandSide = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  border: 1px solid #325d7980;
  border-radius: 20px;
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  position: relative;
  opacity: 1;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;


const LeftOfRightHandSide = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 50%;
  text-align: left;

  @media (max-width: 768px) {
    text-align: left;
    width: 75%;
    margin-bottom: 10%;
  }

`;

const ContactFormLeftTitle = styled.p`
  font-weight: 600;
  font-size: 2rem;
  color: white;


`;

const ContactFormLeftDesc = styled.p`
  font-weight: 400;
  font-size: 1.25rem;
  color: white;
`;

const RightOfRightHandSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
  }

`;

const StyledContactTitle = styled.h2`
  color: #325d79ff;
  font-size: 2.5rem;
  text-align: left;
  padding-bottom: 2vh;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const SelectionDiv = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SelectionButton = styled.button`

  object-fit: contain;
  padding: 5px 10px;
  margin: 0.5%;
  border: none;
  background-color: #325d79ff;
  color: white;
  cursor: pointer;
  border-radius: 20px;
  white-space: nowrap;
  &:hover {
    background-color: #5f7d95ff;
  }

  @media (max-width: 768px) {
    min-width: fit-content;
  }
`;

const SelectionButtonText = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #325d7980;
  border-radius: 4px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #325d7980;
  border-radius: 4px;
  resize: vertical;
`;

const SubmitButton = styled.button`
  width: 30%;
  background-color: #325d79ff;
  color: white;
  font-weight: 600;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  &:hover {
    background-color: #5f7d95ff;
  }
  min-width: fit-content;

`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
`;

// Form components for different types of inquiries
const GeneralInquiryForm = ({ setName, setEmail, setCompany, setMessage }) => (
  <FormContainer>
    <Input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
    <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
    <Input type="text" placeholder="Company" onChange={(e) => setCompany(e.target.value)} />
    <TextArea placeholder="Message" onChange={(e) => setMessage(e.target.value)}></TextArea>
    <SubmitButton type="submit">Submit</SubmitButton>
  </FormContainer>
);

const PurchaseForm = ({
  setName,
  setEmail,
  setCompany,
  setMessage,
  setFirstTime,
  setTonsGoal
}) => (
  <FormContainer>
    <Input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
    <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
    <Input type="text" placeholder="Company" onChange={(e) => setCompany(e.target.value)} />
    <Select onChange={(e) => setFirstTime(e.target.value)} defaultValue="yes">
      <option value="yes">Yes, I have purchased before</option>
      <option value="no">No, this is my first time</option>
    </Select>
    <Select onChange={(e) => setTonsGoal(e.target.value)} defaultValue="notSure">
      <option value="over500">Over 500 tons</option>
      <option value="under500">Under 500 tons</option>
      <option value="notSure">I'm not sure</option>
    </Select>
    <TextArea placeholder="Message" onChange={(e) => setMessage(e.target.value)}></TextArea>
    <SubmitButton type="submit">Submit</SubmitButton>
  </FormContainer>
);

const ProvideForm = ({
  setName,
  setEmail,
  setCompany,
  setMessage,
  setContractIntent
}) => (
  <FormContainer>
    <Input type="text" placeholder="Name" onChange={(e) => setName(e.target.value)} />
    <Input type="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required />
    <Input type="text" placeholder="Company" onChange={(e) => setCompany(e.target.value)} />
    <Select onChange={(e) => setContractIntent(e.target.value)} defaultValue="upload">
      <option value="upload">I am looking to upload contracts</option>
      <option value="initiate">I am looking to initiate contracts</option>
      <option value="both">Both</option>
    </Select>
    <TextArea placeholder="Message" onChange={(e) => setMessage(e.target.value)}></TextArea>
    <SubmitButton type="submit">Submit</SubmitButton>
  </FormContainer>
);

// Main contact component
function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formType, setFormType] = useState('general');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [firstTime, setFirstTime] = useState('yes'); // For PurchaseForm
  const [tonsGoal, setTonsGoal] = useState('notSure'); // For PurchaseForm
  const [contractIntent, setContractIntent] = useState('upload');
  const formRef = React.createRef();
  useEffect(() => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formType])
  
  const handleSubmit = async(e) => {
    e.preventDefault();
    const submitData = {
      email: email,
      account_type: formType,
      name: name,
      company: company,
      message: message,
      // Additional fields depending on formType
      ...(formType === 'purchaser' && { firstTime: firstTime, tonsGoal: tonsGoal }),
      ...(formType === 'supplier' && { contractIntent: contractIntent }),
      status: 'Awaiting Review'
    };

    try {
      const res = await axios.post('http://127.0.0.1:5000/apply_for_carbonsmith', submitData);
      // const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/apply_for_carbonsmith', submitData);
      if (res.status === 200) {
        console.log(`Successfully added`);
        setIsSubmitted(true);
      } else {
          console.log('Something went wrong');
      }
      console.log(submitData);

    } catch (error) {
      console.log('Error: ', error);
    }
  }

  // Function to render the selected form
  const renderForm = () => {
    switch (formType) {
      case 'purchaser':
        return <PurchaseForm setName={setName} setEmail={setEmail} setCompany={setCompany} setMessage={setMessage} setFirstTime={setFirstTime} setTonsGoal={setTonsGoal} />;
      case 'supplier':
        return <ProvideForm setName={setName} setEmail={setEmail} setCompany={setCompany} setMessage={setMessage} setContractIntent={setContractIntent} />;
      case 'general':
      default:
        return <GeneralInquiryForm setName={setName} setEmail={setEmail} setCompany={setCompany} setMessage={setMessage} />;
    }
  };

  return (
    <>
      <Header />
      <ContactContainer>
        <LeftHandSide>
          <StyledContactTitle>Get Started</StyledContactTitle>
          <SelectionDiv>
          <SelectionButton onClick={() => setFormType('purchaser')}>
            <SelectionButtonText>Interested in purchasing removals</SelectionButtonText>
          </SelectionButton>
          <SelectionButton onClick={() => setFormType('supplier')}>
            <SelectionButtonText>Interested in supplying removals</SelectionButtonText>
          </SelectionButton>
          <SelectionButton onClick={() => setFormType('general')}>
            <SelectionButtonText>General Inquiries</SelectionButtonText>
          </SelectionButton>
          </SelectionDiv>
        </LeftHandSide>
        <RightHandSide>
          {isSubmitted ? (
            <LeftOfRightHandSide style={{ marginLeft: '12%', marginTop: '10%' }}>
              <ContactFormLeftTitle>Thank you!</ContactFormLeftTitle>
              <ContactFormLeftDesc style={{ paddingBottom: '25%' }}>We will get back to you shortly.</ContactFormLeftDesc>
            </LeftOfRightHandSide>
          ): (
            <>
            <LeftOfRightHandSide>
            <ContactFormLeftTitle>
              Make a difference
            </ContactFormLeftTitle>
            <ContactFormLeftDesc>
              Whether selling, buying, or just looking for more information, you can make an impact on our planet through getting involved. 
            </ContactFormLeftDesc>
            </LeftOfRightHandSide>
            <RightOfRightHandSide>
              <form onSubmit={handleSubmit} ref={formRef}>
                {renderForm()}
              </form>
            </RightOfRightHandSide>
          </>
          )}


        </RightHandSide>
      </ContactContainer>
      <Footer />
    </>
  );
}

export default Contact;


