import React from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  // background-color: #E4E8E8;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 768px) {
    max-width: 100vw;
    margin-top: 10vh;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 8px;
`;

const BodyDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

const UpperDivMain = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2vw;
`;

const MainContent = styled.div`
  flex: 1;
  padding: 2rem 2rem 2rem 2rem;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 1%;
`;

const OverviewMainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #E4E8E8;
  padding: 40px;
  padding-top: 20px;
  overflow-y: auto;
`;

const OverviewPageContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: linear-gradient(145deg, #fff, #dbe2e9);
  height: 100%;
  width: 100%;
  @media (max-width: 768px) {
    max-width: 100vw;
    flex-direction: column;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const OverviewHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const OverviewSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export { PageContainer, MainContainer, BodyDiv, UpperDivMain, MainContent, PageHeader, OverviewMainContent, OverviewPageContainer, OverviewContainer, OverviewHeader,
  OverviewSection
 };
