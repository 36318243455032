import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler, LineController } from 'chart.js';
import Chart from 'chart.js/auto';
import styled from 'styled-components';

const HeaderTitle = styled.h2`
  font-size: 1.5em;
  color: #325d79ff;
//   margin-bottom: 5%;
  font-weight: bold;
`;

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler, LineController);

const CarbonCreditContractsGraph = ({ contracts }) => {
    // Convert object of objects into an array of contract objects
    const contractsArray = Object.values(contracts);

    // Process the contracts data
    const data = contractsArray.reduce((acc, contract) => {
        if (contract.Status === 'In Progress') {
            acc.labels.push(contract.contractName);
            acc.tonsSold.push(parseFloat(contract.tonsToCapture));
            acc.tonsFulfilled.push(parseFloat(contract.carbonRemoved));
            acc.pricePerTon.push(parseFloat(contract.priceTonUSD));
        }
        return acc;
    }, { labels: [], tonsSold: [], tonsFulfilled: [], pricePerTon: [] });

    const chartData = {
        labels: data.labels,
        datasets: [
            {
                type: 'bar',
                label: 'Tons To Capture',
                data: data.tonsSold,
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                type: 'bar',
                label: 'Carbon Removed',
                data: data.tonsFulfilled,
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
            {
                type: 'line',
                label: 'Price Per Ton ($USD)',
                data: data.pricePerTon,
                borderColor: 'rgb(255, 206, 86)',
                backgroundColor: 'rgba(255, 206, 86, 0.5)',
                fill: false,
                tension: 0.4,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Amount / Price',
                },
            },
        },
        plugins: {
            title: {
                display: true,
                text: 'Carbon Credit Contracts Overview',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            legend: {
                display: true,
            },
        },
    };

    return (
        <div>
            <HeaderTitle>Contract Progression</HeaderTitle>
            <Bar data={chartData} options={options} />
        </div>
    );
};

export default CarbonCreditContractsGraph;

