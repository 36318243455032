import React from 'react';
import styled from 'styled-components';
import { formatNumberDollars, format2Decimals } from '../../../UniversalFunctions/Numeric/Format2Decimal';
import ProgressBar from '../../../Pages/PageComponents/CoreProductFrontEndSeller/GraphSourcing/ProgressBar';
import { FiX } from 'react-icons/fi';

const SummarySectionContainer = styled.div`
  margin-bottom: 1rem;
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const SummaryHeader = styled.p`
  font-size: 1.5rem;
  color: #0A3C57;
  margin-left: 1%;
  margin-bottom: 1rem;
  font-weight: 600;
  text-align: left;
`;

const SummaryContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SummaryCard = styled.div`
  width: 37%;
  padding: 1rem;
  border: 2px solid #E4E8E8;
  border-radius: 8px;
  margin: 0.5%;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const SummaryTitle = styled.h3`
  font-size: 1rem;
  color: #345D72;
  font-weight: 600;
  text-align: left;
  margin-bottom: 1rem;
`;

const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const SummaryLabel = styled.span`
  font-size: 0.875rem;
  color: #345D72;
`;

const SummaryValue = styled.span`
  font-size: 0.875rem;
  font-weight: 600;
  color: #1D374A;
`;

const StatusSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 22%;
  margin: auto 0.5%;
`;

const StatusItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.75rem;
  white-space: nowrap;
  text-align: left;
  color: #5F7D95;
  margin-bottom: 0.75rem;
  padding: 0.5rem;
  border: 2px solid ${props => props.isActive ? '#1EB1B5' : '#E4E8E8'};
  border-radius: 8px;
  height: 2.5rem;
  transition: all 0.3s ease;
  background-color: ${props => props.isActive ? '#E6F7F8' : 'transparent'};

  &:hover {
    background-color: ${props => props.isActive ? '#E6F7F8' : '#F8F9FA'};
    cursor: pointer;
  }
`;

const StatusContent = styled.div`
  display: flex;
  align-items: center;
`;

const ClearButton = styled.button`
  background: none;
  border: none;
  color: #1EB1B5;
  font-size: 1rem;
  cursor: pointer;
  padding: 0;
  margin-left: 0.5rem;
  display: ${props => props.visible ? 'block' : 'none'};
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const StatusLabel = styled.span`
  color: #345D72;
  font-weight: 600;
  margin-right: 0.25rem;
`;

const StatusText = styled.span`
  color: #1EB1B5;
  font-weight: 600;
`;

const SummarySectionSeller = ({ contracts, onStatusClick, activeStatus, onClearStatus }) => {
  const calculateMetrics = () => {
    const metrics = {
      totalRevenue: 0,
      revenueOnDelivered: 0,
      tonsUnderContract: 0,
      totalDelivered: 0,
      completedContracts: 0,
      inProgressContracts: 0,
      uninitializedContracts: 0
    };

    contracts.forEach(contract => {
      metrics.totalRevenue += parseFloat(contract.Revenue);
      metrics.revenueOnDelivered += parseFloat(contract.carbonRemoved) * parseFloat(contract.priceTonUSD);
      metrics.tonsUnderContract += parseFloat(contract.tonsToCapture);
      metrics.totalDelivered += parseFloat(contract.carbonRemoved);
      if (contract.Status === 'Completed') metrics.completedContracts++;
      else if (contract.Status === 'In Progress') metrics.inProgressContracts++;
      else if (contract.Status === 'Uninitialized') metrics.uninitializedContracts++;
    });

    return metrics;
  };

  const metrics = calculateMetrics();

  const revenuePercent = (metrics.revenueOnDelivered / metrics.totalRevenue) * 100;
  const tonsPercent = (metrics.totalDelivered / metrics.tonsUnderContract) * 100;

  return (
    <SummarySectionContainer>
      <SummaryHeader>Summary</SummaryHeader>
      <SummaryContent>
        <SummaryCard>
          <SummaryTitle>Revenue</SummaryTitle>
          <SummaryRow>
            <SummaryLabel>Total</SummaryLabel>
            <SummaryValue>${formatNumberDollars(metrics.totalRevenue)}</SummaryValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>On Delivered</SummaryLabel>
            <SummaryValue>${formatNumberDollars(metrics.revenueOnDelivered)}</SummaryValue>
          </SummaryRow>
          <ProgressBar percent={revenuePercent} />
        </SummaryCard>
        <SummaryCard>
          <SummaryTitle>Tons</SummaryTitle>
          <SummaryRow>
            <SummaryLabel>Under Contract</SummaryLabel>
            <SummaryValue>{format2Decimals(metrics.tonsUnderContract)} t</SummaryValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>Delivered</SummaryLabel>
            <SummaryValue>{format2Decimals(metrics.totalDelivered)} t</SummaryValue>
          </SummaryRow>
          <ProgressBar percent={tonsPercent} />
        </SummaryCard>
        <StatusSection>
        <StatusItem 
            onClick={() => onStatusClick('Completed')} 
            isActive={activeStatus === 'Completed'}
          >
            <StatusContent>
              <StatusLabel>Complete</StatusLabel>
              <StatusText>• {metrics.completedContracts} contracts</StatusText>
            </StatusContent>
            <ClearButton 
              onClick={(e) => {
                e.stopPropagation();
                onClearStatus();
              }} 
              visible={activeStatus === 'Completed'}
            >
              <FiX />
            </ClearButton>
          </StatusItem>
          <StatusItem 
            onClick={() => onStatusClick('In Progress')} 
            isActive={activeStatus === 'In Progress'}
          >
            <StatusContent>
              <StatusLabel>In Progress</StatusLabel>
              <StatusText>• {metrics.inProgressContracts} contracts</StatusText>
            </StatusContent>
            <ClearButton 
              onClick={(e) => {
                e.stopPropagation();
                onClearStatus();
              }} 
              visible={activeStatus === 'In Progress'}
            >
              <FiX />
            </ClearButton>
          </StatusItem>
          <StatusItem 
            onClick={() => onStatusClick('Uninitialized')} 
            isActive={activeStatus === 'Uninitialized'}
          >
            <StatusContent>
              <StatusLabel>Uninitialized</StatusLabel>
              <StatusText>• {metrics.uninitializedContracts} contracts</StatusText>
            </StatusContent>
            <ClearButton 
              onClick={(e) => {
                e.stopPropagation();
                onClearStatus();
              }} 
              visible={activeStatus === 'Uninitialized'}
            >
              <FiX />
            </ClearButton>
          </StatusItem>
        </StatusSection>
      </SummaryContent>
    </SummarySectionContainer>
  );
};

const SummarySectionBuyer = ({ contracts, onStatusClick, activeStatus, onClearStatus }) => {
  const calculateMetrics = () => {
    const metrics = {
      totalRevenue: 0,
      revenueOnDelivered: 0,
      tonsUnderContract: 0,
      totalDelivered: 0,
      completedContracts: 0,
      inProgressContracts: 0,
      uninitializedContracts: 0
    };

    contracts.forEach(contract => {
      metrics.totalRevenue += parseFloat(contract.Revenue);
      metrics.revenueOnDelivered += parseFloat(contract.carbonRemoved) * parseFloat(contract.priceTonUSD);
      metrics.tonsUnderContract += parseFloat(contract.tonsToCapture);
      metrics.totalDelivered += parseFloat(contract.carbonRemoved);
      if (contract.Status === 'Completed') metrics.completedContracts++;
      else if (contract.Status === 'In Progress') metrics.inProgressContracts++;
      else if (contract.Status === 'Uninitialized') metrics.uninitializedContracts++;
    });

    return metrics;
  };

  const metrics = calculateMetrics();

  const revenuePercent = (metrics.revenueOnDelivered / metrics.totalRevenue) * 100;
  const tonsPercent = (metrics.totalDelivered / metrics.tonsUnderContract) * 100;

  return (
    <SummarySectionContainer>
      <SummaryHeader>Summary</SummaryHeader>
      <SummaryContent>
        <SummaryCard>
          <SummaryTitle>Credit Spend</SummaryTitle>
          <SummaryRow>
            <SummaryLabel>Total</SummaryLabel>
            <SummaryValue>${formatNumberDollars(metrics.totalRevenue)}</SummaryValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>On Delivered</SummaryLabel>
            <SummaryValue>${formatNumberDollars(metrics.revenueOnDelivered)}</SummaryValue>
          </SummaryRow>
          <ProgressBar percent={revenuePercent} />
        </SummaryCard>
        <SummaryCard>
          <SummaryTitle>Tons</SummaryTitle>
          <SummaryRow>
            <SummaryLabel>Under Contract</SummaryLabel>
            <SummaryValue>{format2Decimals(metrics.tonsUnderContract)} t</SummaryValue>
          </SummaryRow>
          <SummaryRow>
            <SummaryLabel>Delivered</SummaryLabel>
            <SummaryValue>{format2Decimals(metrics.totalDelivered)} t</SummaryValue>
          </SummaryRow>
          <ProgressBar percent={tonsPercent} />
        </SummaryCard>
        <StatusSection>
        <StatusItem 
            onClick={() => onStatusClick('Completed')} 
            isActive={activeStatus === 'Completed'}
          >
            <StatusContent>
              <StatusLabel>Complete</StatusLabel>
              <StatusText>• {metrics.completedContracts} contracts</StatusText>
            </StatusContent>
            <ClearButton 
              onClick={(e) => {
                e.stopPropagation();
                onClearStatus();
              }} 
              visible={activeStatus === 'Completed'}
            >
              <FiX />
            </ClearButton>
          </StatusItem>
          <StatusItem 
            onClick={() => onStatusClick('In Progress')} 
            isActive={activeStatus === 'In Progress'}
          >
            <StatusContent>
              <StatusLabel>In Progress</StatusLabel>
              <StatusText>• {metrics.inProgressContracts} contracts</StatusText>
            </StatusContent>
            <ClearButton 
              onClick={(e) => {
                e.stopPropagation();
                onClearStatus();
              }} 
              visible={activeStatus === 'In Progress'}
            >
              <FiX />
            </ClearButton>
          </StatusItem>
          <StatusItem 
            onClick={() => onStatusClick('Uninitialized')} 
            isActive={activeStatus === 'Uninitialized'}
          >
            <StatusContent>
              <StatusLabel>Uninitialized</StatusLabel>
              <StatusText>• {metrics.uninitializedContracts} contracts</StatusText>
            </StatusContent>
            <ClearButton 
              onClick={(e) => {
                e.stopPropagation();
                onClearStatus();
              }} 
              visible={activeStatus === 'Uninitialized'}
            >
              <FiX />
            </ClearButton>
          </StatusItem>
        </StatusSection>
      </SummaryContent>
    </SummarySectionContainer>
  );
};


export { SummarySectionSeller, SummarySectionBuyer };