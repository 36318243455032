import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'

// Update frontend firebase initialization's information.
const firebaseConfig = {
  apiKey: "AIzaSyBezu9eW5jyEv98-J6xKSUvOeKG_wNOs18",
  authDomain: "carbonsmith-3c9a5.firebaseapp.com",
  databaseURL: "https://carbonsmith-3c9a5-default-rtdb.firebaseio.com",
  projectId: "carbonsmith-3c9a5",
  storageBucket: "carbonsmith-3c9a5.appspot.com",
  messagingSenderId: "316212251926",
  appId: "1:316212251926:web:3ab5d55de21801b8fe1437",
  measurementId: "G-YHX4PMFT2W"
};


const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export { auth };
