// src/components/StepOne.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Form,
  Label,
  Input,
  Button as BaseButton,
  FormContainer,
} from './StyledComponents';
import styled from 'styled-components';
import LoadingGameOverlay from './LoadingGameOverlay';

const Button = styled(BaseButton)`
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 0.9em;
  &:hover {
    background-color: #0056b3;
  }
`;

const UsageInfo = styled.div`
  margin-top: 20px;
  text-align: center;
  p {
    margin: 0;
    font-size: 1em;
  }
`;

const StepOne = ({ formData, handleChange, handleOutput }) => {
  const [loading, setLoading] = useState(false);
  const [canMakeRequest, setCanMakeRequest] = useState(true);
  const [usageData, setUsageData] = useState(null);

  useEffect(() => {
    checkUsage();
  }, []);

  const checkUsage = async () => {
    try {
        //const response = await axios.get('http://127.0.0.1:8000/check-usage');
      const response = await axios.get('https://marketing-llm-316212251926.us-west2.run.app/check-usage');
      setCanMakeRequest(response.data.can_make_request);
      setUsageData(response.data);
    } catch (error) {
      console.error('Error checking usage:', error);
      setCanMakeRequest(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('initiating request');

    // Check usage before submitting
    try {
        //const usageResponse = await axios.get('http://127.0.0.1:8000/check-usage');
      const usageResponse = await axios.get('https://marketing-llm-316212251926.us-west2.run.app/check-usage');
      if (!usageResponse.data.can_make_request) {
        alert('You have reached your request limit. Please try again later.');
        setCanMakeRequest(false);
        return;
      }
    } catch (error) {
      console.error('Error checking usage:', error);
      alert('Unable to verify usage. Please try again.');
      return;
    }

    setLoading(true);

    // Prepare the request payload
    const payload = {
      website_url: formData.companyName,
      topic: formData.topic,
    };

    try {
        //const response = await axios.post('http://127.0.0.1:8000/process', payload, {
      const response = await axios.post('https://marketing-llm-316212251926.us-west2.run.app/process', payload, {
        headers: { 'Content-Type': 'application/json' },
      });

      console.log('Response data:', response.data);

      // Pass the entire response data back to the parent component
      handleOutput(response.data);
      await checkUsage(); // Update usage data after successful request
    } catch (error) {
      if (error.response && error.response.status === 429) {
        alert('Rate limit exceeded. Please try again later.');
      } else {
        console.error('Error:', error);
        alert('An error occurred while processing your request.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingGameOverlay />}
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Label htmlFor="companyName">Website URL:</Label>
          <Input
            id="companyName"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            placeholder="Enter your company website..."
            required
            disabled={!canMakeRequest || loading}
          />

          <Label htmlFor="topic">Topic:</Label>
          <Input
            id="topic"
            name="topic"
            value={formData.topic}
            onChange={handleChange}
            placeholder="Enter the topic..."
            required
            disabled={!canMakeRequest || loading}
          />

          <Button type="submit" disabled={!canMakeRequest || loading}>
            {loading ? 'Processing...' : 'Submit'}
          </Button>
        </Form>
      </FormContainer>

      {usageData && (
        <UsageInfo>
          {!canMakeRequest ? (
            <p style={{ color: 'red' }}>
              Oops! You've reached your daily limit of {usageData.limit} requests.
              Please contact impact@carbonsmith.io to receive more.
            </p>
          ) : (
            <p>
              🎉 You have <strong>{usageData.remaining_calls}</strong> out of{' '}
              <strong>{usageData.limit}</strong> requests left. Keep exploring!
            </p>
          )}
        </UsageInfo>
      )}
    </>
  );
};

export default StepOne;
