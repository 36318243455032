import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../UniversalFunctions/AuthProvider';
import logofinal from '../../../images/carbonsmith_logo_final.jpg';
// import { useNavigate } from 'react-router';
// import { useAuth, useAuthInitialization, AuthProvider } from '../../UniversalFunctions/AuthProvider';

const HeaderContainer = styled.header`
  // background-color: #00796B;
  background-color: white;
  // color: white;
  padding: 2% 0%;
  font-family: 'Roboto', sans-serif;

  @media (min-width: 768px) {
    /* Apply styles for screens with a minimum width of 768px (web version) */
    // background-color: #00796B;
    padding: 1% 5%; /* Adjust padding for web version */
  }
`;

const Logo = styled.div`
  background-image: url(${logofinal});
  width: 150px;
  height: 100px;
  background-size: 45%;
  background-repeat: no-repeat;
  background-position: left;
  align-content: left;
  // width: 25%;
  // min-height: 25%;
  font-size: 16px;
  font-weight: bold;
  // color: #325d79ff;
//   padding: 1% 1%;

  @media (max-width: 600px) {
    width: 100px;
    background-size: 100%;
    background-position: center;
    margin-left: 2.5%;
  }

  @media (min-width: 800px) {
    background-size: 80%;
    width: 150px;
    font-size: 20px;
  }

  @media (min-width: 1000px) {
    // margin-right: 100px;
    width: 300px;
    background-size: 66%;
    // height: 150px;
  }
  @media (min-width: 1100px) {
    font-size: 24px;
    font-weight: bold;
    // margin-right: 100px;
    background-size: 45%;
  }

  @media (min-width: 1400px) {
    background-size: 48%;
  }


`;

const CompanyTitle = styled.h1`
  color: black;
  // color: #325d79ff;
`;

const Navbar = styled.nav`
  display: flex;
  max-width: 100%;
  margin: 0 auto;
  // justify-content: space-between;

  @media (min-width: 768px) {
    /* Apply styles for web version */
    max-width: 1220px; /* Adjust the max width for web version */
  }

  // @media (max-width: 600px) {
  //   flex-direction: column;
  //   align-items: flex-start;
  // }
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  align-content: center;
  //justify-content: space-between;
  margin-left: auto;

  @media (min-width: 550px) {
    margin: 0;
    padding: 0;
    align-items: center;
  }
  @media (max-width: 550px) {
    align-items: center;
    padding-left: 0;
    flex-direction: row;
    //justify-content: center;      // Center items on mobile
    width: 100%; // Full width to accommodate flex children spacing
    margin-bottom: 5px;
  }
`;

const NavLinkItem = styled.li`
  margin-right: 0 1%;
  border-radius: 5px;

  // &:hover {
  //   background-color: #c5c6D0;
  // }

  @media (min-width: 768px) {
    /* Apply styles for web version */
    margin-right: 30px; /* Adjust margin for web version */
  }
`;


const NavLink = styled(Link)` // Updated to styled(Link) for direct styling
  display: inline-flex; // Ensure it behaves like a block for padding
  background-color: #fff; // White background
  color: #325d79ff; // Text color, adjust as needed
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  font-size: .9em;
  font-weight: bold;
  padding: 8px 12px; // Padding inside the button, adjust as needed
  border: 2px solid #325d79ff; // Border color, adjust as needed
  border-radius: 20px; // Rounded corners
  min-width: fit-content;
  white-space: nowrap;

  &:hover {
    color: #5f7d95ff; // Hover text color, adjust as needed
    border-color: #5f7d95ff; // Hover border color, adjust as needed
    background-color: #f5f5f5;
  }

  @media (min-width: 768px) {
    //font-size: 14px; // Larger text on larger screens
  }

  @media (max-width: 600px){
    font-size: .65em;
    padding: 5px 7px;
    border: 1px solid #325d79ff;
    margin: 0 3px;
  }
`;

const CTAButton = styled(NavLink)`
  background-color: #325d79ff;
  color: #fff;
  padding: 12px 16px;

  &:hover {
      background-color: #5f7d9580;
      color: #325d79ff;
      transform: scale(1.05);
      border-color: #5f7d95ff;
  }

  @media (max-width: 768px) {
    padding: 8px 12px;
  }
`;


const LogoutButton = styled(Link)`
  color: #325d79ff;
  background-color: transparent;
  text-decoration: none;
  transition: color 0.3s;
  font-size: .9em;
  font-weight: bold;
  text-decoration: none;
  border: none;
  min-width: fit-content;
  white-space: nowrap;

  @media (min-width: 768px) {
    // font-size: 18px;
    // color: #00796B;

  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover > ul {
    display: block;
  }
`;

const DropdownMenu = styled.ul`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 3% 3%;

  @media (max-width: 800px) {
    min-width: 80px;
  }
`;

const NavLinkDropDown = styled(NavLink)`
  border: none;
`;

const DropdownItem = styled.li`
  color: black;
  padding: 4% 4%;
  text-decoration: none;
  display: block;
  text-align: left;
  list-style-type: none;
  font-size: 9px;

  &:hover {
    background-color: #f1f1f1;
  }
  @media (min-width: 768px) {
    font-size: 18px;

  }
`;

const MobileRenderDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 0%;
  }
`;


function Header() {
  // const { initialized } = useAuthInitialization();
  const { authState, signOut } = useAuth();
  // const { authState } = useAuth();
 

  return (
    <HeaderContainer>
      <Navbar>
        <MobileRenderDiv>
        <Link style={{ textDecoration: 'none' }} to="/">
        <Logo>

          {/* <CompanyTitle>Carbonsmith</CompanyTitle> */}

        </Logo>
        </Link>

        </MobileRenderDiv>

        <NavLinks>
        { authState.isAuthenticated ? (
          //User is Authenticated - say hello to user
          //have to add user name in
          <></>
        ) : (
          //user not authenticated, show get started button
          <NavLinkItem>

            <CTAButton to="/Contact">Get Started</CTAButton>
          </NavLinkItem>
        )}
          <NavLinkItem>
            <NavLink to="/">
                Home
            </NavLink>
          </NavLinkItem>
          <NavLinkItem>
            <DropdownContainer>
              <NavLink>About</NavLink>
              <DropdownMenu>
                <DropdownItem>
                  <NavLinkDropDown to="/our-team">Our Team</NavLinkDropDown>
                </DropdownItem>
                <DropdownItem>
                  <NavLinkDropDown to="/our-mission">Our Mission</NavLinkDropDown>
                </DropdownItem>
              </DropdownMenu>
            </DropdownContainer>
          </NavLinkItem>
          <NavLinkItem>
            <NavLink to="/blog">
              Blog
            </NavLink>
          </NavLinkItem>
{/* 
          <NavLinkItem>

              <NavLink to="/marketing">Marketing</NavLink>

          </NavLinkItem> */}
          { authState.isAuthenticated ? (
            //authenticated, offer logout
            <NavLinkItem>
              <NavLink>
                {/* //change button styling */}
                <LogoutButton onClick={signOut}>Log Out</LogoutButton>
              </NavLink>
            </NavLinkItem>
          ) : (
            <NavLinkItem>
              <NavLink to="/Login">
                Log In
              </NavLink>
            </NavLinkItem>
          )}
        </NavLinks>
      </Navbar>
    </HeaderContainer>
  );
}

export default Header;








