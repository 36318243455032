import React, { useEffect, useState } from "react";
import LandingBodyTabs from "../CoreProductFrontEndSeller/LandingBodyTabs";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const PageContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
//   background: linear-gradient(145deg, #fff, #DBE2E9);
`;

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    // background: #f5f5f5;
    border-radius: 8px;
`;

const BodyDiv = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
`;

const TitleDiv = styled.div`

`;

const ProjectTitle = styled.p`
    font-size: 1.5rem;
    font-weight: bold;
`;

const ProjectInfoDiv = styled.div`
    /* margin: 2% 2%; */
`;

const ProjectInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

`;

const ProjectInfoTextTitle = styled.p`
    font-size: 1.1rem;
    font-weight: bold;
`;

const ProjectInfoText = styled.p`
    /* margin-left: 2%; */
    font-size: 1rem;
`;

const BackButton = styled.button`
  margin: 20px 0;
  width: clamp(80px, 10%, 30%);
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  &:hover {
    background-color: #0056b3;
  }
`;

function ProjectReviewIndiv() {

    const { company, project_name } = useParams();
    const [project, setProject] = useState({});
    const navigate = useNavigate();
                
    const username = localStorage.getItem('user');
    const accessToken = localStorage.getItem('access_token');
    const firebaseIdToken = localStorage.getItem('firebase_id_token');


    useEffect(() => {
        const getProjectDetails = async() => {
            const requestParams = {};
            if (!accessToken || !firebaseIdToken) {
                console.error('Tokens are missing');
                return;
            } 

            const config = {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Firebase-Token': firebaseIdToken
                }
            }
            
            requestParams['username'] = username;
            requestParams['project_name'] = project_name;
            requestParams['company'] = company;

            try {
                const res = await axios.get(
                    "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/project",
                    {
                        params: {
                            company: company,
                            project_name: project_name,
                        },
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`
                        }
                    }
                );

                console.log("projectreviewindiv get project", res.data.data);
                if (res.data.message === 'success') {
                    setProject(res.data.data);
                }
            } catch (error) {
                console.log('An error occurred: ', error);
            }
        }
        getProjectDetails();
    },[])

    return (
            <MainContainer>
                <BodyDiv>
                    <BackButton onClick={() => navigate(-1)}>Back</BackButton>
                    <TitleDiv>
                        <ProjectTitle>{project_name}</ProjectTitle>
                    </TitleDiv>
                    <ProjectInfoDiv>
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Company</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.company}</ProjectInfoText>
                        </ProjectInfo>
                        {project.time_initiated && (<ProjectInfo>
                            <ProjectInfoTextTitle>Submission Date</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.time_initiated.split(' ')[0]}</ProjectInfoText>
                        </ProjectInfo>)}
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Already Certified</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.certified}</ProjectInfoText>
                        </ProjectInfo>
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Carbonsmith Approval Status</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.approved}</ProjectInfoText>
                        </ProjectInfo>
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Location</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.project_location}</ProjectInfoText>
                        </ProjectInfo>
                        {project.credits_per_year && (
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Credits Per Year</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.credits_per_year}</ProjectInfoText>
                        </ProjectInfo>
                        )}
                        {project.credits_used && (
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Credits Used</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.credits_used}</ProjectInfoText>
                        </ProjectInfo>
                        )} 
                        {project.projectDocumentation && (
                        <ProjectInfo>
                            <ProjectInfoTextTitle>Documentation</ProjectInfoTextTitle>
                            <ProjectInfoText>{project.projectDocumentation}</ProjectInfoText>
                        </ProjectInfo>
                        )}

                    </ProjectInfoDiv>

                </BodyDiv>
            </MainContainer>

    );
}

export default ProjectReviewIndiv;