import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const MainReviewPage = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const ColumnHolder = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5vw;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 30vw;
  max-width: 30vw;
  gap: 10px;
  align-items: center;
  border-radius: 8px;
  background-color: #ecf0f6;
  @media (min-width: 768px) {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 150px;
    height: 100vh;
  }
  @media (max-width: 768px) {
    flex-direction: row;
    overflow-x: scroll;
    height: 30vh;
    min-width: 100vw;
  }
`;

const ColumnTitleDiv = styled.div``;

const ColumnTitle = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ProjectItem = styled.div`
  border: 1px solid #324a75;
  padding: 2% 2%;
  width: 80%;
  border-radius: 4px;
  background-color: white;
  color: #324a75;
  @media (max-width: 768px) {
    height: 15vh;
    min-width: 30vw;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ProjectItemTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const ProjectItemDetail = styled.div`
  font-size: 0.8rem;
  text-align: left;
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;

  &:hover > ul {
    display: block;
  }
`;

const DropdownMenu = styled.ul`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 140px;
  width: 20vw;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  padding: 3% 3%;
  @media (max-width: 800px) {
    min-width: 80px;
  }
`;
const DropdownItem = styled.li`
  color: black;
  padding: 4%;
  text-decoration: none;
  display: block;
  text-align: left;
  list-style-type: none;
  font-size: 0.5rem;

  &:hover {
    background-color: #f1f1f1;
  }
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;
const IconStyle = styled.div`
  font-size: 1rem;
  color: #324a75;
`;
const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1000;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;
const Modal = styled.div`
  height: 30%;
  width: 35%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 2px;

  @media (max-width: 768px) {
    width: 50%;
  }
`;

const Button = styled.button`
  padding: 10px 20px;
  width: clamp(75px, 10vw, 200px);
  height: 40px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  background-color: #e6f2f1;

  &:hover {
    background-color: #8caa98;
    color: #eaf5ee;
  }
`;

function ProjectReview() {
  const [allProjectData, setAllProjectData] = useState({});
  const [admin, setIsAdmin] = useState(false);
  const navigate = useNavigate();
  const [confirmModal, setConfirmModal] = useState({
    isVisible: false,
    movedItem: {},
    source: {},
    destination: {},
  });
  const [categorizedProjects, setCategorizedProjects] = useState({
    reviewing: [],
    accepted: [],
    rejected: [],
  });

  const username = localStorage.getItem("user");
  const firebaseIdToken = localStorage.getItem("firebase_id_token");
  const authHeader = {
      Authorization: `Bearer ${firebaseIdToken}`,
  };

  //TODO: memoize this / use data fetching library eventually
  useEffect(() => {
    const getAllProjects = async () => {

      const requestParams = { username: username };
      if (username === "drewhahn23@gmail.com") {
        setIsAdmin(true);

        try {
          const res = await axios.get(
            "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/all_projects_admin",
              {
                params: {
                  username: username
                },
                headers: authHeader
              }
          );

          const allProjects = res.data.data;
          console.log(allProjects);
          setAllProjectData(allProjects);
          setCategorizedProjects({
            reviewing: [],
            accepted: [],
            rejected: [],
          }); //set to empty to avoid duplicates with strict mode and re-renders

          // Categorize projects based on their approval status
          Object.keys(allProjects).forEach((company) => {
            const companyData = allProjects[company];
            // console.log('company data: ', companyData);
            if (
              companyData.Projects &&
              typeof companyData.Projects === "object"
            ) {
              const projects = companyData.Projects;

              Object.keys(projects).forEach((projectName) => {
                const project = projects[projectName];
                if (project.approved === "reviewing") {
                  setCategorizedProjects((prev) => ({
                    ...prev,
                    reviewing: [...prev.reviewing, project],
                  }));
                } else if (project.approved === "accepted") {
                  setCategorizedProjects((prev) => ({
                    ...prev,
                    accepted: [...prev.accepted, project],
                  }));
                } else if (project.approved === "rejected") {
                  setCategorizedProjects((prev) => ({
                    ...prev,
                    rejected: [...prev.rejected, project],
                  }));
                }
              });
            }
          });
        } catch (error) {
          console.log(
            "error: ",
            error.response ? error.response.data : error.message
          );
        }
      }
    };

    getAllProjects();
  }, []);

  const moveTo = (project, from, to) => async (e) => {
    e.stopPropagation();
    //Display modal
    setConfirmModal({
      isVisible: true,
      movedItem: project,
      source: from,
      destination: to,
    });
  };

  const handleMove = async (project, destination) => {

    const changeData = {
      ...project,
      approved: destination,
    };

    console.log("changeData", changeData);

    try {
      const res = await axios.put(
        "https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/project_status",
        changeData,
          {
            headers: authHeader
          }
      );
      console.log("Status updated successfully: ", res.data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const handleConfirm = (confirmed) => {
    const { movedItem, destination } = confirmModal;
    if (confirmed) {
      handleMove(movedItem, destination)
        .then(() => {
          //update local list state, for categorizedProjects
          const source = confirmModal.source;
          setCategorizedProjects((prev) => ({
            ...prev,
            [source]: prev[source].filter(
              (project) => project.project_name !== movedItem.project_name
            ),
          }));

          setCategorizedProjects((prev) => ({
            ...prev,
            [destination]: [...prev[destination], movedItem],
          }));
        })
        .finally(() => {
          setConfirmModal({
            isVisible: false,
            source: null,
            destination: null,
            movedItem: null,
          });
        });
    }
  };
  return (
    <MainReviewPage>
      {confirmModal.isVisible && (
        <ModalContainer>
          <Modal>
            <p style={{ fontWeight: "bold", fontSize: "1rem" }}>
              Are you sure you want to move this item?
            </p>
            <div>
              <p>
                {confirmModal.movedItem.project_name} from {confirmModal.source}{" "}
                to {confirmModal.destination}
              </p>
            </div>
            <div>
              <Button
                onClick={() => handleConfirm(true)}
                style={{ backgroundColor: "#008074ff", color: "white" }}
              >
                Yes
              </Button>
              <Button onClick={() => handleConfirm(false)}>No</Button>
            </div>
          </Modal>
        </ModalContainer>
      )}
      <ColumnHolder>
        <Column>
          <ColumnTitleDiv>
            <ColumnTitle>In Review</ColumnTitle>
          </ColumnTitleDiv>
          {categorizedProjects.reviewing.map((project, index) => (
            <ProjectItem key={index}>
              <ProjectItemTitle
                onClick={() =>
                  navigate(
                    `/Admin/Projects/Review/${project.company}/${project.project_name}`
                  )
                }
              >
                {project.project_name}
              </ProjectItemTitle>
              <ProjectItemDetail>
                {project.addedAt && project.addedAt.split("T")[0]}
              </ProjectItemDetail>
              <ProjectItemDetail>
                {project.company && project.company}
              </ProjectItemDetail>
              <ProjectItemDetail>
                Already certified: {project.certified && project.certified}
              </ProjectItemDetail>
              <ProjectItemDetail>
                {project.project_location && project.project_location}
              </ProjectItemDetail>
              <ProjectItemDetail>
                <DropdownContainer>
                  <IconStyle>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </IconStyle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={moveTo(project, "reviewing", "accepted")}
                    >
                      Accept
                    </DropdownItem>
                    <DropdownItem
                      onClick={moveTo(project, "reviewing", "rejected")}
                    >
                      Reject
                    </DropdownItem>
                  </DropdownMenu>
                </DropdownContainer>
              </ProjectItemDetail>
            </ProjectItem>
          ))}
        </Column>

        <Column>
          <ColumnTitleDiv>
            <ColumnTitle>Accepted</ColumnTitle>
          </ColumnTitleDiv>
          {categorizedProjects.accepted.map((project, index) => (
            <ProjectItem key={index}>
              <ProjectItemTitle>{project.project_name}</ProjectItemTitle>
              <ProjectItemDetail>
                {project.addedAt && project.addedAt.split("T")[0]}
              </ProjectItemDetail>
              <ProjectItemDetail>
                {project.company && project.company}
              </ProjectItemDetail>
              <ProjectItemDetail>
                Already certified: {project.certified && project.certified}
              </ProjectItemDetail>
              <ProjectItemDetail>
                {project.project_location && project.project_location}
              </ProjectItemDetail>
              <ProjectItemDetail>
                <DropdownContainer>
                  <IconStyle>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </IconStyle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={moveTo(project, "accepted", "reviewing")}
                    >
                      In Review
                    </DropdownItem>
                    <DropdownItem
                      onClick={moveTo(project, "accepted", "rejected")}
                    >
                      Reject
                    </DropdownItem>
                  </DropdownMenu>
                </DropdownContainer>
              </ProjectItemDetail>
            </ProjectItem>
          ))}
        </Column>
        <Column>
          <ColumnTitleDiv>
            <ColumnTitle>Rejected</ColumnTitle>
          </ColumnTitleDiv>
          {categorizedProjects.rejected.map((project, index) => (
            <ProjectItem key={index}>
              <ProjectItemTitle>{project.project_name}</ProjectItemTitle>
              <ProjectItemDetail>
                {project.addedAt && project.addedAt.split("T")[0]}
              </ProjectItemDetail>
              <ProjectItemDetail>
                {project.company && project.company}
              </ProjectItemDetail>
              <ProjectItemDetail>
                Already certified: {project.certified && project.certified}
              </ProjectItemDetail>
              <ProjectItemDetail>
                {project.project_location && project.project_location}
              </ProjectItemDetail>
              <ProjectItemDetail>
                <DropdownContainer>
                  <IconStyle>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </IconStyle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={moveTo(project, "rejected", "reviewing")}
                    >
                      In Review
                    </DropdownItem>
                    <DropdownItem
                      onClick={moveTo(project, "rejected", "accepted")}
                    >
                      Approve
                    </DropdownItem>
                  </DropdownMenu>
                </DropdownContainer>
              </ProjectItemDetail>
            </ProjectItem>
          ))}
        </Column>
      </ColumnHolder>
    </MainReviewPage>
  );
}

export default ProjectReview;
