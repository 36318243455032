// StripeService.js
import axios from 'axios';
import { CONFIG } from './stripeConfig';

class StripeService {
  constructor() {
    this.baseURL = CONFIG.API_URL;
  }

  async createAccount(company) {
    try {
      const response = await axios.post(`${this.baseURL}/api/v1/account`, { params: {company} });
    //   console.log(response);
      return response.data;
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      throw error;
    }
  }

  async checkAccountStatus(company) {
    try {
      const response = await axios.get(`${this.baseURL}/api/v1/stripe_info`, {params: { company }});
      return response.data;
    } catch (error) {
      console.error('Error checking Stripe account status:', error);
      throw error;
    }
  }
}

export default StripeService;