import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import LandingBodyTabs from './LandingBodyTabs';
import { useNavigate } from 'react-router-dom';
import CarbonRemovedProgressPie from './GraphSourcing/CarbonRemovalPieChart';
// import { FiCheckCircle, FiLoader, FiAlertCircle } from 'react-icons/fi';
import axios from 'axios';
import SolidGaugeChart from './GraphSourcing/HighChartsExport';
import background1 from '../../../../images/carbon_removed_ex1.png';
import background2 from '../../../../images/carbon_removed_ex2.png';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import { format2Decimals, formatNumberDollars } from '../../../UniversalFunctions/Numeric/Format2Decimal';
import InsightsPage from './Insights';

// const PageContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   width: 100%;
//   // background: linear-gradient(145deg, #fff, #DBE2E9);
//   @media (max-width: 768px) {
//     margin-top: 5vh; //margin top due to mobile nav bar covering content
//     }
// `;

// const MainContainer = styled.div`
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     padding: 20px;
//     // background: #f5f5f5;
//     border-radius: 8px;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
// `;

// const BodyDiv = styled.div`
//     display: flex;
//     flex: 1;
//     flex-direction: column;
//     width: 100%;
//     padding-left: 14%;
//     min-height: 100vh;
// `;

// const SubBodyDiv = styled.div`
//     display: flex;
//     flex-direction: row;
//     flex: 1;
//     align-items: flex-start;
//     width: 90%;


//     @media (max-width: 800px) {
//       gap: 2vh;
//       flex-direction: column;
//     }
// `;

// const NoContractsContainer = styled(SubBodyDiv)`
//   width: 100%;
// `;

// const SubDivLeft = styled.div`
//     display: flex;
//     flex-direction: column;
//     margin-left: 10%;
//     margin-right: 2%;
//     background-color: white;
//     border-radius: 20px;
//     padding: 1% 3%;
//     border: 1px solid #325d7980;
// `;

// const ContentDivSubDivLeft = styled.div`
//     padding: 5%;
// `;

// const SubDivRight = styled.div`
//     flex: 1;
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     flex-direction: column;
//     padding: 1%;
//     margin-left: 5%;
//     border-radius: 20px;
//     background-color: white;
//     border: 1px solid #325d7980;
// `;

// const PieChartContainer = styled.div`
//   height: 400px;
//   width: 70%;

// `;

// const ContractInfo = styled.div`
//   margin-top: 5%;
//   display: flex;
//   flex-direction: column;
//   background-color: #f5f5f5; // A light background to make the content stand out
//   padding: 3%;
//   border-radius: 10px; // Soft rounded corners
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
//   margin-right: 15%; // Creates a balanced layout with PieChartContainer
//   margin-left: 10%;
//   max-width: 25%; // Restricts the width for better readability

//   @media (max-width: 800px) {
//     margin-right: 0;
//     margin-bottom: 2.5%;
//   }
// `;

// const ContractCard = styled.div`
//   background-color: white;
//   padding: 5%;
//   margin: 5%;
//   border-radius: 20px;
//   box-shadow: 0 5px 15px rgba(0,0,0,0.1);
//   transition: transform 0.3s ease-in-out;
//   border: 1px solid #325d7980;

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 10px 20px rgba(0,0,0,0.2);
//   }
// `;

// const EfficiencyIndicator = styled.div`
//   padding: 10px;
//   margin-top: 10px;
//   background-color: #e6ffe6; // Light green background for positive insight
//   border-radius: 5px;
//   text-align: center;
//   color: #00796b;
// `;

// const ContractInfoHeader = styled.h2`
//   font-size: 1.5em; // Slightly larger font for prominence
//   color: #325d79ff; // Darker shade for contrast
//   margin-bottom: 10%;
//   font-weight: bold; // Bold for emphasis
// `;

// const ContractInfoLine = styled.div`
//   display: flex;
//   flex-direction: row;
// `;

// const ContractInfoDescTitle = styled.p`
//   font-size: 1.1em;
//   line-height: 1.25;
//   color: #325d79ff;
//   margin-bottom: 10%;
//   text-align: left;
//   font-weight: bold;
//   margin-right: 2%;
// `;

// const ContractInfoDesc = styled.p`
//   font-size: 1.1em; // Comfortable reading size
//   line-height: 1.25; // Spacing for readability
//   color: #5f7d95ff; // Dark enough for contrast, but not too harsh
//   margin-bottom: 10%;
//   text-align: left;

// `;

// const GraphHeader = styled.h2`
//   margin: 0;
//   margin-bottom: 5%;
//   color: #325d79ff;
//   // margin: auto;
// `;

// const StyledSelect = styled.select`
//   padding: 2% 2%;
//   border-radius: 4px;
//   border: 1px solid #ccc;
//   background-color: white;
//   font-size: 16px;
//   color: #333;
//   margin-bottom: 5%;
//   cursor: pointer;
//   outline: none;
//   transition: border-color 0.3s, box-shadow 0.3s;
//   // width: 10%;

//   &:hover, &:focus {
//     border-color: #26a69a;
//     box-shadow: 0 0 0 3px rgba(38, 166, 154, 0.5);
//   }

// `;

// const ButtonChangeDiv = styled.div`
//   display: flex;
//   justify-content: center;
//   width: 30%;
// `;

// const CircularBarDiv = styled.div`
//     width: 100%; // Adjust width as needed
//     height: 300px; // Adjust height as needed
//     transition: all 0.5s ease-in-out;
//     margin-left: 8%;

//     @media (max-width: 800px) {
//         height: 200px; // Adjust for smaller screens if necessary
//     }

//     @media (max-width: 650px) {
//       height: 150px;
//     }
// `;


// const ProgressBarContainer = styled.div`
//   position: relative;
//   width: 100%;
//   background-color: #e0e0e0;
//   border-radius: 20px;
//   box-shadow: inset 0 0 5px #00000050;
//   margin: 20px 0;
//   overflow: hidden;
//   display: flex;
//   align-items: center;
//   height: 30px;
// `;

// const ProgressBar = styled.div`
//   height: 30px;
//   //width: ${({ progress }) => progress > 0 ? `${progress}%` : `1%`}; // Ensure a minimum width for visibility
//   background: linear-gradient(to right, #4caf50, #81c784); // Gradient effect
//   border-radius: 18px;
//   transition: width 0.5s ease;
//   display: flex;
//   padding-left: 10px;
//   align-items: center;
//   justify-content: flex-start;
//   color: white; // For text visibility
//   position: relative;
// `;

// const ProgressText = styled.span`
//  // Adjust based on your logic
//   font-weight: bold;
//   // left: ${({ progress }) => progress >= 20 ? '0' : '50%'}; // Center text for low progress, otherwise start at the beginning
//   // transform: ${({ progress }) => progress < 20 ? 'translateX(-50%)' : 'none'}; // Center text for low progress
//   // text-align: ${({ progress }) => progress < 20 ? 'center' : 'left'}
//   transition: color 0.3s;
//   padding-left: 5%;
// `;


// const TextDivSubDivRight = styled.div`
//     width: 65%;
// `;

// const LowerDivMain = styled.div`
//     // margin-top: 3%;
//     margin: 2%;
// `;

// const WelcomeContainer = styled.div`
//   display: flex;
//   flex: 1;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   padding: 5%;
//   padding-bottom: 12%;
//   background: linear-gradient(to right, #00c9ff, #92fe9d);
//   border-radius: 20px;
//   box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
//   color: white;
//   text-align: center;
//   margin: 0 1%;
//   // margin-left: 2%;
//   // margin-top: 2%;
//   transition: all 0.3s ease-in-out;

//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 15px 25px rgba(0, 0, 0, 0.25);
//   }
// `;

// const WelcomeTitle = styled.h1`
//   font-size: 2.5em;
//   margin-bottom: 20px;
// `;

// const WelcomeMessage = styled.p`
//   font-size: 1.2em;
//   margin-bottom: 30px;
//   max-width: 600px; // Keeps text from becoming too wide on larger screens
// `;

// const GetStartedButton = styled.button`
//   background-color: white;
//   color: #325d79ff;
//   font-size: 1em;
//   padding: 1% 2%;
//   border: none;
//   border-radius: 20px;
//   cursor: pointer;
//   font-weight: bold;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #f0f0f0;
//     transform: scale(1.02);
//   }
// `;

// const IllustrationContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   gap: 2%;
//   // justify-content: space-between;

//   @media (max-width: 1200px) {
//     flex-direction: column;
//   }
  
// `;

// const IllustrationImage = styled.img`
//   width: 100%;
//   max-width: 400px;
//   margin-top: 5%;
//   border-radius: 20px;
// `;

// //const IllustrationUrl = 'path/to/your/engaging/illustration.svg'; // Replace with your actual illustration URL


// function CarbonRemovedPage() {
//   // Example data
//   const tonsRemoved = 6.66;
//   const totalTons = 10;
//   const progress = (tonsRemoved / totalTons) * 100;

//   // maybe only want example data in a modal?
//   const navigate = useNavigate();
//   const [contracts, setContracts] = useState([]);
//   const [selectedContract, setSelectedContract] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   const OverallProgress = ({ current, total }) => {
//     const currentNum = Number(current) || 0;
//     const totalNum = Number(total) || 0;
  
//     const progressPercent = totalNum > 0 ? Math.min((currentNum / totalNum) * 100, 100) : 0;
//     const formatNumber = (num) => num === 0 ? "0" : num.toFixed(2);
  
//     // Define the progress text component for reuse
//     const progressText = (
//       <ProgressText
//         style={{
//           textAlign: progressPercent >= 20 ? 'left' : 'center',
//           paddingLeft: progressPercent >= 20 ? '10px' : '5px',
//         }}
//       >
//         {`${formatNumber(currentNum)} / ${formatNumber(totalNum)} Tons CO2 Removed`}
//       </ProgressText>
//     );
  
//     return (
//       <ProgressBarContainer>
//         {progressPercent >= 35 && (
//           <ProgressBar progress={progressPercent}>
//             {progressText}
//           </ProgressBar>
//         )}
//         {progressPercent < 35 && progressText}
//       </ProgressBarContainer>
//     );
//   };
  

//   const RenderEfficiencyIndicator = ({ contract }) => {
//     if (!contract || typeof contract !== 'object') {
//       return <EfficiencyIndicator>Invalid contract data</EfficiencyIndicator>;
//     }
  
//     // Attempt to extract and parse the Revenue, defaulting to 0 if unsuccessful
//     let revenue = 0;
//     if (typeof contract.Revenue === 'string') {
//       const revenueString = contract.Revenue.replace(' USD', '');
//       revenue = parseFloat(revenueString);
//       if (isNaN(revenue)) {
//         revenue = 0; // Set to 0 if the parsed value is NaN
//       }
//     }
  
//     // Calculate cost per ton
//     let costPerTon = 'N/A';
//     if (contract.carbonRemoved > 0 && revenue > 0) {
//       costPerTon = revenue / contract.carbonRemoved;
//     }
  
//     return (
//       <EfficiencyIndicator>
//         Cost Efficiency: ${costPerTon === 'N/A' ? costPerTon : formatNumberDollars(costPerTon)} per ton CO2 removed
//       </EfficiencyIndicator>
//     );
//   };
  

  
//   useEffect(() => {

//     const fetchContracts = async () => {
//       const username = localStorage.getItem('user');
//       const accessToken = localStorage.getItem('access_token');
//       const firebaseIdToken = localStorage.getItem('firebase_id_token');
    
//       if (!accessToken || !firebaseIdToken) {
//           console.error('Tokens are missing');
//           return;
//       }

//       const config = {
//           headers: {
//               'Authorization': `Bearer ${accessToken}`,
//               'Firebase-Token': firebaseIdToken
//           }
//       };
//       try {
//         const res = await axios.post('https://carbonsmith-handling-69e454e97e3b.herokuapp.com/seller_contracts', { 'username': username }, config);
//         const fetchedContracts = res.data.data;
//         if (Object.keys(fetchedContracts).length > 0) {
//           // console.log('fetched ctx', fetchedContracts);
//           setContracts(fetchedContracts);
//           const contractsArray = Object.values(fetchedContracts);
//           setSelectedContract(contractsArray[0]);
//         }

//       } catch (error) {
//         console.log('An error occurred grabbing contracts: ', error);
//       }
//       setIsLoading(false);
//     }
//     fetchContracts();
//   },[])

//   const handleContractChange = (e) => {
//     const selectedContractId = e.target.value;
//     const contractsArray = Object.values(contracts);
//     const foundContract = contractsArray.find(contract => contract.contractName === selectedContractId);
//     // console.log('found contract: ', foundContract)
//     setSelectedContract(foundContract);
//   }

//   return (
//     <PageContainer>
//     {/* <DynamicBackgroundContainer> */}
//         <MainContainer>
//         <LandingBodyTabs />

//         <BodyDiv>
//           {isLoading ? (<Spinner />) : (
//             <>
//           {contracts && Object.keys(contracts).length > 0 ? (
//             <React.Fragment>
//               <ButtonChangeDiv>
//                 <StyledSelect onChange={handleContractChange}>
//                   {Object.values(contracts).map((contract) => (
//                     <option key={contract.contractName} value={contract.contractName}>
//                       {contract.contractName}
//                     </option>
//                   ))}
//                 </StyledSelect>
//               </ButtonChangeDiv>
//               <SubBodyDiv>
//                 <SubDivLeft>
//                   <ContentDivSubDivLeft>
//                   {selectedContract && <OverallProgress current={selectedContract['carbonRemoved']} total={selectedContract['tonsToCapture']} /> }
//                   { selectedContract && (
                  
//                     <ContractCard>
//                       <ContractInfoHeader>{selectedContract.contractName}</ContractInfoHeader>
//                       <ContractInfoLine><ContractInfoDescTitle>Status:</ContractInfoDescTitle><ContractInfoDesc> {selectedContract.Status}</ContractInfoDesc></ContractInfoLine>
//                       <ContractInfoLine><ContractInfoDescTitle>Revenue:</ContractInfoDescTitle><ContractInfoDesc> ${formatNumberDollars(selectedContract.Revenue)}</ContractInfoDesc></ContractInfoLine>
//                       <ContractInfoLine><ContractInfoDescTitle>Tons to Remove:</ContractInfoDescTitle><ContractInfoDesc> {selectedContract.tonsToCapture} Tons</ContractInfoDesc></ContractInfoLine>
//                       <ContractInfoLine><ContractInfoDescTitle>Progress:</ContractInfoDescTitle><ContractInfoDesc> {selectedContract.carbonRemoved} Tons</ContractInfoDesc></ContractInfoLine>
//                       {/* Would like to put a little symbol or mark if selectedContract.status is completed, in progress, uninitialized */}
//                     </ContractCard>
//                   )}
//                   {selectedContract && <RenderEfficiencyIndicator contract={selectedContract} />
//                   }
//                 </ContentDivSubDivLeft>
//               </SubDivLeft>
          
//               {/* <PieChartContainer> */}
//               <SubDivRight>
//                 {selectedContract && (
                  
//                     <>
//                     <GraphHeader>{selectedContract.contractName}</GraphHeader>
//                     <CircularBarDiv>
//                       <CarbonRemovedProgressPie data={[{ name: 'CO2 Removed', value: (Math.round((selectedContract['carbonRemoved']) * Math.pow(10, 2)) / Math.pow(10, 2)), fill: "#5cb85c"}, { name: 'Unfulfilled', value: (Math.round((selectedContract['tonsToCapture'] - selectedContract['carbonRemoved']) * Math.pow(10, 2)) / Math.pow(10, 2)), fill: "#666"}]} />
//                     </CircularBarDiv>
//                     <TextDivSubDivRight>
//                     {selectedContract['Status'] === 'Completed' && (<ContractInfoDesc>You have finished filling this contract. Make sure you are sending new removals to another contract!</ContractInfoDesc>)}
//                     {selectedContract['Status'] === 'Uninitialized' && (<ContractInfoDesc>Begin removing CO2 to start tracking your removals</ContractInfoDesc>)}
//                     {selectedContract['Status'] === 'In Progress' && (<ContractInfoDesc>You have removed {(((selectedContract.carbonRemoved / selectedContract.tonsToCapture)*100).toFixed(2))}% of the CO2 mandated by this contract. Keep it up!</ContractInfoDesc>)}
//                     </TextDivSubDivRight>
//                     </>
                  
//                 )}
//                 </SubDivRight>
//               </SubBodyDiv>
//             </React.Fragment>
//           ) : (


//               <NoContractsContainer>
//                 <WelcomeContainer>
//                   <WelcomeTitle>Start Your Journey with Carbon Credits</WelcomeTitle>
//                   <WelcomeMessage>
//                     Dive into the future of green finance by uploading your first carbon credit contract today.
//                     Together, we can make a difference in the fight against climate change.
//                   </WelcomeMessage>
//                   <GetStartedButton onClick={() => navigate("/ContractBuilder")}>
//                     Begin Now
//                   </GetStartedButton>
//                   <IllustrationContainer>
//                   <IllustrationImage src={background1} alt="An example of the platform showing data visualizations" />
//                   <IllustrationImage src={background2} alt="Another graphic example of the platform" />
//                   </IllustrationContainer>
//                 </WelcomeContainer>
//               </NoContractsContainer>



//           )}
//           {selectedContract && (
//             <LowerDivMain>
//               <SolidGaugeChart contract={selectedContract} />
//             </LowerDivMain>
//           )}
//           </>
//           )}
//         </BodyDiv>
//       </MainContainer>
//     </PageContainer>

//   );
// }

// export default CarbonRemovedPage;

function CarbonRemovedPage() {

  return (
    // <>
    <InsightsPage />
    // </>
  )
}

export default CarbonRemovedPage;


