import React from 'react';
import Header from './LandingHeader';
import Footer from './LandingFooter';
import styled from 'styled-components';
import videoSource from '../../../images/videoblocks-treetwo_bc7f7eh7v__62b5a21c191dc8d4a3bfd73076535ae5__P360.mp4';
import MapChart from '../../../data/timelapse_emissions_map';

const PageContainer = styled.div`
  background: linear-gradient(145deg, #fff, #DBE2E9);
`;

const BodySection = styled.div`
    display: flex;
    flex-direction: column;
`;

const UpperHalfBody = styled.div`

`;

const VideoContainerSection = styled.div`
  position: relative;
  width: 100%;
  height: 500px; // Adjust height as needed
  overflow: hidden;
  border-radius: 20px;

  @media (max-width: 768px) {
    height: 350px;
    margin-top: 4%;
  }
`;

const Video = styled.video`
  min-width: 100%;
  height:100%;
  object-fit: fill; // Ensure video covers the whole container
  `;

const VideoContent = styled.h1`
    position: absolute;
    top: 35%;
    left: 40%;
    transform: translate(-35%, -50%);
    color: white; // Adjust text color as needed
    z-index: 1; // Ensure the text is above the video
    text-align: left;
    width: 75%;

    @media (max-width: 768px) {
        font-size: 2rem;
    }
    @media (min-width: 1200px) {
        font-size: 3rem;
        line-height: 1.5;
    }
`;

const MiddleHalfBody = styled.div`

`;

const LowerHalfBody = styled.div`
    display: flex;
    flex-direction: row;
    margin: 5% 10%;
`;


const DescriptionHeader = styled.h2`
  font-size: 1.75em;
  margin-top: 4%;
  color: #005c4f;
`;

const DescriptionBodyMiddle = styled.p`
  margin: auto;
  width: 30%;
  font-weight: bold;
`;

const DescriptionBody = styled.p`
    margin: auto;
    width: 50%;
    font-weight: bold;
`;

const LeftComponent = styled.div`

`;

const RightComponent = styled.div`

`;

const MissionDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4% 10%;
  padding-top: 2%;
  background-color: #f0f0f0; // A light background to create a clean and open space
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;

  @media (max-width: 600px) {
    padding-top: 8%;
    padding-bottom: 10%;
  }
`;

const MissionHeader = styled.div`
  text-align: center;
`;

const MissionHeaderText = styled.h2`
  font-size: 2em;
  color: #325d79ff; // A professional color that reflects trust
  margin-bottom: 2%;
`;

const MissionBody = styled.div`
  text-align: center;
  max-width: 600px; // Limit width for better readability
  margin: auto;
  align-content: center;

  @media (max-width: 768px) {
    width: 80%;
    text-align: left;
    // max-width: 400px
  }
`;

const MissionBodyText = styled.p`
  font-size: 1.2em;
  color: #5f7d95ff;
  line-height: 1.5;
  margin-bottom: 2%;

`;

//background: linear-gradient(to right, #325d79ff, #5f7d95ff);

function OurMission() {

    return (
        <PageContainer>
            <Header />
            <BodySection>
                <UpperHalfBody>
                    <VideoContainerSection>
                        <Video autoPlay loop muted playsInline>
                            <source src={videoSource} type="video/mp4" />
                            Your browser does not support the video tag.
                        </Video>
                        <VideoContent>
                             Innovation needs to scale. We bring the infrastructure to do so.
                        </VideoContent>
                    </VideoContainerSection>
                
                </UpperHalfBody>
                <MiddleHalfBody>
                    <MissionDiv>
                        <MissionHeader>
                            <MissionHeaderText>
                                Our Mission
                            </MissionHeaderText>
                        </MissionHeader>
                        <MissionBody>
                            <MissionBodyText>
                                At Carbonsmith, we believe in the need for scaling of carbon removal solutions. Our goal is to help Direct Air Capture companies scale as quickly as possible to help make a dent in net-zero targets.
                            </MissionBodyText>
                        </MissionBody>
                    </MissionDiv>

                    {/* <DescriptionHeader>Growing Green Finance</DescriptionHeader>
                    <DescriptionBodyMiddle>There are two sides to any market, and a marketplace should seek to provide maximum value to both. This is our approach.</DescriptionBodyMiddle> */}
                </MiddleHalfBody>
                <LowerHalfBody>
                    <MapChart />
                </LowerHalfBody>

            </BodySection>
            <Footer />
        </PageContainer>
    
    )
}

export default OurMission;