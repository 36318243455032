import React from 'react';
import styled from 'styled-components';
import { FaLinkedin } from 'react-icons/fa';
import { RiTwitterXFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

const FooterContainer = styled.footer`
  background-color: #0A3C57;
  padding: 7% 20px;
  padding-bottom: 2%;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  z-index: 0;

  @media (max-width: 800px) {
    padding-top: 10vh;
  }
  @media (min-width: 800px) {
    padding-top: 0;
  }
`;

const LogoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 4%;
  margin-top: 9%;
  transform: translateY(-25px);

  &:hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  height: 60px;
  margin-bottom: 10px;
`;

const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 7%;
  align-items: flex-start;
  text-align: left;
  margin-right: 10%;

  @media (max-width: 750px) {
    margin-top: 2%;
  }
`;

const Link = styled.a`
  color: white;
  text-decoration: none;
  margin: 10px 0;
  font-size: 18px;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SocialSection = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
`;

const Icon = styled.a`
  color: white;
  font-size: 24px;
  margin-right: 20%;

  &:hover {
    color: #1EB1B5;
  }
`;

const CopyrightSection = styled.div`
  text-align: left;
  font-size: 14px;
  margin-top: 20px;
  margin-left: 5%;
  width: 100%;

  @media (max-width: 750px) {
    margin-left: 0%;
  }
`;

const NewFooter = () => {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <LogoSection>
        <a href='#landing' style={{ textDecoration: 'none' }}>
          <Logo src="/images/carbonsmith_logo_white.png" alt="Carbonsmith Logo" />
        </a>
        <a href="mailto:impact@carbonsmith.io" style={{ textDecoration: 'none', color: 'inherit', marginLeft: '5%', marginTop: '5%' }}>
          impact@carbonsmith.io
        </a>
      </LogoSection>
      <LinksSection>
        <Link href="/our-team">About</Link>
        <Link href="/blog">Blog</Link>
        <Link href="#get-started">Get Started</Link>
        <SocialSection>
          <Icon
            href="https://www.linkedin.com/company/carbonsmith"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="LinkedIn"
          >
            <FaLinkedin />
          </Icon>
          <Icon
            href="https://www.x.com/carbonsmith_io"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Twitter"
          >
            <RiTwitterXFill />
          </Icon>
        </SocialSection>
      </LinksSection>
      <CopyrightSection>
        © 2024 Carbonsmith, All Rights Reserved
      </CopyrightSection>
    </FooterContainer>
  );
};

export default NewFooter;

