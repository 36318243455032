
function addedAt() {
    return new Date().toISOString();
}

function modifiedAt() {
    return new Date().toISOString();
}

function formatDateFromISO(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
};

export { addedAt, modifiedAt, formatDateFromISO };