
import React, {createContext, useContext, useReducer, useEffect, useState} from 'react';
import { jwtDecode } from 'jwt-decode';
import { auth } from './firebase'



const AuthContext = createContext();

const clearAuthData = () => {
  // console.log("Clearing auth data from localStorage");
  localStorage.removeItem('user');
  localStorage.removeItem('firebase_id_token');
  localStorage.removeItem('access_csrf');
  localStorage.removeItem('refresh_csrf');
  localStorage.removeItem('hideIntroModal');
};


const initialState = () => {
  const user = localStorage.getItem('user');
  const firebaseIdToken = localStorage.getItem('firebase_id_token');

  // When a user successfully logs in, we will get a firebase id token, since we want to quickly direct the user to the home page, a
  // nd we trust the signInWithCustomToken will give us a valid firebase id token,
  // so we do not have to check it again (originally we use isTokenExpired).
  // Instead, we can directly check whether we already store user and firebaseIdToken.
  // When a user is making an api call, the backend will check this firebase id token.
  const isAuthenticated = !!user && !!firebaseIdToken;

  if (!isAuthenticated) {
    clearAuthData();
  }
  return {
    user: isAuthenticated ? user: null,
    isAuthenticated,
  };
};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'SIGN_IN':
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case 'SIGN_OUT':
      clearAuthData();
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};


export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, initialState());

  const setAuthState = (user, isAuthenticated) => {
    dispatch({
      type: 'SIGN_IN',
      payload: { user, isAuthenticated },
    });
  };

  const signOut = async () => {
    // First signout out of firebase auth.
    //console.log("Attempting to sign out...");
    try {
      await auth.signOut();
      //console.log("Firebase sign out completed.");
      dispatch({
        type: 'SIGN_OUT'
      });
      // Clear all auth data.
      clearAuthData();
      //console.log("Local storage cleared and state updated.");
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };


  // Refresh firebase_id_token every 58 mins.
  useEffect(() => {
    const refreshInterval = setInterval(async () => {
      if (auth.currentUser) {
        console.log("Refresh firebase_id_token every 58 mins...")
        console.log("Current firebase_id_token is:", localStorage.getItem("firebase_id_token"));
        try {
          const refreshedToken = await auth.currentUser.getIdToken(true);
          console.log("The new refreshed firebase token is:", refreshedToken);
          localStorage.setItem("firebase_id_token", refreshedToken);
          console.log("Successfully saved the refreshed firebase_id_token", localStorage.getItem("firebase_id_token"));
        } catch (error) {
          console.error('Error refreshing Firebase ID token:', error);
          // If we cannot successfully refresh, sign out.
          signOut();
        }
      }
    }, 3480000);


    return () => {
      clearInterval(refreshInterval);
    };
  }, [auth.currentUser]);


  return (
      <AuthContext.Provider value={{ authState, setAuthState, signOut }}>
        {children}
      </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

