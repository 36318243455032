
function ContractData() {

    //this has become obsolete
    const tonsOfCO2 = 10;
    const tonsRemoved = 6.6;
    const percentage = (tonsRemoved / tonsOfCO2);

    const contract = {
        "tonsOfCO2": tonsOfCO2,
        "tonsRemoved": tonsRemoved,
        "percentage": percentage,
    }

    return contract;

}


export default ContractData;