import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import LandingBodyTabs from './LandingBodyTabs';
import { FaCircle, FaCheckCircle, FaTimesCircle, FaPlusCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Timeline } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import CheckIcon from '@rsuite/icons/legacy/Check';
import CloseIcon from '@rsuite/icons/Close';
import project_dashboard from '../../../../images/project_dashboard.png';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, ContractDetails, ContractStatus, ContractId, ContractItem } from '../../../UI/Library/SellerComponents';
import { PageContainer, BodyDiv, UpperDivMain, MainContainer } from '../../../UI/Library/PageStructure';


const Header = styled.h1`
  font-size: 24px;
  color: #325d79ff;
  margin-bottom: 10px;
`;

const StatusTab = styled.button`
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background-color: ${({ isActive }) => isActive ? '#007bff' : '#6c757d'};
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const SummaryArea = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  padding: 15px;
  background: #e2e6ea;
  border-radius: 8px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
  border: 1px solid #325d7980;
  justify-content: space-between;
  align-items: center;
`;

const SummaryStats = styled.div`
  margin-left: 10%;
  font-size: 1rem;
`;

const AddProjectsDiv = styled.div`
  margin-right: 10%;
`;

const AddProjectsButton = styled.button`
background-color: #325d79ff;
color: white;
padding: 8px 16px;
border: none;
border-radius: 4px;
font-weight: bold;
cursor: pointer;
transition: background-color 0.3s;
white-space: nowrap;
//   margin: 2% 1%;

&:hover {
  background-color: #5f7d95ff;
}
`;

const GraphicContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 1%;
  margin-right: 5%;
`;

const NoProjectsDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // text-align: center;
  text-align: left;
  padding: 20px;
  margin-top: 20px;
  // background-color: #f2f2f2;
  border-radius: 10px;
  // box-shadow: 0 4px 8px rgba(0,0,0,0.1);
`;

const MessageText = styled.p`
  font-size: 18px;
  color: #333;
  margin: 20px 0;
  text-align: left;
`;

const AddProjectButton = styled.button`
  background-color: #325d79ff; 
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
`;

const AddProjectText = styled.p`
  padding: 1%;
  white-space: nowrap;
  padding-top: 2%;
  margin-top: 1%;
`;

const PlaceholderImage = styled.img`
  // width: 300px;
  // height: 200px;
  width: 50%;
  background-size: cover;
  border-radius: 5px;
`;

// Update the Node components below to use the new styles...
function NewTimeline({ project }) {
  return (
    <GraphicContainer>
      {project.approved === 'reviewing' && (
        <Timeline isItemActive={index => index === 1}>
          <Timeline.Item>Submitted for Review</Timeline.Item>
          <Timeline.Item>In Review</Timeline.Item>
          <Timeline.Item>Accepted</Timeline.Item>
          <Timeline.Item>Begin issuing credits</Timeline.Item>
        </Timeline>  // Fixed the closing tag here
      )}

      {project.approved === 'accepted' && (
        <Timeline isItemActive={Timeline.ACTIVE_LAST}>
          <Timeline.Item>Submitted for Review</Timeline.Item>
          <Timeline.Item>In Review</Timeline.Item>
          <Timeline.Item dot={<CheckIcon style={{ background: '#15b215', color: '#fff' }} />}>Accepted</Timeline.Item>
          <Timeline.Item>Begin issuing credits</Timeline.Item>
        </Timeline>  // Fixed the closing tag here
      )}

      {project.approved === 'rejected' && (
        <Timeline isItemActive={Timeline.ACTIVE_LAST}>
          <Timeline.Item>Submitted for Review</Timeline.Item>
          <Timeline.Item>In Review</Timeline.Item>
          <Timeline.Item dot={<CloseIcon style={{ background: 'red', color: '#fff' }} />}>Rejected</Timeline.Item>
        </Timeline>  // Fixed the closing tag here
      )}
    </GraphicContainer>
  );
}
  

function AllProjectsPage() {

    const [projects, setProjects] = useState({});
    const [activeStatus, setActiveStatus] = useState('reviewing');
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const user = localStorage.getItem('user');
    const firebaseIdToken = localStorage.getItem('firebase_id_token');
    const authHeader = {
        Authorization: `Bearer ${firebaseIdToken}`
    };


    useEffect(() => {

        const getProjects = async() => {
    
            if (!firebaseIdToken) {
                console.error('Token is missing');
                return;
            }


            try {
                const res = await axios.get(
                    'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/company_projects',
                    {
                        params: {
                            username: user
                        },
                        headers: authHeader
                    });
                // console.log('data: ', res.data.data);
                setProjects(res.data.data.Projects || {});
                setLoading(false);
            } catch(error) {
                console.log(error);
                setLoading(false);
            }

        }
        getProjects();
    },[]);

    const renderProjectsByStatus = (status) => {
        const filteredProjects = Object.values(projects).filter(p => p.approved === status);
        return (
          <div style={{ marginLeft: '2%'}}>
            <SummaryArea>
              <SummaryStats>
              <strong>Total Projects:</strong> {filteredProjects.length}
              <strong> | Total Credits/Year:</strong> {filteredProjects.length > 0 ? filteredProjects.reduce((sum, p) => sum + (Number(p.credits_per_year) || 0), 0) : 0}
              </SummaryStats>
              <AddProjectsDiv>
                <AddProjectsButton onClick={() => {navigate('/add_project')}} style={{marginTop: '2%', marginRight: '2%'}}>
                  Add Project
                </AddProjectsButton>
              </AddProjectsDiv>

            </SummaryArea>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', flexWrap: 'wrap', marginLeft: '2%', marginTop: '2%'}}>
            {filteredProjects.map(project => (

              <Card key={project.project_name} onClick={() => {navigate(`/View_Projects/${project.project_name}`)}} style={{ marginRight: '2%', marginTop: '2%'}}>
                <CardHeader>
                  <CardTitle>{project.project_name}</CardTitle>
                </CardHeader>
                <CardContent>
                  <ContractItem>
                    <ContractDetails>
                      {project.credits_per_year && (
                        <ContractId>{project.credits_per_year} Credits approved per year</ContractId>
                      )}
                      {project.build_status && (
                        <ContractId>Project Status: {project.build_status}</ContractId>
                      )}
                      {project.credits_used && (
                        <ContractId>{project.credits_used} credits used this year</ContractId>
                      )}
                      {project.project_location && (
                        <ContractId>{project.project_location}</ContractId>
                      )}
                    </ContractDetails>
                      {project.approved === 'reviewing' && (
                        <ContractStatus>{project.approved}</ContractStatus>
                      )}
                      {project.approved === 'accepted' && (
                        <ContractStatus style={{backgroundColor: '#b2e0b2', color: 'white'}}>{project.approved}</ContractStatus>
                      )}
                      {project.approved === 'rejected' && (
                        <ContractStatus style={{backgroundColor: '#ff4d4f', color: 'white'}}>{project.approved}</ContractStatus>
                      )}
                  </ContractItem>
                </CardContent>
              </Card>
            ))}
            </div>
          </div>
        );
      };
    

    return (
            <MainContainer> 
                <BodyDiv>
                  {loading ? (<Spinner />) : (
                    <>
                      <Header>Project Dashboard</Header>
                        <div>
                            <StatusTab isActive={activeStatus === 'reviewing'} onClick={() => setActiveStatus('reviewing')}><FaCircle /> In Review</StatusTab>
                            <StatusTab isActive={activeStatus === 'accepted'} onClick={() => setActiveStatus('accepted')}><FaCheckCircle /> Accepted</StatusTab>
                            <StatusTab isActive={activeStatus === 'rejected'} onClick={() => setActiveStatus('rejected')}><FaTimesCircle /> Rejected</StatusTab>
                        </div>
                        {renderProjectsByStatus(activeStatus)}

                        {Object.keys(projects).length === 0 && (
                                <NoProjectsDiv>
                                    <MessageText>You haven't submitted any projects, add a project to begin tracking the information associated with it.</MessageText>
                                    <AddProjectButton onClick={() => {navigate('/add_project')}}>
                                        <AddProjectText><FaPlusCircle /> Add a Project</AddProjectText>
                                    </AddProjectButton>
                                    <PlaceholderImage src={project_dashboard} /> {/* Placeholder for an example image */}
                                </NoProjectsDiv>
                        )}
                    </>
                  )}


                </BodyDiv>
            </MainContainer>
    );
}

export default AllProjectsPage;