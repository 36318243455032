//THIS COMPONENT IS OLD AND CAN LIKELY BE DELETED OR JUST USED FOR TESTING

import React from 'react';
import styled from 'styled-components';
import Header from '../LandingHeader';
import Footer from '../LandingFooter';
import LandingBodyTabs from './LandingBodyTabs';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 10vh;
  }


`;

const BodyContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;

`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    width: calc(70% - 20px);

`

const ContractContainer = styled.div`
//this is for an individual contract
    // margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
    flex-basis: calc(33.33% - 20px);
    box-sizing: border-box;

    &:hover {
        // background-color: #add8e6;
        background-color: #c5c6D0;
      }
`

const ListOfContractsHeader = styled.h2`
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
`
const ListOfContractsBody = styled.p`
    color: #555;
    font-size: 1rem;
    margin-bottom: 5px;
`


function ContractList() {

    const contract1 = {
        name: "Jim's Company Deal",
        tons: 10,
        removed: 6.66,
        percentage: 66.6
    }

    const contract2 = {
        name: "Tom's Company Deal",
        tons: 25,
        removed: 14.51,
    }

    contract2.percent = (contract2.removed / contract2.tons) * 100;
    contract2.percentage = contract2.percent.toFixed(2);

    const contract3 = {
        name: "Al's Company Deal",
        tons: 65,
        removed: 9.27
    }

    contract3.percent = (contract3.removed / contract3.tons) * 100;
    contract3.percentage = contract3.percent.toFixed(2);

    //contract list should pull from a contracts node in the database that is stored per user
    //each contract should link to an individual contract page
    //would be cool to see total tons to remove from all contracts combined - can do same with already removed
    //can potentially create heatmap of contract fulfillment

    return (
        <PageContainer>
            {/* <Header /> */}
                <BodyContainer>
                    <ContentContainer>
                        <ContractContainer>
                        <ListOfContractsHeader>{contract1.name}</ListOfContractsHeader>
                        <ListOfContractsBody>The contract is for {contract1.tons} tons</ListOfContractsBody>
                        <ListOfContractsBody>So far, {contract1.removed} tons have been filled, or {contract1.percentage}%</ListOfContractsBody>
                        </ContractContainer>
                        
                        <ContractContainer>
                            <ListOfContractsHeader>{contract2.name}</ListOfContractsHeader>
                            <ListOfContractsBody>The contract is for {contract2.tons} tons</ListOfContractsBody>
                            <ListOfContractsBody>So far, {contract2.removed} tons have been filled, or {contract2.percentage}%</ListOfContractsBody>
                        </ContractContainer>

                        <ContractContainer>
                            <ListOfContractsHeader>{contract3.name}</ListOfContractsHeader>
                            <ListOfContractsBody>The contract is for {contract3.tons} tons</ListOfContractsBody>
                            <ListOfContractsBody>So far, {contract3.removed} tons have been filled, or {contract3.percentage}</ListOfContractsBody>

                        </ContractContainer>
                        {/* <ContractContainer>

                        </ContractContainer> */}

                    </ContentContainer>

                </BodyContainer>
        </PageContainer>
    );

}

export default ContractList;