import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import LandingBodyTabs from './LandingBodyTabs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FiSearch, FiPlus } from 'react-icons/fi';
import Spinner from '../../../UniversalFunctions/LoadingGraphic';
import { formatNumberDollars, format2Decimals } from '../../../UniversalFunctions/Numeric/Format2Decimal';
import { PageContainer, MainContent, PageHeader } from '../../../UI/Library/PageStructure';
import { Title, Subtitle, HelpLink, ContractDetail, ContractValue, ContractLabel, SearchAndButton, ProgressAmount, RemainingAmount, ContractsHeader, ContractHeader, ContractTitle, ContractsTitle, AddButton, ContractsSection } from '../../../UI/Library/SellerComponents';
import { SummarySectionSeller} from '../../../UI/Library/PageSections/ContractSummarySection';
import ProgressBar from './GraphSourcing/ProgressBar';
import { ContractStatus } from '../../../UI/Library/SellerComponents';
import { useSearch } from '../../../UniversalFunctions/useSearch';
import SearchBar from '../../../UI/Library/SearchBar';
import { filter } from 'jszip';


const ContractGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;

const ContractCard = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 1.25rem;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 2px 6px rgba(0,0,0,0.1);
  
  &:hover {
    box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  }
`;

const ContractProgress = styled.div`
  margin-top: 1rem;
`;

const ContractProgressText = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.75rem;
  color: #5F7D95;
`;


function ContractHistory() {
  const [contracts, setContracts] = useState([]);
  const [metrics, setMetrics] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeStatus, setActiveStatus] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchContracts = async () => {
      const username = localStorage.getItem('user');

      try {
        const res = await axios.get(
            'https://carbonsmith-handling-69e454e97e3b.herokuapp.com/api/v1/user_all_contracts',
            {
              params: {
                username: username
              },
              headers: {Authorization: `Bearer ${localStorage.getItem("firebase_id_token")}`}
        });

        const fetchedContracts = res.data.data;
        if (fetchedContracts) {
          setContracts(Object.values(fetchedContracts));
          setMetrics(calculateMetrics(Object.values(fetchedContracts)));
        } else {
          setContracts([]);
        }
      } catch (error) {
        console.log('An error occurred: ', error);
        setContracts([]);
      }
      setIsLoading(false);
    };
    
    fetchContracts();
  }, []);

  const { searchTerm, setSearchTerm, searchResults, setStatusFilter } = useSearch(contracts, ['contractName', 'contract_hedera', 'Status', 'company', 'purchaser']);

  const handleStatusClick = (status) => {
    if (status === activeStatus) {
      setActiveStatus('');
      setStatusFilter('');
    } else {
      setActiveStatus(status);
      setStatusFilter(status);
    }
  };

  const handleClearStatus = () => {
    setActiveStatus('');
    setStatusFilter('');
  };

  const calculateMetrics = (contracts) => {
    return {
      totalRevenue: contracts.reduce((sum, contract) => sum + parseFloat(contract.Revenue), 0),
      tonsUnderContract: contracts.reduce((sum, contract) => sum + Number(contract.tonsToCapture), 0),
      totalDelivered: contracts.reduce((sum, contract) => sum + Number(contract.carbonRemoved), 0),
    };
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageContainer style={{backgroundColor: '#E4E8E8'}}>
      <LandingBodyTabs />
      <MainContent>
        <PageHeader>
          <div>
            <Title>Contracts</Title>
            <Subtitle>Access and manage your contracts</Subtitle>
          </div>
          <HelpLink href="#">Help?</HelpLink>
        </PageHeader>
      
        <SummarySectionSeller contracts={contracts} onStatusClick={handleStatusClick} activeStatus={activeStatus} onClearStatus={handleClearStatus}/>
        
        <ContractsSection>
          <ContractsHeader>
            <ContractsTitle>All Contracts</ContractsTitle>
          </ContractsHeader>
          <SearchAndButton>
            <SearchBar
              searchTerm={searchTerm}
              onSearch={setSearchTerm}
              placeholder="Search Contract"
            />
          <AddButton onClick={() => navigate('/ContractBuilder')}>
              <FiPlus style={{ marginRight: '0.5rem' }} />
              Add Contract
          </AddButton>
          </SearchAndButton>
          
          <ContractGrid>
            {searchResults && searchResults.length > 0 ? (
              searchResults.map(contract => (
                <ContractCard key={contract.contractName} onClick={() => navigate(`/contract/${contract.contractName}`)}>
                  <ContractHeader>
                    <ContractTitle>{contract.contractName}</ContractTitle>
                      {contract.Status === 'Uninitialized' && (
                          <ContractStatus>{contract.Status}</ContractStatus>
                        )}
                        {contract.Status === 'In Progress' && (
                          <ContractStatus style={{ backgroundColor: '#b2e0b2', color: 'white'}}>{contract.Status}</ContractStatus>
                        )}
                        {contract.Status === 'Completed' && (
                          <ContractStatus style={{ backgroundColor: '#ADD8E6', color: 'white'}}>{contract.Status}</ContractStatus>
                        )}
                  </ContractHeader>
                  <ContractDetail>
                    <ContractLabel>Contract ID:</ContractLabel>
                    <ContractValue>{contract.contract_hedera}</ContractValue>
                  </ContractDetail>
                  <ContractDetail>
                    <ContractLabel>Project:</ContractLabel>
                    <ContractValue>{contract.contractProject?.project_name || 'N/A'}</ContractValue>
                  </ContractDetail>
                  <ContractDetail>
                    <ContractLabel>Price:</ContractLabel>
                    <ContractValue>${contract.priceTonUSD} / ton</ContractValue>
                  </ContractDetail>
                  <ContractProgress>
                    <ProgressBar percent={(contract.carbonRemoved / contract.tonsToCapture) * 100} />
                    <ContractProgressText>
                      <div><ProgressAmount>${formatNumberDollars(contract.carbonRemoved * contract.priceTonUSD)}</ProgressAmount> <RemainingAmount> / ${formatNumberDollars(contract.tonsToCapture * contract.priceTonUSD)} USD</RemainingAmount></div>
                      <div><ProgressAmount>{contract.carbonRemoved}</ProgressAmount><RemainingAmount> / {contract.tonsToCapture} t</RemainingAmount></div>
                    </ContractProgressText>
                  </ContractProgress>
                </ContractCard>
            ))) : (<p>No contracts found.</p>)
            }
          </ContractGrid>
        </ContractsSection>
      </MainContent>
    </PageContainer>
  );
}

export default ContractHistory;

